import React, { Component } from 'react';

import FloatingFilterButton from '../../components/UI/FloatingFilterButton';
import { store, getFromLocalStorage } from '../../store/index'
import { getSelectedVendor, clearSelectedVendor, changeTab, updateCartProducts, updateCartTotal } from '../../actions/index'
import { applyToken } from '../../services/api';
import { connect } from 'react-redux'
import '../../App.css'
import '../../index.css'
import MapComponent from '../../components/MapComponent';
import FloatingPanel from '../../components/UI/FloatingPanel'
import ExploreComponent from '../../components/ExploreComponent';
import CartComp from '../../components/CartComponent';
import NewNavBar from '../../components/UI/NewNavBar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Toast from 'react-bootstrap/Toast';
import VendorSideBar from '../../components/UI/VendorSideBar';
import Modal from 'react-bootstrap/Modal';
import TrackVisibility from 'react-on-screen';
import CheckBox from '../../components/UI/CheckBox';
import CustomButton from '../../components/UI/Button';



import {
    CartComponent,
    ProductComponent,
    CheckoutButtonComponent,
    cartLocalization
} from "react-shopping-cart";
import './style.css'
import { theme } from '../../components/UI/theme';
const { getDefaultLocalization } = cartLocalization;
const iPadCaseLocalization = {
    color: "Color",
    iPadCase: "iPad case",
    red: "Red",
    green: "Green",
    yellow: "Yellow",
    AUD: "$"
};
const iPadPropertiesWithAdditionalCostLocalization = {
    yellow: "Yellow"
};
const localization = {
    AUD: '$',
};

class VendorResponsiveScreen extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        mealList: [],
        selectedMealCategory: '',
        mealCategories: [],
        addToCartModal: false,
        isFindOutMoreClicked: false,
        bookingUserName: '',
        bookingUserEmail: '',
        bookingUserPhone: '',
        bookingUserDate: '',
        bookingUserTime: '',
        bookingUserLocation: '',
        bookingUserGuest: '',
        selectedMeal: {},
        combos: {},
        productsState: {},
        productsTotalState: 0,
        mealTotalState: 0,
        quantityPicker: 1,
        showAddToCartToast: false,
        selectedSubItem: {},
        selectedCombo: new Array(),
        additionalCostState: new Array(),
        specialRequest: '',
        divFocus: 'category0',
        product: {
            name: "iPadCase",
            id: "ipad-case",
            path: "/shop/ipad-case/",
            properties: {
                color: [
                    "red",
                    "green",
                    {
                        additionalCost: {
                            GBP: 1,
                            EUR: 2,
                            USD: 3.5
                        },
                        value: "yellow"
                    }
                ]
            },
            propertiesToShowInCart: ["color"],
            prices: { AUD: 70 },
            currency: "AUD",
            imageSrc: "1-483x321.jpeg"
        },
        getProductLocalization: getDefaultLocalization("product", "en", {
            ...iPadCaseLocalization,
            ...iPadPropertiesWithAdditionalCostLocalization
        }),
        getCheckoutButtonLocalization: getDefaultLocalization(
            "checkoutButton",
            "en",
            iPadCaseLocalization
        ),

        getCartLocalization: getDefaultLocalization(
            "cart",
            "en",

        )
    }

    componentDidMount() {
        const { dispatch } = this.props
        const { appReducer } = store.getState()

        const { products, productsTotal } = appReducer
        const {
            productsState,
            productsTotalState
        } = this.state
        var vendorId = window.location.href.substring(window.location.href.lastIndexOf(":") + 1)
        dispatch(getSelectedVendor(vendorId)).then(() => {
            const { appReducer } = store.getState()

            var selectedVendorData = appReducer.selectedVendorData
            if (this.state.selectedMealCategory == '' && Object.keys(selectedVendorData).length != 0) {
                var meal_categories = [];
                this.setSelectedMealCategory(meal_categories, selectedVendorData, '')
            }
        });
        if (this.isEmpty(productsState)) {
            this.setState({ productsState: products }, () => {
                console.log(this.state.productsState)
            })
        }

        if (productsTotalState == 0) {
            this.setState({ productsTotalState: productsTotal })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { appReducer } = store.getState()
        const { products } = appReducer
        const { dispatch } = this.props

        if (this.state.productsState !== products) {

            dispatch(updateCartProducts(this.state.productsState))
            dispatch(updateCartTotal(this.calculateCartTotal(this.state.productsState)))
            //setCartProducts(this.state.productsState)

        }
    }


    getMealsByCategory(meals, mealCategories = this.state.mealCategories) {

        var exploreMealListing = []
        meals.map((meal, i) => {
            try {
                if (mealCategories.some(item => item === meal.category.title)) {
                    var obj = {}
                    if (exploreMealListing.some(item => meal.category.title in item)) {
                        exploreMealListing.map((t, i) => {
                            if (meal.category.title in t) {
                                var arr = t[meal.category.title]
                                arr.push(meal)
                                var new_obj = {}
                                new_obj[meal.category.title] = arr
                                exploreMealListing.remove(i)
                                exploreMealListing.push(obj)
                            }
                        })
                    }
                    else {
                        var arr = []
                        arr.push(meal)
                        obj[meal.category.title] = arr
                        exploreMealListing.push(obj)
                    }
                }
            }
            catch (err) {

            }
        })

        return exploreMealListing


    }

    setSelectedMealCategory = (meal_categories, truckData, selectedMealCategory) => {

        if (Object.keys(truckData).length != 0) {
            this.setState({
                mealList: []
            }, () => {


                truckData.meals.sort(function (a, b) {
                    var nameA = a.category.priority, nameB = b.category.priority
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                });

                var temp_category = truckData.meals.map(q => q.category.title)

                meal_categories = temp_category.filter((q, idx) => temp_category.indexOf(q) === idx)
                meal_categories.unshift('All')
                if (selectedMealCategory == '') {
                    selectedMealCategory = 'All'
                }

                this.setState({
                    selectedMealCategory: selectedMealCategory, mealCategories: meal_categories
                }, () => {
                    var tempMealList = []

                    for (var i = 0; i < truckData.meals.length; i++) {
                        if (truckData.meals[i].category.title == this.state.selectedMealCategory) {
                            tempMealList.push(truckData.meals[i])
                        }

                    }

                    this.setState({
                        mealList: [
                            ...this.state.mealList,
                            ...tempMealList
                        ]
                    }, () => {

                    }

                    )

                });
            }

            )


        }
    }


    getComboArray = (combos) => {

        let comboArray = []
        combos.forEach(combo => {
            comboArray.push([
                { id: combo.id, value: combo.name, isChecked: false, price: combo.price },
            ])
        })

        return comboArray;
    }
    handleCheckComboElement = (event) => {

        let combos = this.state.combos
        let selectedCombo = []

        combos.forEach(combo => {
            combo = combo[0]
            if (combo.value == event.target.value) {

                combo.isChecked = event.target.checked
                if (combo.isChecked) {
                    var cost = Number(this.state.mealTotalState) + (Number(combo.price) * 1)
                    var k = combo.value;
                    var v = Number(combo.price) 

                    var obj = {}
                    obj[k] = v;
                    this.setState({ additionalCostState: [...this.state.additionalCostState, obj], mealTotalState: cost }, () => {
                        
                    })

                    //this.setState({ additionalCostState: Number(this.state.additionalCostState) + (Number(combo.price) * Number(this.state.quantityPicker)), mealTotalState: cost })
                }
                else {
                    var cost = Number(this.state.mealTotalState) - (Number(combo.price) * 1)
                    var k = combo.value;
                    var v = Number(combo.price)
                    var temp = new Array()
                    this.state.additionalCostState.map((item) => {

                        if (Object.keys(item) == k && Object.values(item) == v) {
                            // dont push it into new array
                        }
                        else {
                            var key = Object.keys(item)
                            var val = Object.values(item)

                            var obj = {}
                            obj[key] = val[0]
                            temp.push(obj)
                        }
                    })
                    this.setState({ additionalCostState: temp, mealTotalState: cost }, () => {
                        
                    })
                }
            }

            if (combo.isChecked) {
                selectedCombo.push(combo.value)
            }
        })

        this.setState({ combos: combos, selectedCombo: selectedCombo })
    }







    calculateCartTotal = (products) => {
        var price = 0
        for (var key in products) {
            price += Number(products[key].prices.AUD) * Number(products[key].quantity);
            products[key].additionalCost && products[key].additionalCost.map((item) => {
                var additionalPrice = Object.values(item)[0] * Number(products[key].quantity)
                if (!isNaN(Number(additionalPrice))) {
                    price += Number(additionalPrice)
                    
                }

            })
        }
        //price = Number(price) + Number(this.state.additionalCostState)
        return price;
    }


    setDivFocus = (categoryid, category) => {
        if (categoryid == "category-1") {
            categoryid = "category0"
        }

        this.setState({ divFocus: categoryid, selectedMealCategory: category })

        document.getElementById(categoryid).scrollIntoView()


    }

    toggleAddToCartModal() {
        this.state.addToCartModal
            ?
            this.setState({ addToCartModal: false,mealTotalState: 0, quantityPicker: 1,selectedMeal:{},combos:{} })
            :
            this.setState({ addToCartModal: true })
    }

    addProduct = (key, product, currency) => {
        var values = Object.values(this.state.productsState)
        if (values.length == 0) {
            void this.setState(
                ({
                    productsState: { [key]: cartProduct = { quantity: 0 }, ...restOfProducts }
                }) => ({
                    productsState: {
                        ...restOfProducts,
                        [key]: {
                            ...product,
                            quantity: product.quantity + cartProduct.quantity
                        }
                    }
                }), () => {



                }
            );
        }
        else {
            for (var i = 0; i < values.length; i++) {
                var value = values[i]
                var match = false;
                var matchKey = -1;
                if (value.id == product.id &&
                    value.properties.SpecialRequest.toString() == product.properties.SpecialRequest.toString() &&
                    value.properties.Combo.toString() == product.properties.Combo.toString() &&
                    value.properties.Subitem.toString() == product.properties.Subitem.toString()
                ) {
                    match = true;
                    matchKey = Object.keys(this.state.productsState)[i]
                    break;
                }
            }
            if (match) {

                void this.setState(
                    ({
                        productsState: { [matchKey]: cartProduct = { quantity: 0 }, ...restOfProducts }
                    }) => ({
                        productsState: {
                            ...restOfProducts,
                            [matchKey]: {
                                ...product,
                                quantity: product.quantity + cartProduct.quantity
                            }
                        }
                    }), () => {
                        console.log("Meena Product added", this.state.productsState)


                    }
                );
            }
            else {
                // same product with different extras. Add as new product
                var key = product.id + "_" + Date.now()
                this.setState({ productsState: { ...this.state.productsState, [key]: product } }, () => {
                    console.log("Meena Product added", this.state.productsState)
                })
            }
        }
        this.setState({mealTotalState: 0,quantityPicker: 1,selectedMeal:{},combos:{}})
    }

    isEmpty = obj => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };


    render() {
        const {
            mealCategories,
            selectedMealCategory,
            showAddToCartToast,
            selectedMeal,
            productsTotalState,
            productsState
        } = this.state
        const { appReducer } = store.getState()
        const { selectedVendorData, userId } = appReducer
        const { dispatch } = this.props

        var mealsByCategory = [];

        if (mealCategories.length != 0) {
            mealsByCategory = this.getMealsByCategory(selectedVendorData.meals)
        }

        

        return (
            <>
                {selectedVendorData && selectedVendorData.kitchaco ?
                    <NewNavBar kitchacoLocation={selectedVendorData && selectedVendorData.kitchaco && selectedVendorData.kitchaco.location} kitchacoLocationId={selectedVendorData && selectedVendorData.kitchaco && selectedVendorData.kitchaco.id} selectedVendorId={selectedVendorData && selectedVendorData.vendor && selectedVendorData.vendor.id} productsState={productsState} productsTotalState={productsTotalState} userId={userId} dispatch={dispatch}></NewNavBar>
                    :
                    null}
                <Container>
                    <Row>
                        <Col lg={3} className="desktop-only" style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s" }}>
                            {mealCategories.length != 0 ? <VendorSideBar mealCategories={mealCategories} selectedMealCategory={selectedMealCategory} setDivFocus={this.setDivFocus.bind(this)}></VendorSideBar> : null}
                        </Col>
                        <Col lg={9}>
                            <div
                                aria-live="polite"
                                aria-atomic="true"
                                style={{
                                    position: 'absolute',
                                    minHeight: '100px',
                                }}
                            >
                                <Toast
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        right: 0,
                                        zIndex: 102
                                    }}
                                    onClose={() => this.setState({ showAddToCartToast: false })} show={showAddToCartToast} delay={3000} autohide
                                >
                                    <Toast.Header>
                                        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                                        <strong className="mr-auto">Added to Cart</strong>
                                        <small>just now</small>
                                    </Toast.Header>
                                    <Toast.Body>{selectedMeal.name} is successfully added to your cart. </Toast.Body>
                                </Toast>
                            </div>
                            <TrackVisibility partialVisibility>
                                {
                                    ({ isVisible }) => {


                                        if (this.state.carouselVisible != isVisible) {
                                            this.setState({ carouselVisible: isVisible })
                                        }

                                        return (
                                            <Row style={{ marginTop: 10 }}>
                                                <Col lg={12}>
                                                    <Carousel
                                                        controls={false}
                                                        style={{ maxHeight: 313, overflow: 'hidden' }}
                                                    >
                                                        <Carousel.Item>
                                                            <img
                                                                className="d-block w-100"
                                                                src={selectedVendorData.image && selectedVendorData.image != null && selectedVendorData.image != "" && selectedVendorData.image != "undefined" ? selectedVendorData.image : require("../../assets/img/default_cover.jpg")}
                                                                alt={selectedVendorData.title}
                                                            />

                                                            <Carousel.Caption className="mobile-only">
                                                                <h3>{selectedVendorData.title}</h3>

                                                            </Carousel.Caption>
                                                            <div style={{ height: 313, backgroundColor: 'black', opacity: .5, position: 'absolute', width: '100%', top: 0 }} className="mobile-only"></div>
                                                        </Carousel.Item>
                                                    </Carousel>
                                                </Col>
                                            </Row>
                                        )
                                    }}
                            </TrackVisibility>
                            <Row>
                                {this.renderMobileCategories()}
                                {this.renderAddToCartModal()}
                                {mealsByCategory.map((meal, i) => {
                                    var mealsArray = meal[Object.keys(meal)]
                                    return (
                                        <div key={"category" + i} id={"category" + i} style={{ paddingBottom: 40, paddingTop: 40, borderBottom: '1px solid #C7CACE', paddingLeft: 15, paddingRight: 15, width: '100%' }} >
                                            <Row>
                                                <Col lg={12}><h4 style={{ textAlign: 'left' }}>{Object.keys(meal)}</h4></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} lg={12}>
                                                    {mealsArray.map((meal, i) => {
                                                        return (
                                                            <>
                                                                {i % 4 == 0 ?
                                                                    <Row>
                                                                        <Col xs={6} md={6} lg={3} key={'k' + i} onClick={() => {
                                                                            const product = {
                                                                                name: mealsArray[i].name,
                                                                                id: "" + mealsArray[i].id,
                                                                                path: mealsArray[i].image && !((mealsArray[i].image).includes("NULL")) ? mealsArray[i].image : require('../../assets/img/empty_logo.png'),
                                                                                prices: { AUD: Number(mealsArray[i].price) },
                                                                                currency: "AUD",
                                                                                imageSrc: mealsArray[i].image ? mealsArray[i].image : require('../../assets/img/empty_logo.png'),
                                                                                properties: {},
                                                                                propertiesToShowInCart: [],
                                                                                quantity: 1,
                                                                                additionalCost: this.state.additionalCostState,
                                                                            }
                                                                            this.setState({ combos: this.getComboArray(mealsArray[i].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i].price) }, () => {
                                                                                this.setState({ selectedMeal: mealsArray[i], addToCartModal: true })
                                                                            })
                                                                            // this.setState({ selectedMeal: mealsArray[i], addToCartModal: true })

                                                                            //addProduct(mealsArray[i].id,product,"AUD")
                                                                        }}>
                                                                            <Card style={{ height: 370, marginTop: 20 }}>
                                                                                <div style={{ minHeight: 160, maxHeight: 160, overflow: 'hidden' }}><Card.Img onError={this.addDefaultSrc} className="align-middle" src={mealsArray[i].image && !((mealsArray[i].image).includes("NULL")) ? mealsArray[i].image : require('../../assets/img/empty_logo.png')} /></div>
                                                                                <Card.Body variant="bottom">
                                                                                    <Card.Title>{mealsArray[i].name}</Card.Title>
                                                                                    <Card.Text>
                                                                                        ${mealsArray[i].price}
                                                                                    </Card.Text>
                                                                                    <Button onClick={() => {
                                                                                        const product = {
                                                                                            name: mealsArray[i].name,
                                                                                            id: "" + mealsArray[i].id,
                                                                                            path: mealsArray[i].image && !((mealsArray[i].image).includes("NULL")) ? mealsArray[i].image : require('../../assets/img/empty_logo.png'),
                                                                                            prices: { AUD: Number(mealsArray[i].price) },
                                                                                            currency: "AUD",
                                                                                            imageSrc: mealsArray[i].image ? mealsArray[i].image : require('../../assets/img/empty_logo.png'),
                                                                                            properties: {},
                                                                                            propertiesToShowInCart: [],
                                                                                            quantity: 1,
                                                                                            additionalCost: this.state.additionalCostState,
                                                                                        }
                                                                                        this.setState({ combos: this.getComboArray(mealsArray[i].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i].price) }, () => {
                                                                                            this.setState({ selectedMeal: mealsArray[i], addToCartModal: true })
                                                                                        })
                                                                                        // this.setState({ selectedMeal: mealsArray[i], addToCartModal: true })

                                                                                        //addProduct(mealsArray[i].id,product,"AUD")
                                                                                    }} style={{ backgroundColor: theme.colors.primaryColor, borderColor: theme.colors.primaryColor, color: 'white' }}>Add to cart</Button>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>

                                                                        <Col xs={6} md={6} lg={3} key={'k' + i + 1} onClick={() => {
                                                                            const product = {
                                                                                name: mealsArray[i + 1].name,
                                                                                id: "" + mealsArray[i + 1].id,
                                                                                path: mealsArray[i + 1].image && !((mealsArray[i + 1].image).includes("NULL")) ? mealsArray[i + 1].image : require('../../assets/img/empty_logo.png'),
                                                                                prices: { AUD: Number(mealsArray[i + 1].price) },
                                                                                currency: "AUD",
                                                                                imageSrc: mealsArray[i + 1].image ? mealsArray[i + 1].image : require('../../assets/img/empty_logo.png'),
                                                                                properties: {},
                                                                                propertiesToShowInCart: [],
                                                                                quantity: 1,
                                                                                additionalCost: this.state.additionalCostState,
                                                                            }
                                                                            this.setState({ combos: this.getComboArray(mealsArray[i + 1].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i + 1].price) }, () => {
                                                                                this.setState({ selectedMeal: mealsArray[i + 1], addToCartModal: true })
                                                                            })

                                                                            //addProduct(mealsArray[i+1].id,product,"AUD")
                                                                        }}>
                                                                            {mealsArray[i + 1] != undefined ?
                                                                                <Card style={{ height: 370, marginTop: 20 }}>
                                                                                    <div style={{ minHeight: 160, maxHeight: 160, overflow: 'hidden' }}><Card.Img onError={this.addDefaultSrc} className="align-middle" src={mealsArray[i + 1].image && !((mealsArray[i + 1].image).includes("NULL")) ? mealsArray[i + 1].image : require('../../assets/img/empty_logo.png')} /></div>
                                                                                    <Card.Body variant="bottom">
                                                                                        <Card.Title>{mealsArray[i + 1].name}</Card.Title>
                                                                                        <Card.Text>
                                                                                            ${mealsArray[i + 1].price}
                                                                                        </Card.Text>
                                                                                        <Button onClick={() => {
                                                                                            const product = {
                                                                                                name: mealsArray[i + 1].name,
                                                                                                id: "" + mealsArray[i + 1].id,
                                                                                                path: mealsArray[i + 1].image && !((mealsArray[i + 1].image).includes("NULL")) ? mealsArray[i + 1].image : require('../../assets/img/empty_logo.png'),
                                                                                                prices: { AUD: Number(mealsArray[i + 1].price) },
                                                                                                currency: "AUD",
                                                                                                imageSrc: mealsArray[i + 1].image ? mealsArray[i + 1].image : require('../../assets/img/empty_logo.png'),
                                                                                                properties: {},
                                                                                                propertiesToShowInCart: [],
                                                                                                quantity: 1,
                                                                                                additionalCost: this.state.additionalCostState,
                                                                                            }
                                                                                            this.setState({ combos: this.getComboArray(mealsArray[i + 1].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i + 1].price) }, () => {
                                                                                                this.setState({ selectedMeal: mealsArray[i + 1], addToCartModal: true })
                                                                                            })

                                                                                            //addProduct(mealsArray[i+1].id,product,"AUD")
                                                                                        }} style={{ backgroundColor: theme.colors.primaryColor, borderColor: theme.colors.primaryColor, color: 'white' }}>Add to cart</Button>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                                : null}
                                                                        </Col>


                                                                        <Col xs={6} md={6} lg={3} key={'k' + i + 2} onClick={() => {
                                                                            const product = {
                                                                                name: mealsArray[i + 2].name,
                                                                                id: "" + mealsArray[i + 2].id,
                                                                                path: mealsArray[i + 2].image && !((mealsArray[i + 2].image).includes("NULL")) ? mealsArray[i + 2].image : require('../../assets/img/empty_logo.png'),
                                                                                prices: { AUD: Number(mealsArray[i + 2].price) },
                                                                                currency: "AUD",
                                                                                imageSrc: mealsArray[i + 2].image ? mealsArray[i + 2].image : require('../../assets/img/empty_logo.png'),
                                                                                properties: {},
                                                                                propertiesToShowInCart: [],
                                                                                quantity: 1,
                                                                                additionalCost: this.state.additionalCostState,
                                                                            }
                                                                            this.setState({ combos: this.getComboArray(mealsArray[i + 2].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i + 2].price) }, () => {
                                                                                this.setState({ selectedMeal: mealsArray[i + 2], addToCartModal: true })
                                                                            })

                                                                            //addProduct(mealsArray[i+2].id,product,"AUD")
                                                                        }}>
                                                                            {mealsArray[i + 2] != undefined ?
                                                                                <Card style={{ height: 370, marginTop: 20 }}>
                                                                                    <div style={{ minHeight: 160, maxHeight: 160, overflow: 'hidden' }}><Card.Img onError={this.addDefaultSrc} className="align-middle" src={mealsArray[i + 2].image && !((mealsArray[i + 2].image).includes("NULL")) ? mealsArray[i + 2].image : require('../../assets/img/empty_logo.png')} /></div>
                                                                                    <Card.Body variant="bottom">
                                                                                        <Card.Title>{mealsArray[i + 2].name}</Card.Title>
                                                                                        <Card.Text>
                                                                                            ${mealsArray[i + 2].price}
                                                                                        </Card.Text>
                                                                                        <Button onClick={() => {
                                                                                            const product = {
                                                                                                name: mealsArray[i + 2].name,
                                                                                                id: "" + mealsArray[i + 2].id,
                                                                                                path: mealsArray[i + 2].image && !((mealsArray[i + 2].image).includes("NULL")) ? mealsArray[i + 2].image : require('../../assets/img/empty_logo.png'),
                                                                                                prices: { AUD: Number(mealsArray[i + 2].price) },
                                                                                                currency: "AUD",
                                                                                                imageSrc: mealsArray[i + 2].image ? mealsArray[i + 2].image : require('../../assets/img/empty_logo.png'),
                                                                                                properties: {},
                                                                                                propertiesToShowInCart: [],
                                                                                                quantity: 1,
                                                                                                additionalCost: this.state.additionalCostState,
                                                                                            }
                                                                                            this.setState({ combos: this.getComboArray(mealsArray[i + 2].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i + 2].price) }, () => {
                                                                                                this.setState({ selectedMeal: mealsArray[i + 2], addToCartModal: true })
                                                                                            })

                                                                                            //addProduct(mealsArray[i+2].id,product,"AUD")
                                                                                        }} style={{ backgroundColor: theme.colors.primaryColor, borderColor: theme.colors.primaryColor, color: 'white' }}>Add to cart</Button>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                                : null}
                                                                        </Col>


                                                                        <Col xs={6} md={6} lg={3} key={'k' + i + 3} onClick={() => {
                                                                            const product = {
                                                                                name: mealsArray[i + 3].name,
                                                                                id: "" + mealsArray[i + 3].id,
                                                                                path: mealsArray[i + 3].image && !((mealsArray[i + 3].image).includes("NULL")) ? mealsArray[i + 3].image : require('../../assets/img/empty_logo.png'),
                                                                                prices: { AUD: Number(mealsArray[i + 3].price) },
                                                                                currency: "AUD",
                                                                                imageSrc: mealsArray[i + 3].image ? mealsArray[i + 3].image : require('../../assets/img/empty_logo.png'),
                                                                                properties: {},
                                                                                propertiesToShowInCart: [],
                                                                                quantity: 1,
                                                                                additionalCost: this.state.additionalCostState,
                                                                            }
                                                                            this.setState({ combos: this.getComboArray(mealsArray[i + 3].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i + 3].price) }, () => {
                                                                                this.setState({ selectedMeal: mealsArray[i + 3], addToCartModal: true })
                                                                            })

                                                                            //addProduct(mealsArray[i+3].id,product,"AUD")
                                                                        }}>
                                                                            {mealsArray[i + 3] != undefined ?
                                                                                <Card style={{ height: 370, marginTop: 20 }}>
                                                                                    <div style={{ minHeight: 160, maxHeight: 160, overflow: 'hidden' }}><Card.Img onError={this.addDefaultSrc} className="align-middle" src={mealsArray[i + 3].image && !((mealsArray[i + 3].image).includes("NULL")) ? mealsArray[i + 3].image : require('../../assets/img/empty_logo.png')} /></div>
                                                                                    <Card.Body variant="bottom">
                                                                                        <Card.Title>{mealsArray[i + 3].name}</Card.Title>
                                                                                        <Card.Text>
                                                                                            ${mealsArray[i + 3].price}
                                                                                        </Card.Text>
                                                                                        <Button onClick={() => {
                                                                                            const product = {
                                                                                                name: mealsArray[i + 3].name,
                                                                                                id: "" + mealsArray[i + 3].id,
                                                                                                path: mealsArray[i + 3].image && !((mealsArray[i + 3].image).includes("NULL")) ? mealsArray[i + 3].image : require('../../assets/img/empty_logo.png'),
                                                                                                prices: { AUD: Number(mealsArray[i + 3].price) },
                                                                                                currency: "AUD",
                                                                                                imageSrc: mealsArray[i + 3].image ? mealsArray[i + 3].image : require('../../assets/img/empty_logo.png'),
                                                                                                properties: {},
                                                                                                propertiesToShowInCart: [],
                                                                                                quantity: 1,
                                                                                                additionalCost: this.state.additionalCostState,
                                                                                            }
                                                                                            this.setState({ combos: this.getComboArray(mealsArray[i + 3].combo), mealTotalState: Number(this.state.quantityPicker) * Number(mealsArray[i + 3].price) }, () => {
                                                                                                this.setState({ selectedMeal: mealsArray[i + 3], addToCartModal: true })
                                                                                            })

                                                                                            //addProduct(mealsArray[i+3].id,product,"AUD")
                                                                                        }} style={{ backgroundColor: theme.colors.primaryColor, borderColor: theme.colors.primaryColor, color: 'white' }}>Add to cart</Button>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                                : null}
                                                                        </Col>


                                                                    </Row>
                                                                    : null}
                                                            </>
                                                        )

                                                    }
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    renderMobileCategories = () => {
        const { mealCategories, selectedMealCategory, carouselVisible } = this.state

        return (
            <Row style={carouselVisible == false ? { position: 'fixed', top: 0, zIndex: 100, backgroundColor: 'white', paddingTop: 10 } : { marginTop: 10 }}>
                <Col lg={12} style={{ overflow: 'scroll', width: '100vw', paddingRight: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', marginLeft: 15, marginRight: 15 }} className="mobile-only">
                        {mealCategories.map((category, k) => {
                            var selected = selectedMealCategory == category
                            return (
                                <>
                                    {category.indexOf(" ") != -1 ?
                                        <p onClick={() => { this.setDivFocus("category" + (k - 1), category) }} style={{ height: 45, padding: 10, backgroundColor: selected ? '#f15b4b' : '#ffffff', color: selected ? 'white' : 'black', marginRight: 10, borderRadius: 40, borderWidth: 1, borderColor: '#f15b4b', borderStyle: 'solid', textAlign: 'center', minWidth: 'fit-content' }}>{category}</p> :
                                        <p onClick={() => { this.setDivFocus("category" + (k - 1), category) }} style={{ height: 45, padding: 10, backgroundColor: selected ? '#f15b4b' : '#ffffff', color: selected ? 'white' : 'black', marginRight: 10, borderRadius: 40, borderWidth: 1, borderColor: '#f15b4b', borderStyle: 'solid', textAlign: 'center' }}>{category}</p>
                                    }

                                </>
                            )
                        })}
                    </div>
                </Col>
            </Row>

        )
    }

    renderAddToCartModal() {
        const {
            addToCartModal,
            selectedMeal,
            combos,
            specialRequest,
            selectedCombo,
            selectedSubItem
        } = this.state
        return (
            <Modal show={addToCartModal} onHide={this.toggleAddToCartModal.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
                    <Card style={{ height: 500, marginTop: 20, overflowY: 'scroll' }}>
                        <div style={{ minHeight: 250, maxHeight: 250, overflow: 'hidden' }}><Card.Img onError={this.addDefaultSrc} className="align-middle" src={selectedMeal.image ? selectedMeal.image : require('../../assets/img/empty_logo.png')} /></div>
                        <Card.Body variant="bottom">
                            <Card.Title>{selectedMeal.name}</Card.Title>
                            <Card.Text>
                                {selectedMeal.description}
                            </Card.Text>
                            <Row>
                                <Col className="text-center" xs={3} onClick={() => {
                                    this.setState((prevState, props) => ({
                                        quantityPicker: prevState.quantityPicker - 1
                                    }));
                                }}>
                                    <div style={{ borderRadius: "50%", backgroundColor: theme.colors.borderColor, cursor: 'pointer', width: 50, height: 50, display: 'inline-block', lineHeight: "50px" }}>
                                        -
                                    </div>
                                </Col>
                                <Col xs={6}> <div style={{ width: 50, height: 50, display: 'inline-block', lineHeight: "50px" }}>
                                    {this.state.quantityPicker}
                                </div> </Col>
                                <Col className="text-center" xs={3} onClick={() => {
                                    this.setState((prevState, props) => ({
                                        quantityPicker: prevState.quantityPicker + 1
                                    }));
                                }}>
                                    <div style={{ borderRadius: "50%", backgroundColor: theme.colors.borderColor, cursor: 'pointer', width: 50, height: 50, display: 'inline-block', lineHeight: "50px" }}>
                                        +
                                    </div>
                                </Col>
                            </Row>
                            {selectedMeal.subitem && selectedMeal.subitem.length > 0 ?
                                <Row style={{ marginTop: 20 }}>
                                    <Card.Title style={{ textAlign: 'left', backgroundColor: '#c7cace', padding: 10, width: '100%' }}>Pick One</Card.Title>
                                    <Col xs={12}>

                                        {selectedMeal.subitem.map((subitem) => {

                                            return (
                                                <li style={{ listStyle: 'none', textAlign: 'left' }}>
                                                    <input key={subitem.id} onClick={() => { this.setState({ selectedSubItem: subitem }) }} type="radio" checked={this.state.selectedSubItem === subitem ? true : false} value={subitem.name} /> {subitem.name}
                                                </li>
                                            );

                                        })}
                                    </Col>

                                </Row>
                                : null}
                            {selectedMeal.combo && selectedMeal.combo.length > 0 ?
                                <Row style={{ marginTop: 20 }}>
                                    <Card.Title style={{ textAlign: 'left', backgroundColor: '#c7cace', padding: 10, width: '100%' }}>Add Extras</Card.Title>
                                    <Col xs={12}>

                                        {combos && combos.length > 0 && combos.map((combo) => {

                                            return (
                                                <CheckBox
                                                    handleCheckComboElement={this.handleCheckComboElement}
                                                    {...combo} />
                                            );

                                        })}
                                    </Col>

                                </Row>
                                : null}
                            <Row style={{ marginTop: 20 }}>
                                <Card.Title style={{ textAlign: 'left', backgroundColor: '#c7cace', padding: 10, width: '100%' }}>Special Request</Card.Title>
                                <Col xs={12}>
                                    <Form.Group controlId="formBasicSpecialRequest">
                                        <Form.Control type="text" placeholder="Eg: No Onions" value={specialRequest} onChange={(e) => { this.setState({ specialRequest: e.target.value }) }} />
                                    </Form.Group>

                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>

                </Modal.Body>

                <Modal.Footer>
                    <CustomButton
                        backgroundColor={theme.colors.primaryColor}
                        textColor={theme.colors.textColorLight}
                        borderRadius={20}
                        isProfilePic={false}
                        buttonText={'Add to Cart $' + Number(this.state.mealTotalState).toFixed(2)}
                        handleButtonClick={
                            () => {
                                const product = {
                                    name: selectedMeal.name,
                                    id: "" + selectedMeal.id,
                                    path: selectedMeal.image ? selectedMeal.image : require('../../assets/img/empty_logo.png'),
                                    prices: { AUD: Number(selectedMeal.price) },
                                    currency: "AUD",
                                    imageSrc: selectedMeal.image ? selectedMeal.image : require('../../assets/img/empty_logo.png'),
                                    properties: { Combo: [selectedCombo], Subitem: [selectedSubItem.name], SpecialRequest: [specialRequest], },
                                    propertiesToShowInCart: ["Combo", "Subitem", "SpecialRequest"],
                                    quantity: this.state.quantityPicker,
                                    additionalCost: this.state.additionalCostState,

                                }
                                console.log('product_obj',product)
                                this.setState({ addToCartModal: false, }, () => {
                                    this.setState({ showAddToCartToast: true, additionalCostState: new Array(), selectedSubItem: {}, selectedCombo: new Array(), specialRequest: '', }, () => {
                                        this.addProduct(selectedMeal.id, product, "AUD")

                                    })
                                })


                            }
                        }

                        buttonWidth={'90%'}
                        margin={'auto'}
                    >
                    </CustomButton>
                </Modal.Footer>
            </Modal>
        )
    }
}



function mapStateToProps(state) {
    return {
        selectedTab: state.appReducer.selectedTab,
        vendorData: state.appReducer.vendorData,
        filters: state.appReducer.filters,
        selectedCategory: state.appReducer.selectedCategory,
        loggedIn: state.appReducer.loggedIn,
        smsSent: state.appReducer.smsSent,
        loginModalToggle: state.appReducer.loginModalToggle,
        phone: state.appReducer.phone,
        userId: state.appReducer.userId,
        token: state.appReducer.token,
        username: state.appReducer.username,
        email: state.appReducer.email,
        userAddress: state.appReducer.userAddress,
        userImg: state.appReducer.userImg,
        userLocation: state.appReducer.userLocation,
        currentLatitude: state.appReducer.currentLatitude,
        currentLongitude: state.appReducer.currentLongitude,
        selectedVendorData: state.appReducer.selectedVendorData

    }
}

export default connect(mapStateToProps)(VendorResponsiveScreen);
