import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import OperatorNavBar from '../../components/UI/OperatorNavBar/index';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { Bar, Pie } from 'react-chartjs-2'
import DatePicker from "react-datepicker";
import moment from 'moment';
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'



class OperatorScreen extends Component {

  state = {
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    salesByLocationLabels: [],
    salesByLocationId: [],
    salesByLocationData: [],
    salesByVendorLabels: [],
    salesByVendorData: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    totalSales: 0,
    avgOrder: 0,
    numOrders: 0,
    numCanceledOrders: 0,
    cashSales: 0,
    cardSales: 0,
    uberSales: 0,
    itemsSold: {},
    operatorKitchacoLocation: getFromLocalStorage("operatorKitchacoLocation") != "undefined" && getFromLocalStorage("operatorKitchacoLocation") != "" && getFromLocalStorage("operatorKitchacoLocation") != null ? getFromLocalStorage("operatorKitchacoLocation") : -1,
    kitchacoLocation:getFromLocalStorage("kitchacoLocation") != "undefined" && getFromLocalStorage("kitchacoLocation") != "" && getFromLocalStorage("kitchacoLocation") != null ? getFromLocalStorage("kitchacoLocation") : '',
    kitchacoLocations: [],
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    stockSent:0,
  }

  componentDidMount() {
    const {
      isOperatorLoggedIn
    } = this.state
    if (isOperatorLoggedIn == "true") {
      this.setState({email:getFromLocalStorage("operatorEmail"),password:getFromLocalStorage("operatorPassword")},()=>{
        this.login()
      })
    }

  }
  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate)
    })

  }

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }



  getBackgroundColor(length) {
    var bgColor = []
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++)
        rgb.push(Math.floor(Math.random() * 255));
      bgColor.push('rgb(' + rgb.join(',') + ')')
    }

    return bgColor;
  }

  
  getDashboardData() {
    const {
      startDate,
      endDate,
      operatorKitchacoLocation

    } = this.state

    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "dashboard_data",
        "kitchaco_location": parseInt(operatorKitchacoLocation),
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY")
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        
        if (dataResponse.success) {
          var salesByLocationArray = dataResponse.sales_by_locations
          var salesByVendorArray = dataResponse.sales_by_vendors
          var salesByHoursObj = dataResponse.sales_by_hours

          var salesByLocationLabelsTemp = []
          var salesByLocationDataTemp = []
          var salesByLocationIdTemp = []
          var salesByVendorLabelsTemp = []
          var salesByVendorDataTemp = []
          var salesByHoursLabelsTemp = []
          var salesByHoursDataTemp = []
          for (var i = 0; i < salesByLocationArray.length; i++) {
            salesByLocationLabelsTemp.push(salesByLocationArray[i]["location"])
            salesByLocationIdTemp.push(salesByLocationArray[i]["id"])
            if (salesByLocationArray[i]["total_sales"] != null) {
              salesByLocationDataTemp.push(salesByLocationArray[i]["total_sales"])
            }
            else {
              salesByLocationDataTemp.push(0)
            }

          }
          for (var i = 0; i < salesByVendorArray.length; i++) {
            for (var key in salesByVendorArray[i]) {
              if (salesByVendorArray[i][key] != null) {
                salesByVendorLabelsTemp.push(key)
                salesByVendorDataTemp.push(salesByVendorArray[i][key])
              }
            }
          }
          for (var j = 0; j < salesByVendorDataTemp.length; j++) {
            console.log(salesByVendorDataTemp[j])
          }

          
          
         


          for (let [key, value] of Object.entries(salesByHoursObj)) {
            salesByHoursLabelsTemp.push(key)
          }

          salesByHoursLabelsTemp.sort()
          var len = salesByHoursLabelsTemp.length;
          for (var i = 0; i < len; i++) {
            var k = salesByHoursLabelsTemp[i];
            salesByHoursDataTemp.push(salesByHoursObj[k])
          }

          console.log("salesByHoursLabelsTemp",)






          this.setState({
            salesByLocationLabels: salesByLocationLabelsTemp,
            salesByLocationId: salesByLocationIdTemp,
            salesByLocationData: salesByLocationDataTemp,
            salesByVendorLabels: salesByVendorLabelsTemp,
            salesByVendorData: salesByVendorDataTemp,
            salesByHoursLabels: salesByHoursLabelsTemp,
            salesByHoursData: salesByHoursDataTemp,
            avgOrder: dataResponse.avg_order,
            totalSales: dataResponse.total_sales,
            numOrders: dataResponse.num_orders,
            numCanceledOrders: dataResponse.num_canceled_orders,
            cashSales: dataResponse.cash_sales,
            cardSales: dataResponse.card_sales,
            itemsSold: dataResponse.items_sold,
            uberSales: dataResponse.uber_sales,
            stockSent:dataResponse.total_stock_sent,
          })
        }
      })
      .catch(err => { console.log(err) })

  }


  login = () => {
    const { email, password } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "admin_login" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.admin.adminData, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          console.log(result)
          if (result.data.is_operator) {
            this.setState({ isOperatorLoggedIn: "true",operatorKitchacoLocation:result.data.kitchaco__id,kitchacoLocation:result.data.kitchaco__location }, () => {
              saveToLocalStorage("isOperatorLoggedIn", "true")
              saveToLocalStorage("operatorKitchacoLocation", this.state.operatorKitchacoLocation)
              saveToLocalStorage("kitchacoLocation", this.state.kitchacoLocation)
              saveToLocalStorage("operatorEmail", this.state.email)
              saveToLocalStorage("operatorPassword", this.state.password)
              
              this.getDashboardData()
            })
          }
          else{
            alert("Invalid username or password! Please try again")
            this.logout()
          }
          

        }
        else {
          alert(result.error)
          this.logout()
        }
      })
      .catch(error => console.log('error', error));
  }

  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false")
    this.setState({ isOperatorLoggedIn: "false" })
    window.location.reload()
  }


  render() {
    const {
      isOperatorLoggedIn
    } = this.state
    console.log("isOperatorLoggedIn ",isOperatorLoggedIn)
    return (
      <>

        {
          isOperatorLoggedIn == "true" ?
            this.renderMainComponent()
            :
            this.renderLoginComponent()
        }
      </>
    )
  }

  renderLoginComponent() {
    const {
      email,
      password,
      isOperatorLoggedIn
    } = this.state
    return (
      <div>
        <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} logout={this.logout}></OperatorNavBar>
        <Container>
          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(text) => { this.setState({ email: text.target.value }) }} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(text) => { this.setState({ password: text.target.value }) }} />
          </Form.Group>

          <Button onClick={() => { this.login() }} variant="primary" type="submit">
            Login
            </Button>

        </Container>
      </div>
    )
  }


  renderMainComponent() {
    const {
      startDate,
      endDate,
      salesByLocationLabels,
      salesByLocationId,
      salesByLocationData,
      salesByVendorLabels,
      salesByVendorData,
      salesByHoursData,
      salesByHoursLabels,
      numOrders,
      numCanceledOrders,
      totalSales,
      avgOrder,
      cashSales,
      cardSales,
      operatorKitchacoLocation,
      kitchacoLocations,
      isOperatorLoggedIn,
      itemsSold,
      uberSales,
      stockSent,
      kitchacoLocation
    } = this.state

    console.log("operatorKitchacoLocation", getFromLocalStorage("operatorKitchacoLocation"))
    console.log("salesByHoursData", salesByHoursData)
    console.log("salesByHoursLabels", salesByHoursLabels)
    return (
      <div>

        <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} logout={this.logout}></OperatorNavBar>
        <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 10 }}>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={6} lg={6}>
              {salesByLocationLabels ?
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Kitchaco Locations {kitchacoLocation}</Form.Label>
                </Form.Group>


                : null}
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Row style={{ marginTop: 10 }}>
                <Col xs={4} md={4} lg={4}>
                  <p>Start Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={date => this.setStartDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>End Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button onClick={() => { this.login() }}>Apply</Button>
                </Col>

              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 10, borderBottomWidth: 1, borderBottomColor: 'grey', borderBottomStyle: 'solid' }}>
            <Col xs={12} md={4} lg={4}>
              <h4>Total Sales(Kitchaco+Uber): ${Number(totalSales).toFixed(2)}</h4>
              <p>(Doesn't include canceled orders)</p>
              <h5>Kitchaco Cash Sales: ${Number(cashSales).toFixed(2)}</h5>
              <h5>Kitchaco Card Sales: ${Number(cardSales).toFixed(2)}</h5>
              <h5>Uber Sales: ${Number(uberSales).toFixed(2)}</h5>
              <h5>Stock Sent: ${Number(stockSent).toFixed(2)}</h5>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <h4>Number of Completed Orders: {numOrders}</h4>
              <h4>Number of Canceled Orders: {numCanceledOrders}</h4>
              
            </Col>
            <Col xs={12} md={4} lg={4}>
              <h4>Avg Order: ${Number(avgOrder).toFixed(2)}</h4>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={12} lg={12}>
                <Pie
                  data={{
                    labels: salesByVendorLabels,
                    datasets: [{
                      label: 'Sales by Vendors ($)',
                      data: salesByVendorData,
                      backgroundColor: this.getBackgroundColor(salesByVendorData.length),

                      borderWidth: 1
                    }]
                  }}
                  height={400}
                  width={600}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true
                        }
                      }]
                    }
                  }}
                />
            </Col>
          </Row>

          <Row>
          <Col xs={12} md={12} lg={12}>
            <Bar
              data={{
                labels: salesByHoursLabels,
                datasets: [{
                  label: 'Sales by hours ($)',
                  data: salesByHoursData,
                  backgroundColor: this.getBackgroundColor(salesByHoursLabels.length),

                  borderWidth: 1
                }]
              }}
              height={400}
              width={600}
              options={{
                maintainAspectRatio: false,
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                }
              }}
            />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs={12} md={12} lg={12}>
              <h4>Sales by Vendor</h4>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Total Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {salesByVendorData && salesByVendorData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{salesByVendorLabels[i]}</td>
                        <td>{salesByVendorData[i]}</td>
                      </tr>
                    )
                  })
                  }

                </tbody>
              </Table>
            </Col>
          </Row>
          {operatorKitchacoLocation != 0 ?
            <>
              <Row style={{ marginTop: 20 }}>
                <Col xs={12} md={12} lg={12}>
                  <h4>Items Sold</h4>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(itemsSold).map((key, i) => {
                        console.log(key, itemsSold[key], i);

                        return (
                          <>

                            <tr key={i}>
                              <td>{key}</td>
                              <td>{itemsSold[key]}</td>
                            </tr>
                          </>
                        )
                      })

                      }

                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
            : null}


        </Container>
      </div>
    )
  }




}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(OperatorScreen);



