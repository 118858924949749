import React, { Component } from 'react';
import NavBar from '../../components/UI/NavBar/index';
import VendorComponent from '../../components/VendorComponent';
import FloatingFilterButton from '../../components/UI/FloatingFilterButton';
import {store, getFromLocalStorage} from '../../store/index'
import {getSelectedVendor,clearSelectedVendor,changeTab} from '../../actions/index'
import { applyToken } from '../../services/api';
import {connect} from 'react-redux'
import '../../App.css'
import '../../index.css'
import MapComponent from '../../components/MapComponent';
import FloatingPanel from '../../components/UI/FloatingPanel'
import ExploreComponent from '../../components/ExploreComponent';
import CartComp from '../../components/CartComponent';



class VendorScreen extends Component {
    
    constructor(props){
        super(props)
        
      
    }
    state={
        cartProducts:[],
       
    }

    isEmpty = obj => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };
    
    
    componentWillMount(){
        const {dispatch} = this.props
        
        dispatch(clearSelectedVendor())
    }
    componentDidMount(){
        //console.log("ScreenProps - Vendor",this.props)
        const {dispatch} = this.props
        const {appReducer} = store.getState()
        const {products} = appReducer
        var vendorId = window.location.href.substring(window.location.href.lastIndexOf(":")+1)
        window.location.href="/v2/vendor:"+vendorId
        applyToken(getFromLocalStorage('token'))
        dispatch(getSelectedVendor(vendorId)).then(()=>{
            const {appReducer} = store.getState()
            //console.log("ScreenProps - Vendor Reducers",appReducer)
        });
        if(!this.isEmpty(products)){
            this.setCartProducts(products)
        }
    }
    componentWillUnmount(){
        
    }

    componentDidUpdate(){
        const {dispatch} = this.props
        const {appReducer} = store.getState()
        const {selectedVendorData,products,vendorData} = appReducer
        // if(selectedVendorData.length!=0){
        //     dispatch(changeTab("explore"))
        // }
    }

    setCartProducts = (cartProducts) => {
        this.setState({cartProducts:cartProducts})
    }
    
    render() {
        const {cartProducts} = this.state;
        const {
            selectedTab,
            dispatch,
            filters,
            selectedCategory,
            loggedIn,
            phone,
            smsSent,
            loginModalToggle,
        }
        = this.props
        //console.log("vendor render called")
        
        const {appReducer} = store.getState()
        const {selectedVendorData,products,vendorData,location} = appReducer
        
        let displayComponent;
        if (selectedTab === 'map') {
            displayComponent = <div><FloatingPanel></FloatingPanel><MapComponent vendorData={vendorData}  dispatch={dispatch}></MapComponent></div>
        }
        else if (selectedTab === 'explore') {
            displayComponent = selectedVendorData.length!=0?
            <VendorComponent 
                    dispatch={dispatch} 
                    selectedCategory={selectedCategory} 
                    selectedVendorData={selectedVendorData}
                    products={products}
                    setCartProducts={this.setCartProducts}
                    ></VendorComponent>:<div><ExploreComponent selectedCategory={selectedCategory} dispatch={dispatch}></ExploreComponent></div>
            
        }
        else if (selectedTab === 'cart') {
            displayComponent = <div><CartComp selectedCategory={selectedCategory} dispatch={dispatch}></CartComp></div>
        }
        

       
        return (
            
            <div>
                
                <NavBar
                    selectedTab={selectedTab}
                    dispatch={dispatch}
                    loggedIn={loggedIn}
                    phone={phone}
                    smsSent={smsSent}
                    loginModalToggle={loginModalToggle}
                    kitchacoLocation={location}
                    cartProducts={cartProducts}
                ></NavBar>
                {/* <FloatingFilterButton></FloatingFilterButton> */}
                {displayComponent}
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
      selectedTab : state.appReducer.selectedTab,
      vendorData:state.appReducer.vendorData,
      filters:state.appReducer.filters,
      selectedCategory:state.appReducer.selectedCategory,
      loggedIn:state.appReducer.loggedIn,
      smsSent:state.appReducer.smsSent,
      loginModalToggle:state.appReducer.loginModalToggle,
      phone:state.appReducer.phone,
      userId:state.appReducer.userId,
      token:state.appReducer.token,
      username:state.appReducer.username,
      email:state.appReducer.email,
      userAddress:state.appReducer.userAddress,
      userImg:state.appReducer.userImg,
      userLocation:state.appReducer.userLocation,
      currentLatitude:state.appReducer.currentLatitude,
      currentLongitude:state.appReducer.currentLongitude,
      selectedVendorData:state.appReducer.selectedVendorData
      
    }
  }
  
  export default connect(mapStateToProps)(VendorScreen);
  