import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import OperatorNavBar from '../../components/UI/OperatorNavBar/index';
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Bar } from 'react-chartjs-2'
import DatePicker from "react-datepicker";
import moment from 'moment';
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'



class OperatorOrdersScreen extends Component {

  state = {
    operatorKitchacoLocation: getFromLocalStorage("operatorKitchacoLocation") != "undefined" && getFromLocalStorage("operatorKitchacoLocation") != "" && getFromLocalStorage("operatorKitchacoLocation") != null ? getFromLocalStorage("operatorKitchacoLocation") : 0,
    kitchacoLocation: getFromLocalStorage("kitchacoLocation") != "undefined" && getFromLocalStorage("kitchacoLocation") != "" && getFromLocalStorage("kitchacoLocation") != null ? getFromLocalStorage("kitchacoLocation") : '',
    kitchacoLocations: [],
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    ordersByLocation: [],
    isLoading: false,
    availableStatus: ['Received', 'Cooking', 'Ready for pickup', 'On its way', 'Finished', 'Canceled'],
    selectedOrderNewStatus: '',
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),


  }

  componentDidMount() {
    this.getOrdersData()
    
    console.log(getFromLocalStorage("operatorKitchacoLocation"))
    const {
      isOperatorLoggedIn
    } = this.state
    if (isOperatorLoggedIn != 'true') {
      window.location.href = '/operator-admin'
    }
  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate)
    })

  }

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  

  onChangeOrderStatus = (e, order) => {
    console.log(e.target.value, order)
    this.setState({ isLoadingChangeOrderStatus: true })
    this.setState({ selectedOrder: order, selectedOrderNewStatus: e.target.value, isOpenOrderStatusChangeModal: true }, () => {
      this.setState({ isLoadingChangeOrderStatus: false })
    })
  }




  

  getOrdersData() {
    const {
      startDate,
      endDate,
      operatorKitchacoLocation,

    } = this.state
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "orders_data",
        "kitchaco_location": parseInt(operatorKitchacoLocation),
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY")
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success) {
          console.log(dataResponse)
          this.setState({ ordersByLocation: dataResponse.orders_by_location })
        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

  }

  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false")
    this.setState({ isOperatorLoggedIn: "false" })
    window.location.reload()
  }



  render() {
    console.log(this.state.startDate)
    return (
      <>

        {this.renderMainComponent()}
       
      </>
    )
  }

  renderLoginComponent() {
    return (
      <div>Login Component</div>
    )
  }


  renderMainComponent() {
    const {
      kitchacoLocations,
      operatorKitchacoLocation,
      startDate,
      endDate,
      ordersByLocation,
      availableStatus,
      isLoading,
      isLoadingChangeOrderStatus,
      isOperatorLoggedIn,
      kitchacoLocation
    } = this.state

    console.log(isLoadingChangeOrderStatus)
    return (
      <div>
        <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} logout={this.logout}></OperatorNavBar>
        {isLoading ?
          <div style={{ textAlign: 'center' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          :
          <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={6} lg={6}>
              <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Kitchaco Locations {kitchacoLocation}</Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={4} md={4} lg={4}>
                    <p>Start Date</p>
                    <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={date => this.setStartDate(date)} />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>End Date</p>
                    <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>&nbsp;</p>
                    <Button onClick={() => { this.getOrdersData() }}>Apply</Button>
                  </Col>

                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={12} lg={12}>
                <h4>Orders By Location</h4>
              </Col>
            </Row>
            <Row className="table-header-row">
              <Col xs={12} md={1} lg={1} className="table-header-col" >
                Order #
                </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col" >
                Order Date
                </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Order Time
                </Col>
              <Col xs={12} md={3} lg={3} className="table-header-col">
                Location
                </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Customer Name
                </Col>
              <Col xs={12} md={2} lg={2} className="table-header-col">
                Payment Receipt Number
                </Col>
              <Col xs={12} md={1} lg={1} className="table-header-col">
                Status
              </Col>
            </Row>
            {ordersByLocation.map((item, i) => {


              return (
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col" >
                          <span><b className="mobile-only">Order #:</b> {item.id}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col" >
                          <span><b className="mobile-only">Order Date:</b>{item.order_date}</span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span><b className="mobile-only">Order Time:</b>{item.order_time}</span>
                        </Col>
                        <Col xs={12} md={3} lg={3} className="table-col">
                          <span><b className="mobile-only">Order Location:</b>{item.kitchaco__location}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span><b className="mobile-only">Customer Name:</b>{item.customer_name}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span><b className="mobile-only">Payment Receipt Number:</b>{item.payment_receipt_number}</span>
                        </Col>

                        <Col xs={12} md={2} lg={2} className="mobile-only">
                        <span><b className="mobile-only">Order Status:</b>{item.status}</span>
                        </Col>
                        
                        <Col xs={12} md={2} lg={2} className="mobile-only">
                          <div style={{ padding: 10, backgroundColor: '#f25b4b', borderRadius: 40, marginBottom: 10, color: 'white' }}><b className="mobile-only">Click here to see more details</b></div>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.id} style={{ padding: 20, backgroundColor: '#eff2f5' }}>
                      <>


                        {item.order_source == "Kitchaco" && item.meal && (item.meal).map((meal, j) => {
                          return (
                            <Row>
                              <Col xs={6} md={6} lg={6} className="table-col" style={{ border: 'none' }} >
                                <Row>
                                  <Col xs={12} md={12} lg={12}>
                                    {meal.meal__name}
                                  </Col>
                                </Row>
                                {meal.subitem != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      {meal.subitem}
                                    </Col>
                                  </Row>
                                  : null}
                                {meal.special_request != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      {meal.special_request}
                                    </Col>
                                  </Row>
                                  : null}
                                {meal.combo_array && meal.combo_array.map((combo) => {
                                  return (
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        {combo.name}
                                      </Col>
                                    </Row>
                                  )
                                })
                                }

                              </Col>
                              <Col xs={3} md={3} lg={3} className="table-col" style={{ border: 'none' }} >
                                <Row >
                                  <Col xs={12} md={12} lg={12}>
                                    x{meal.quantity}
                                  </Col>
                                </Row>
                                {meal.subitem != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      <p></p>
                                    </Col>
                                  </Row>
                                  : null}
                                {meal.special_request != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      <p></p>
                                    </Col>
                                  </Row>
                                  : null}

                                {meal.combo_array && meal.combo_array.map((combo) => {
                                  return (
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        x{meal.quantity}
                                      </Col>
                                    </Row>
                                  )
                                })}


                              </Col>
                              <Col xs={3} md={3} lg={3} className="table-col" style={{ border: 'none' }}>
                                <Row>
                                  <Col xs={12} md={12} lg={12}>
                                    ${Number(meal.meal__price).toFixed(2)}
                                  </Col>
                                </Row>
                                {meal.subitem != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      <p></p>
                                    </Col>
                                  </Row>
                                  : null}
                                {meal.special_request != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      <p></p>
                                    </Col>
                                  </Row>
                                  : null}

                                {meal.combo_array && meal.combo_array.map((combo) => {
                                  return (
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        ${Number(combo.price).toFixed(2)}
                                      </Col>
                                    </Row>
                                  )
                                })
                                }
                              </Col>

                            </Row>
                          )
                        })}

                        {/*UBER EATS*/}
                        {item.order_source == "Uber Eats" && item.meal && (item.meal).map((meal, j) => {
                          console.log(meal)
                          var uber_combo = []
                          if(meal.combo.indexOf(",")!==1){
                            uber_combo = (meal.combo).split(",")

                          }
                          else{
                            uber_combo.push(meal.combo)

                          }

                          // Check if more than 1 combo items then check price of main meal
                          var uber_meal_price = meal.sub_total
                          uber_combo.length>0 && uber_combo.map((combo) => {
                            var price = 0
                            if(combo.indexOf("=")!=-1){
                              var temp = combo.split("=")
                              
                              price = temp[1]
                              uber_meal_price-=price
                            }

                            

                            
                            

                          })






                          return (
                            <Row>
                              <Col xs={6} md={6} lg={6} className="table-col" style={{ border: 'none' }} >
                                <Row>
                                  <Col xs={12} md={12} lg={12}>
                                    {meal.meal_name}
                                  </Col>
                                </Row>
                                {meal.subitem != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      {meal.subitem}
                                    </Col>
                                  </Row>
                                  : null}
                                {uber_combo.map((combo) => {
                                  var name = ""
                                  var price = 0
                                  var qty = 0
                                  if(combo.indexOf("=")!=-1){
                                    var temp = combo.split("=")
                                    name = temp[0].split("~")[0]
                                    qty = temp[0].split("~")[1]
                                    price = temp[1]
                                  }
                                  else{
                                    name = combo.split("~")[0]
                                  }
                                  return (
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        {name}
                                      </Col>
                                    </Row>
                                  )
                                })
                                }
                                {meal.special_request != "" ?
                                  <Row style={{ padding: 20 }}>
                                    <Col xs={12} md={12} lg={12}>
                                      {meal.special_request}
                                    </Col>
                                  </Row>
                                  : null}
                              </Col>
                              <Col xs={3} md={3} lg={3} className="table-col" style={{ border: 'none' }} >
                                x{meal.quantity}
                                {uber_combo.length>0 && uber_combo.map((combo) => {
                                 
                                
                                  var qty = 0
                                  if(combo.indexOf("=")!=-1){
                                    var temp = combo.split("=")
                                    qty = temp[0].split("~")[1]
                                  }
                                  else{
                                    qty = combo.split("~")[1]
                                  }
                                  return (
                                    <>
                                    {Number(qty)>0?
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        x{Number(qty)}
                                      </Col>
                                    </Row>
                                    :null}
                                    </>
                                  )
                                })
                                }
                                
                              </Col>
                              <Col xs={3} md={3} lg={3} className="table-col" style={{ border: 'none' }}>
                              
                              

                                ${Number(uber_meal_price).toFixed(2)}
                               
                                {uber_combo.length>0 && uber_combo.map((combo) => {
                                  var price = 0
                                  if(combo.indexOf("=")!=-1){
                                    var temp = combo.split("=")
                                    
                                    price = temp[1]
                                  }
                                  return (
                                    <>
                                    {Number(price)>0?
                                    <Row>
                                      <Col xs={12} md={12} lg={12}>
                                        ${Number(price).toFixed(2)}
                                      </Col>
                                    </Row>
                                    :null}
                                    </>
                                  )
                                })
                                }
                              </Col>

                            </Row>
                          )
                        })}
                        <Row>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Sub Total
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }} >

                          </Col>
                          
                          <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }}>

                            {item.order_source=="Kitchaco"?'$'+Number(item.sub_total).toFixed(2)
                            :
                            '$'+Number(item.payment_total_amount).toFixed(2)
                            }
                          </Col>

                        </Row>
                        {item.delivery_charge && Number(item.delivery_charge) > 0 ?
                          <Row>
                            <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Delivery Charges
                             </Col>
                              </Row>
                            </Col>
                            <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }} >

                            </Col>
                            <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }}>

                              ${Number(item.delivery_charge).toFixed(2)}
                            </Col>

                          </Row>
                          : null}
                        {item.discount && Number(item.discount) > 0 ?
                          <Row>
                            <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Discount
                             </Col>
                              </Row>
                            </Col>
                            <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }} >

                            </Col>
                            <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }}>

                              ${Number(item.discount).toFixed(2)}
                            </Col>

                          </Row>
                          : null}
                        <Row>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Total
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }} >

                          </Col>
                          <Col xs={3} md={3} lg={3} className="table-header-col" style={{ border: 'none' }}>

                            ${Number(item.payment_total_amount).toFixed(2)}
                          </Col>

                        </Row>
                        <hr></hr>
                        <Row>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Order Source
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }}>
                            {item.order_source}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Customer Name
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }}>
                            {item.customer_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Customer Phone
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }}>
                            {item.customer__phone}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={12} lg={12} className="table-header-col" style={{ border: 'none' }} >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Customer Email
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={12} lg={12} className="table-header-col" style={{ border: 'none' }}>
                            {item.customer__email}
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                Order Type
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }}>
                            {item.order_type}
                          </Col>
                        </Row>
                        {item.order_type == "delivery" ?
                          <Row>
                            <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }} >
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  Delivery Location
                              </Col>
                              </Row>
                            </Col>
                            <Col xs={6} md={6} lg={6} className="table-header-col" style={{ border: 'none' }}>
                              {item.delivery_location}
                            </Col>
                          </Row>
                          : null}










                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
              )
            })
            }

          </Container>

        }

      </div>
    )
  }






}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(OperatorOrdersScreen);



