import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"


class StockOrderScreen extends Component {


  state = {
    loggedIn: getFromLocalStorage("stockLoggedIn"),
    orderList: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    email: getFromLocalStorage("stockEmail"),
    password: getFromLocalStorage("stockPassword"),
    stockUserData: getFromLocalStorage("stockUserData") && JSON.parse(getFromLocalStorage("stockUserData")) ? JSON.parse(getFromLocalStorage("stockUserData")) : new Array(),
    

  }



  logout = () => {
    deleteFromLocalStorage("stockLoggedIn", "false")
    deleteFromLocalStorage("stockToken", "")
    deleteFromLocalStorage("stockUserId", -1)
    deleteFromLocalStorage("stockEmail","")
    deleteFromLocalStorage("stockPassword","")
    this.setState({ loggedIn: "false", token: '', user_id: -1 })
    window.location.href="/stock"
  }



  fetchStockOrderListFromServer = () => {
    const {
      email,
      password,
      stockUserData,
    } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "kitchaco": stockUserData["kitchaco__id"], "email": email, "password": password, "query_type": "get_all" });

    console.log("", raw)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(config.BASE_URI + apiPaths.stockOrder)
    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("Get All Orders", result)
        if (result.success) {
          this.setState({ orderList: result.data })
        }


      })
      .catch(error => console.log('error', error));
  }


  containsObject = (obj,list) =>{
    if(list){
      for (var i=0;i<list.length;i++){
        if(list[i].stock_list_id==obj.stock_list_id){
          return true
        }
      }
    }
    return false
  }





  componentDidMount() {
    const {
      loggedIn
    } = this.state
    if(loggedIn=="true"){
      this.fetchStockOrderListFromServer()
    }
    else{
      window.location.href="/stock"
    }
    

  }



  render() {
    return (
      <div>
        {this.renderNavBar()}
        {this.renderOrderPage()}
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      loggedIn,
      stockUserData
    } = this.state
    return (
      <Navbar bg="light" expand="lg" fixed>
        <Navbar.Brand href="#home">
          <Image src={require('../../assets/img/logo.png')} style={{ width: 45 }} roundedCircle fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/stock/">Home</Nav.Link>
            {loggedIn == "true" ?
              <Nav.Link href="/stock-orders/">Order History</Nav.Link>
              :
              null
            }

          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
          {loggedIn == "true" ?
            <>
              <Nav.Link href="#link">Welcome, {stockUserData["first_name"] + ' ' + stockUserData["last_name"]}</Nav.Link>
              <Nav.Link onClick={() => { this.logout() }}>Logout</Nav.Link>
            </>
            :
            null
          }


        </Navbar.Collapse>
      </Navbar>
    )
  }



  renderOrderPage() {
    const {
      orderList,
      stockUserData
    } = this.state
    var todayDate = moment(new Date());
    var todayDay = moment(todayDate).format('dddd');
    var delivery_days = (stockUserData["delivery_day"].toString()).split(",");
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Kitchaco Location: Reservoir</h3>
              <h3>Previous Orders</h3>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Order #
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col" >
              Order Date
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Order Time
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Total
            </Col>
            <Col xs={12} md={3} lg={3} className="table-header-col">
              Status
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">

            </Col>
          </Row>
          {orderList.map((item, i) => {

            return (
              <>
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                        <span><b className="mobile-only">Order Number:</b> {item.id}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.order_date}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.order_time}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span><b className="mobile-only">Total:</b>${item.total}</span>
                        </Col>
                        <Col xs={12} md={3} lg={3} className="table-col">
                          <span><b className="mobile-only">Status:</b>{item.approved ? 'Approved on ' + item.approved_date + ' ' + item.approved_time : 'Pending'}</span>
                          {item.approved?<span style={{fontSize:12,fontStyle:'italic'}}> Approved items might be different from the ones you have ordered. Please check the details. </span>:null}                        
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <div className="details-btn">Details</div>
                      </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.id} style={{padding:20,backgroundColor:'#eff2f5'}}>
                      <>
                      <Row>
                      <Col xs={12} md={3} lg={3}  >
                          <h5>Products Ordered</h5>
                      </Col>
                      </Row>
                      <Row className="table-header-row">
                            <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                              Id
                             </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col" >
                              Product Name
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Qty
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Price
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Sub Total
                            </Col>
                        </Row>
                        {item.orderData.map((product, i) => {
                          
                          return (

                            <Row key={i} className="table-row" style={this.containsObject(product,item.adjustedData)?{backgroundColor:'yellow'}:null}>
                              <Col xs={12} md={1} lg={1} className="table-col">
                                <span className="desktop-only">{product.stock_list_id}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                                {product.name+' ('+product.qty_type__qty_type+')'}
                              </Col>

                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Price:</b> {product.price}</span>
                              </Col>
                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Sub Total:</b>${product.total}</span>
                              </Col>

                            </Row>


                          )
                        })}

                      {item.adjustedData && item.adjustedData.length>0?
                      <>
                      <Row>
                      <Col xs={12} md={3} lg={3}  style={{marginTop:20}} >
                          <h5>Adjusted Items</h5>
                      </Col>
                      </Row>
                      <Row className="table-header-row">
                            <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                              Id
                             </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col" >
                              Product Name
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Qty
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Price
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Sub Total
                            </Col>
                        </Row>
                        {item.adjustedData && item.adjustedData.map((product, i) => {
                         
                          return (

                            <Row key={i} className="table-row">
                              <Col xs={12} md={1} lg={1} className="table-col">
                                <span className="desktop-only">{product.stock_list_id}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                                {product.name+' ('+product.qty_type__qty_type+')'}
                              </Col>

                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Price:</b> {product.price}</span>
                              </Col>
                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Sub Total:</b>${product.total}</span>
                              </Col>

                            </Row>


                          )
                        })}
                        </>
                        :null}







                        {delivery_days.indexOf(todayDay.toString())<0 && !item.approved?
                        <Row className="button-row">
                              <Col xs={12} >
                                <Button onClick={() => { window.location.href="/stock-order-edit:"+item.id }}>Edit</Button>
                              </Col>
                        </Row>
                        :
                        <Row className="button-row">
                              <Col xs={12} >
                                <Button disabled>Edit</Button>
                              </Col>
                              <Col xs={12}>
                                <p><b>Why can't I edit my order?</b></p>
                                <p>You won't be able to edit your order due to one of the following reasons:- </p>
                                <p>1. Orders cannot be edited if they have already been approved.</p>
                                <p>2. Orders cannot be edited on delivery days ({stockUserData["delivery_day"]})</p>
                              </Col>
                        </Row>}
                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
               
              </>

            )
          })}
          

        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockOrderScreen);



