import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import NavBar from './components/NavBar/index';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';

import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage, } from '../../store/index'


import { driverLogin } from '../../actions';
import { Table } from '@material-ui/core';
import moment from 'moment'

class DriverScreen extends Component {

  state = {
    driverLoggedIn: getFromLocalStorage("driverLoggedIn") == 'true' ? true : false,
    adminAccess: getFromLocalStorage("adminAccess") == 'true' ? true : false,
    driverProfileCompleted: getFromLocalStorage("driverProfileCompleted") == 'true' ? true : false,
    driverEmail: getFromLocalStorage("driverEmail") != "" ? getFromLocalStorage("driverEmail") : "",
    driverPassword: getFromLocalStorage("driverPassword") != "" ? getFromLocalStorage("driverPassword") : "",
    name: null,
    promo: null,
    comission: null,
    orderDetails: new Array(),
    orderDelivery: new Array(),
    ordersData: new Array(),
    subtotal: 0,
    balance: 0,
    amountPaid: 0,
    paymentDetails: new Array(),
    bankName: null,
    bankAddress: null,
    bankAccountName: null,
    bankBSB: null,
    bankAccountNumber: null,
    confirmBankAccountNumber: null,
    driverABN: null,
    tncChecked: false,

    adminData: new Array(),
    activeTab: getFromLocalStorage("driverActiveTab") == '' ? 'driver' : getFromLocalStorage("driverActiveTab"),
    allOrders: new Array()



  }



  driverLoginSubmit = () => {
    const { getDriverData, getAdminData } = this
    const { driverEmail, driverPassword } = this.state;

    if (driverEmail != "" && driverPassword != "") {
      let data = {
        username: driverEmail,
        password: driverPassword,
        query_type: 'login',

      };
      // this.props.dispatch(driverLogin(data)).then(response => {
      //   console.log(response)
      // });


      fetch(config.BASE_URI + apiPaths.deliveryDriver, {
        method: 'post',
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then((data) => {
        console.log("driverLogin", data)
        if (data.success) {
          
          saveToLocalStorage("driverLoggedIn", "true")
          saveToLocalStorage("driverActiveTab", "driver")
          saveToLocalStorage("adminAccess", data.driver_details.admin_access)
          saveToLocalStorage("driverEmail", data.driver_details.email)
          saveToLocalStorage("driverPassword", driverPassword)
          this.setState({ driverLoggedIn: true, adminAccess: data.driver_details.admin_access })
          console.log("hello hello", data)
          if (data.driver_details.admin_access) {

            getAdminData()
          }
          else {
            getDriverData()
          }


        }
        else {

          alert(data.error)

        }
      });



    }
    else {
      alert("Email and Password are required fields!")
    }
  }


  logout = () => {

    deleteFromLocalStorage("driverLoggedIn", "false")
    deleteFromLocalStorage("driverProfileCompleted", "false")
    deleteFromLocalStorage("driverEmail", "")
    deleteFromLocalStorage("driverPassword", "")
    deleteFromLocalStorage("adminAccess", "false")
    window.location.reload()
  }


  resetAndSendSms = (orderId) => {
    var sure = window.confirm("Are you sure? This will reset the remove the current driver from Order #" + orderId + " and send again the SMS/Notification to all the other driver");
    if (sure) {
      const { driverEmail, driverPassword } = this.state;

      if (driverEmail != "" && driverPassword != "") {
        let data = {
          username: driverEmail,
          password: driverPassword,
          query_type: 'reset_driver',
          order_id: orderId
        };
        // this.props.dispatch(driverLogin(data)).then(response => {
        //   console.log(response)
        // });


        fetch(config.BASE_URI + apiPaths.deliveryDriver, {
          method: 'post',
          body: JSON.stringify(data)
        }).then(function (response) {
          return response.json();
        }).then((data) => {

          if (data.success) {
            alert("Driver has been successfully removed and SMS/Notification has been resend to other drivers")
            window.location.reload()
          }
          else {
            alert(data.error)
          }
        });



      }
      else {
        alert("Email and Password are required fields!")
      }
    }
  }


  getAdminData = () => {

    const { driverEmail, driverPassword } = this.state;

    if (driverEmail != "" && driverPassword != "") {
      let data = {
        username: driverEmail,
        password: driverPassword,
        query_type: 'admin',
      };
      // this.props.dispatch(driverLogin(data)).then(response => {
      //   console.log(response)
      // });


      fetch(config.BASE_URI + apiPaths.deliveryDriver, {
        method: 'post',
        body: JSON.stringify(data)
      }).then((response) => {
        return response.json();
      }).then((data) => {
        console.log("hello", data.all_orders)
        if (data.success) {
          this.setState({
            adminAccess: data.admin_details.admin_access,
            adminData: data.driver_details,
            allOrders: data.all_orders,
          })
        }
      });
    }

  }




  getDriverData = () => {

    const { driverEmail, driverPassword } = this.state;

    if (driverEmail != "" && driverPassword != "") {
      let data = {
        username: driverEmail,
        password: driverPassword,
        query_type: 'get',
      };
      // this.props.dispatch(driverLogin(data)).then(response => {
      //   console.log(response)
      // });


      fetch(config.BASE_URI + apiPaths.deliveryDriver, {
        method: 'post',
        body: JSON.stringify(data)
      }).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.success) {
          console.log("ola", data)
          this.setState({
            adminAccess: data.driver_details.admin_access,
            name: data.driver_details.first_name,
            promo: data.promo_details.code,
            comission: data.driver_details.promo_comission,
            bankName: data.driver_details.bank_name,
            bankAddress: data.driver_details.bank_address,
            bankAccountName: data.driver_details.bank_account_name,
            bankBSB: data.driver_details.bank_bsb,
            bankAccountNumber: data.driver_details.bank_account_number,
            driverABN: data.driver_details.abn,
            orderDetails: data.order_details,
            orderDelivery: data.order_delivery,
            paymentDetails: data.payment_details,
          }, () => {
            if (!this.state.adminAccess) {
              if (this.isBankDetailsSet()) {
                this.setState({ driverProfileCompleted: true })
              }
              this.calculateEarnings()
            }


          })
        }
      });
    }

  }


  driverProfileSubmit = () => {
    const { driverEmail, driverPassword, bankBSB, bankAccountName, bankName, bankAddress, bankAccountNumber, driverABN, confirmBankAccountNumber, tncChecked } = this.state;
    console.log(driverEmail, driverPassword, bankBSB, bankAccountName, bankName, bankAddress, bankAccountNumber, driverABN)
    if (driverEmail != "" && driverPassword != "" && bankBSB != "" && bankAccountName != "" && bankName != "" && bankAddress != "" && bankAccountNumber != "" && driverABN != "" && tncChecked &&
      driverEmail != null && driverPassword != null && bankBSB != null && bankAccountName != null && bankName != null && bankAddress != null && bankAccountNumber != null && driverABN != null) {
      if (bankAccountNumber == confirmBankAccountNumber) {
        let data = {
          username: driverEmail,
          password: driverPassword,
          query_type: 'update',
          bank_account_name: bankAccountName,
          bank_name: bankName,
          bank_address: bankAddress,
          bank_account_number: bankAccountNumber,

          bank_bsb: bankBSB,
          abn: driverABN,
        };
        console.log(data)
        fetch(config.BASE_URI + apiPaths.deliveryDriver, {
          method: 'post',
          body: JSON.stringify(data)
        }).then((response) => {
          return response.json();
        }).then((data) => {
          if (data.success) {
            saveToLocalStorage("driverProfileCompleted", "true")
            this.setState({
              bankName: data.driver_details.bank_name,
              bankAddress: data.driver_details.bank_address,
              bankAccountName: data.driver_details.bank_account_name,
              bankBSB: data.driver_details.bank_bsb,
              bankAccountNumber: data.driver_details.bank_account_number,
              driverABN: data.driver_details.abn,
              orderDetails: data.order_details,
              orderDelivery: data.order_delivery,
              paymentDetails: data.payment_details,
              driverProfileCompleted: true,


            }, () => {
              this.calculateEarnings()

            })
          }
        });


      }
      else {
        alert("Bank account numbers don't match")
      }


    }
    else {
      alert("Please enter all the fields and try again!")
    }
  }

  calculateSubtotal = () => {
    const { ordersData, comission } = this.state;
    var subtotal = 0;
    ordersData.map((order) => {
      subtotal = subtotal + order.earnings
    })
    return Number(subtotal).toFixed(2);

  }

  calculateBalance = () => {
    const { orderDetails, paymentDetails, subtotal } = this.state;
    var total_paid = 0
    console.log("payment", paymentDetails)
    paymentDetails.map((payment) => {
      total_paid = total_paid + payment.payment
    })
    this.setState({ amountPaid: Number(total_paid).toFixed(2) }, () => {
      var balance = Number(this.state.subtotal).toFixed(2) - Number(this.state.amountPaid).toFixed(2)
      this.setState({ balance: balance })
    })
  }

  isBankDetailsSet() {
    const { bankBSB, bankAccountName, bankName, bankAddress, bankAccountNumber, driverABN } = this.state;
    if (bankBSB == null || bankAccountName == null || bankName == null || bankAddress == null || bankAccountNumber == null || driverABN == null) {
      return false
    }
    else {
      return true
    }
  }

  calculateEarnings = () => {

    var ordersData = new Array()
    const { orderDelivery, orderDetails, comission, driverEmail } = this.state;
    var arr1 = orderDelivery
    var arr2 = orderDetails
    //All ONLY Promo Code Earnings ie Earnings using promo code per new customer
    for (var i = 0; i < orderDetails.length; i++) {
      var delivery_charge = 0.00
      var promo_code_earnings = 0.00
      var earnings = 0.00
      if (orderDetails[i].order_type == "delivery" && orderDetails[i].delivery_driver__email == driverEmail) {
        delivery_charge = orderDetails[i].delivery_charge
      }

      promo_code_earnings = comission
      earnings = Number(delivery_charge) + Number(promo_code_earnings)
      ordersData.push({ id: orderDetails[i].id, customerName: orderDetails[i].customer_name, customer: orderDetails[i].customer, deliveryCharges: Number(delivery_charge), promoCodeComission: Number(promo_code_earnings), earnings: Number(earnings) })

    }

    for (var i = 0; i < orderDelivery.length; i++) {
      var delivery_charge = 0.00
      var promo_code_earnings = 0.00
      var earnings = 0.00
      var include = ordersData.filter(function (order) { return order.id == orderDelivery[i].id }).length
      if (include == 0) {
        delivery_charge = orderDelivery[i].delivery_charge
        promo_code_earnings = 0.00
        earnings = Number(delivery_charge) + Number(promo_code_earnings)
        ordersData.push({ id: orderDelivery[i].id, customerName: orderDelivery[i].customer_name, customer: orderDelivery[i].customer, deliveryCharges: Number(delivery_charge), promoCodeComission: Number(promo_code_earnings), earnings: Number(earnings) })

      }
    }

    ordersData.sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0


    })



    console.log("DD COMMON ==> ", ordersData);
    this.setState({ ordersData: ordersData }, () => {
      this.setState({ subtotal: this.calculateSubtotal() }, () => {
        this.calculateBalance()

      })
    })



  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab }, () => {
      saveToLocalStorage('driverActiveTab', tab)
    })

  }

  componentDidMount() {

    if (this.state.adminAccess) {
      this.getAdminData()
    }
    else {
      this.getDriverData()
    }


  }


  render() {
    const {
      selectedTab,
      dispatch,
      filters,
      selectedCategory,
      loggedIn,
      phone,
      smsSent,
      loginModalToggle,
    }
      = this.props
    const { appReducer } = store.getState()
    const { vendorData, location, products } = appReducer
    const { driverLoggedIn, name, promo, driverEmail, orderDetails, balance, amountPaid, subtotal, driverProfileCompleted, ordersData, adminAccess, adminData, activeTab } = this.state;

    if (adminAccess) {
      return (
        <div>
          <NavBar
            selectedTab={selectedTab}
            dispatch={dispatch}
            loggedIn={loggedIn}
            phone={phone}
            smsSent={smsSent}
            loginModalToggle={loginModalToggle}
            kitchacoLocation={location}
          ></NavBar>
          <div style={{ padding: 20 }}>
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={(k) => this.setActiveTab(k)}
              style={{ marginBottom: 20 }}
            >
              <Tab eventKey="driver" title="Drivers">

              </Tab>
              <Tab eventKey="order" title="Orders">

              </Tab>
            </Tabs>
            {activeTab == 'driver' ? this.renderDriver() : this.renderOrder()}


            <Button style={{ textAlign: 'right', marginTop: 20 }} onClick={() => this.logout()} variant="primary" type="button">
              Logout
            </Button>
          </div>

        </div>

      )
    }
    else {
      return (
        <div>

          <NavBar
            selectedTab={selectedTab}
            dispatch={dispatch}
            loggedIn={loggedIn}
            phone={phone}
            smsSent={smsSent}
            loginModalToggle={loginModalToggle}
            kitchacoLocation={location}
          ></NavBar>
          {driverLoggedIn == true && driverProfileCompleted ?
            <div className="container">
              <Row>
                <Col xs={12} md={4}>
                  <Table style={{ border: '1px solid #dee2e6' }}>
                    <thead style={{ backgroundColor: '#0000000d', borderBottom: '1px solid #dee2e6' }}>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Promo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ borderBottom: '1px solid #dee2e6' }}>

                        <td>{name}</td>
                        <td>{driverEmail}</td>
                        <td>{promo}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} md={8}>
                  <Table style={{ border: '1px solid #dee2e6' }}>
                    <thead style={{ backgroundColor: '#0000000d', borderBottom: '1px solid #dee2e6' }}>
                      <tr>
                        <th>Order Number</th>
                        <th>Customer Name</th>
                        <th>Delivery Charges</th>
                        <th>Promo Code Comission</th>
                        <th>Total Earned(AUD)</th>
                        {/* <th>Order Total Amount</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {ordersData.map((order) => {
                        return (
                          <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                            <td>{order.id}</td>
                            <td>{order.customerName}</td>
                            <td>${order.deliveryCharges}</td>
                            <td>${order.promoCodeComission}</td>
                            <td>${order.earnings}</td>
                            {/* <td>${order.payment_total_amount}</td> */}
                          </tr>
                        )
                      })}
                    </tbody>
                    <tbody style={{ borderBottom: '1px solid #dee2e6' }}>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                    <tbody style={{ borderBottom: '1px solid #dee2e6' }}>
                      <tr>
                        <td>Sub Total:</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>${subtotal}</td>
                      </tr>
                    </tbody>
                    <tbody style={{ borderBottom: '1px solid #dee2e6' }}>
                      <tr>
                        <td>Amount Paid:</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>${Number(amountPaid).toFixed(2)}</td>
                      </tr>
                    </tbody>
                    <thead style={{ borderBottom: '1px solid #dee2e6' }}>
                      <tr>
                        <th>Balance Due:</th>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <th>${Number(balance).toFixed(2)}</th>
                      </tr>
                    </thead>
                  </Table>
                </Col>
              </Row>
              <Button style={{ textAlign: 'right' }} onClick={() => this.logout()} variant="primary" type="button">
                Logout
            </Button>
            </div>
            :
            driverLoggedIn == true && !driverProfileCompleted
              ?
              <div className="container">
                <h3>Complete your profile</h3>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter bank name" value={this.state.bankName} onChange={(e) => this.setState({ bankName: e.target.value })} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Bank Address</Form.Label>
                    <Form.Control type="text" placeholder="Enter bank address" value={this.state.bankAddress} onChange={(e) => this.setState({ bankAddress: e.target.value })} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Bank Account Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter bank account name" value={this.state.bankAccountName} onChange={(e) => this.setState({ bankAccountName: e.target.value })} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Bank Account BSB</Form.Label>
                    <Form.Control type="text" placeholder="Enter bank account BSB" value={this.state.bankBSB} onChange={(e) => this.setState({ bankBSB: e.target.value })} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Bank Account Number</Form.Label>
                    <Form.Control type="text" placeholder="Enter bank account number" value={this.state.bankAccountNumber} onChange={(e) => this.setState({ bankAccountNumber: e.target.value })} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Confirm Bank Account Number</Form.Label>
                    <Form.Control type="text" placeholder="Enter bank account number again" value={this.state.confirmBankAccountNumber} onChange={(e) => this.setState({ confirmBankAccountNumber: e.target.value })} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>ABN</Form.Label>
                    <Form.Control type="text" placeholder="Enter ABN" value={this.state.driverABN} onChange={(e) => this.setState({ driverABN: e.target.value })} />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check checked={this.state.tncChecked} onChange={(evt) => { this.setState({ tncChecked: evt.target.checked }); }} type="checkbox" label="I agree to the terms and conditions" />
                    <a href="https://ac245500-873d-44de-a86c-ae090e26b012.usrfiles.com/ugd/ac2455_7e2da1cefea048039f44a33f8f942804.pdf">Terms and Conditions</a>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Button onClick={() => this.driverProfileSubmit()} variant="primary" type="button">
                      Continue
              </Button>
                  </Form.Group>


                  <Button style={{ textAlign: 'right', marginTop: 20 }} onClick={() => this.logout()} variant="primary" type="button">
                    Logout
            </Button>
                </Form>
              </div>
              :
              <div style={{ width: '50%', margin: 'auto' }}>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={this.state.driverEmail} onChange={(e) => this.setState({ driverEmail: e.target.value })} />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" value={this.state.driverPassword} onChange={(e) => this.setState({ driverPassword: e.target.value })} />
                  </Form.Group>
                  <Button onClick={() => this.driverLoginSubmit()} variant="primary" type="button">
                    Login
            </Button>
                </Form>
              </div>
          }


        </div>

      );
    }
  }

  renderOrder() {
    const { allOrders } = this.state;
    return (
      <Row>
        <Col xs={12}>
          <Table style={{ border: '1px solid #dee2e6' }}>
            <thead style={{ backgroundColor: '#0000000d', borderBottom: '1px solid #dee2e6' }}>
              <tr>
                <th>Order #</th>
                <th>Kitchaco</th>
                <th>Placed At</th>
                <th>Due At</th>
                <th>Status</th>
                <th>Total Amount</th>
                <th>Customer Name</th>
                <th>Customer Number</th>
                <th>Delivery Location</th>
                <th>Delivery Driver</th>
                <th>Driver Status</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {allOrders.map((order) => {
                console.log("hello ", order)
                var kitchaco = order.kitchaco__location.split(' ')
                kitchaco = kitchaco[kitchaco.length - 3]

                var created_at = order.created_at.substring(0, order.created_at.length - 11);
                created_at = moment(created_at, 'YYYY-MM-DDTHH:mm').add(10, 'hours').format("DD MMM YYYY hh:mm A")

                var due_at = order.order_date + " " + order.order_time
                if (order.order_later == false) {
                  due_at = moment(due_at, 'DD MMM YYYY hh:mm A').add(30, 'minutes').format("DD MMM YYYY hh:mm A")
                }

                return (
                  <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                    <td>{order.id}</td>
                    <td>{kitchaco}</td>
                    <td>{created_at}</td>
                    <td>{due_at}</td>
                    <td>{order.status}</td>
                    <td>{order.payment_total_amount}</td>
                    <td>{order.customer_name}</td>
                    <td>{order.customer__phone}</td>
                    <td>{order.delivery_location}</td>
                    <td>{order.delivery_driver__first_name}</td>
                    <td>{order.delivery_driver_status}</td>
                    <td>{order.delivery_driver_status != "Finished" && order.delivery_driver_status != "NA" && (
                      <Button style={{ textAlign: 'right', marginTop: 5, marginBottom: 5 }} onClick={() => this.resetAndSendSms(order.id)} variant="primary" type="button">
                        Reset
                      </Button>
                    )}</td>
                  </tr>


                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
  renderDriver() {
    const { adminData } = this.state;
    return (
      <Row>
        <Col xs={12}>
          <Table style={{ border: '1px solid #dee2e6' }}>
            <thead style={{ backgroundColor: '#0000000d', borderBottom: '1px solid #dee2e6' }}>
              <tr>
                <th>Driver Name</th>
                <th>Location</th>
                <th>Status</th>
                <th>Earnings via deliveries</th>
                <th>Earnings via Promo Code</th>
                <th>Extra Amount assgined by Admin</th>
                <th>Total Earned</th>
                <th>Amount Paid</th>
                <th>Last Payment Date</th>
                <th>Balance Due</th>

              </tr>
            </thead>
            <tbody>
              {adminData.map((driver) => {
                var url = "https://thekitchaco.com/admin/vendor/deliverydriver/"+driver.id+"/change/";
                return (
                  <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                    <td><a href={url} target="_blank">{driver.name}</a></td>
                    <td>{driver.active_all_locations ? 'All Locations' : driver.kitchaco__location}</td>
                    <td>{driver.driver_active ? 'Active' : 'Disabled'}</td>
                    <td>${driver.amount_earned_deliveries}</td>
                    <td>${driver.amount_earned_promo}</td>
                    <td>${driver.extra_earnings}</td>
                    <td>${(Number(Number(driver.amount_earned_deliveries) + Number(driver.amount_earned_promo)+Number(driver.extra_earnings))).toFixed(2)}</td>
                    <td>${driver.amount_paid}</td>
                    <td>{driver.payment_date}</td>
                    <th>${Number(Number(driver.amount_earned_deliveries) + Number(driver.amount_earned_promo) - Number(driver.amount_paid)).toFixed(2)}</th>

                    {/* <td>${order.payment_total_amount}</td> */}
                  </tr>

                )
              })}
              <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>

              </tr>
            </tbody>

          </Table>
        </Col>
      </Row>
    )

  }













}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(DriverScreen);



