import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"


class StockScreen extends Component {


  state = {
    loggedIn: getFromLocalStorage("stockLoggedIn"),
    stockList: new Array(),
    stockUserData: getFromLocalStorage("stockUserData") && JSON.parse(getFromLocalStorage("stockUserData")) ? JSON.parse(getFromLocalStorage("stockUserData")) : new Array(),
    quantitiesArray: [],
    email: getFromLocalStorage("stockEmail") && getFromLocalStorage("stockEmail") != '' ? getFromLocalStorage("stockEmail") : '',
    password: getFromLocalStorage("stockPassword") && getFromLocalStorage("stockPassword") != '' ? getFromLocalStorage("stockPassword") : '',
    isPlacingOrder: false,
    reminderData: new Array(),
  }




  login = () => {
    const { email, password } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "user_login" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.stockAdmin, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {

          this.setState({ loggedIn: "true", stockUserData: result.data }, () => {
            saveToLocalStorage("stockLoggedIn", "true")
            saveToLocalStorage("stockEmail", email)
            saveToLocalStorage("stockPassword", password)
            saveToLocalStorage("stockUserData", JSON.stringify(result.data))
            this.fetchStockListFromServer()
          })
        }
        else {
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }


  logout = () => {
    deleteFromLocalStorage("stockLoggedIn", "false")
    saveToLocalStorage("stockUserData", "[]")
    deleteFromLocalStorage("stockEmail", "")
    deleteFromLocalStorage("stockPassword", "")
    this.setState({ loggedIn: "false" })
    window.location.reload()
  }

  placeOrder = () => {
    const { isPlacingOrder } = this.state
    this.setState({ isPlacingOrder: true })
    var todayDate = moment(new Date());
    var todayDay = moment(todayDate).format('dddd');

    const {
      quantitiesArray,
      stockList,
      stockUserData
    } = this.state

    var delivery_days = (stockUserData["delivery_day"].toString()).split(",");

    console.log(delivery_days)

    if (delivery_days.indexOf(todayDay.toString()) < 0) {


      var stockData = []
      var total = 0
      for (var key in quantitiesArray) {
        var sub_total = Number(Number(stockList[key].price) * Number(quantitiesArray[key])).toFixed(2)
        total = Number(total) + Number(sub_total)
        var obj = {
          "id": stockList[key].id,
          "stock_list_id": stockList[key].id,
          "name": stockList[key].name,
          "qty": quantitiesArray[key],
          "qty_type": stockList[key].qty_type__id,
          "price": stockList[key].price,
          "par": stockList[key].par,
          "total": sub_total

        }
        stockData.push(obj)
      }

      var orderData = {
        "kitchaco": stockUserData["kitchaco__id"],
        "order_date": moment(new Date()).format("DD MMM YYYY"),
        "order_time": moment(new Date()).format("hh:mm A"),
        "total": Number(total).toFixed(2),
        "stockData": stockData
      }

      if (stockData.length > 0 && Number(total) > 0) {
        var data = { "orderData": orderData, "query_type": "place_order" }
        console.log(orderData)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(data);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
          .then(response => response.json())
          .then(result => {
            this.setState({ isPlacingOrder: false })
            if (result.success) {
              alert("Order placed successfully! Your delivery will be done on next " + stockUserData["delivery_day"] + "." + " We will send you an email once it is approved.")
              window.location.reload()
            }
          })
          .catch(error => {
            this.setState({ isPlacingOrder: false })
            console.log('error', error)
          });
      }
      else {
        this.setState({ isPlacingOrder: false })
        alert("Please select altleast 1 item to order")
      }
    }
    else {
      alert("Sorry! " + stockUserData["delivery_day"] + " is/are the delivery day! Orders cannot be placed on delivery days")
    }
  }

  fetchStockListFromServer = () => {
    const { stockUserData } = this.state
    console.log(stockUserData["kitchaco__id"])
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "kitchaco": stockUserData["kitchaco__id"] });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.stockList, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          console.log(result.data)
          this.setState({ stockList: result.data, reminderData: result.reminder_data })
        }


      })
      .catch(error => console.log('error', error));
  }

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value }
    });
  }



  componentDidMount() {
    const {
      loggedIn
    } = this.state
    if (loggedIn == "true") {
      this.fetchStockListFromServer()
    }

    console.log(getFromLocalStorage("stockLoggedIn"), loggedIn, typeof (loggedIn))
  }



  render() {
    const {
      loggedIn
    } = this.state
    return (
      <div>
        {this.renderNavBar()}
        {
          loggedIn == "true" ?
            this.renderHomePage()
            :
            this.renderLoginPage()
        }
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      loggedIn,
      stockUserData
    } = this.state
    return (
      <Navbar bg="light" expand="lg" fixed>
        <Navbar.Brand href="#home">
          <Image src={require('../../assets/img/logo.png')} style={{ width: 45 }} roundedCircle fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/stock/">Home</Nav.Link>
            {loggedIn == "true" ?
              <Nav.Link href="/stock-orders/">Order History</Nav.Link>
              :
              null
            }

          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
          {loggedIn == "true" ?
            <>
              <Nav.Link href="#link">Welcome, {stockUserData["first_name"] + ' ' + stockUserData["last_name"]}</Nav.Link>
              <Nav.Link onClick={() => { this.logout() }}>Logout</Nav.Link>
            </>
            :
            null
          }


        </Navbar.Collapse>
      </Navbar>
    )
  }

  renderLoginPage() {
    const {
      email,
      password
    } = this.state
    return (
      <div>
        <Container>
          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(text) => { console.log("hello ", text.target.value); this.setState({ email: text.target.value }) }} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(text) => { this.setState({ password: text.target.value }) }} />
          </Form.Group>

          <Button onClick={() => { this.login() }} variant="primary" type="submit">
            Login
            </Button>

        </Container>
      </div>
    )
  }

  renderHomePage() {
    const {
      stockList,
      isPlacingOrder,
      reminderData
    } = this.state
    console.log("reminder ", reminderData)
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Kitchaco Location: Reservoir</h3>
            </Col>
          </Row>
          {reminderData.length > 0 ?
            <>
              <Row>
                <Col xs={12}>
                  <h6>Following items are about to be expired. Please order them in your next order - </h6>
                </Col>
              </Row>
              {reminderData.map((item) => {
                return (
                  <Row>
                    <Col xs={12}>
                      <h6>{item["name"]+' expiring on '+item["expiring_on"]} </h6>
                    </Col>
                  </Row>
                )
              })}
            </>
            : null
          }
          <Row className="table-header-row" style={{marginTop:10}}>
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Id
            </Col>
            <Col xs={12} md={3} lg={3} className="table-header-col" >
              Product Name
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Par
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Qty
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Price
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Sub Total
            </Col>
          </Row>
          {stockList.map((item, i) => {

            return (
              <Row key={i} className="table-row">
                <Col xs={12} md={1} lg={1} className="table-col">
                  <h4 className="desktop-only">{item.id}</h4>
                </Col>
                <Col xs={12} md={3} lg={3} className="table-col">
                  <h4>{item.name + " (" + item.qty_type__qty_type + ") "}</h4>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <span><b className="mobile-only">Par:</b> {item.par}</span>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <Form.Group>
                    <Form.Control type="email" placeholder="Quantity Needed" id={"qty_" + i} value={this.state.quantitiesArray[i]}
                      name={this.state.quantitiesArray[i]}
                      onChange={this.handleChange.bind(this, i)} />
                    <Form.Text className="text-muted">
                      {item.qty_type__qty_type}
                    </Form.Text>
                  </Form.Group>
                </Col>

                <Col xs={12} md={2} lg={2} className="table-col">
                  <span><b className="mobile-only">Price:</b> ${item.price}</span>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <span><h4><b className="mobile-only">Sub Total:</b> ${document.getElementById("qty_" + i) ? Number(Number(document.getElementById("qty_" + i).value) * Number(item.price)).toFixed(2) : 0.00}</h4></span>
                </Col>

              </Row>

            )
          })}
          <Row className="button-row">
            <Col xs={12} >
              <Button onClick={() => { if (!isPlacingOrder) { this.placeOrder() } }}>{!isPlacingOrder ? 'Place Order' : 'Loading...'}</Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockScreen);



