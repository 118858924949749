import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"


class StockAdminOrderEditScreen extends Component {


  state = {
    loggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    pendingOrder: new Array(),
    quantitiesArray: [],
    email: getFromLocalStorage("stockAdminEmail") && getFromLocalStorage("stockAdminEmail")!=''?getFromLocalStorage("stockAdminEmail"):'',
    password: getFromLocalStorage("stockAdminPassword") && getFromLocalStorage("stockAdminPassword")!=''?getFromLocalStorage("stockAdminPassword"):'',
    qtyTouched:false,
    stockAdminUserData: getFromLocalStorage("stockAdminUserData") && JSON.parse(getFromLocalStorage("stockAdminUserData")) ? JSON.parse(getFromLocalStorage("stockAdminUserData")) : new Array(),
  }

  

  logout = () => {
    deleteFromLocalStorage("stockAdminLoggedIn", "false")
    deleteFromLocalStorage("stockAdminEmail","")
    deleteFromLocalStorage("stockAdminPassword","")
    this.setState({ loggedIn: "false"})
    window.location.href='/stock-admin'
  }

  approveOrder = () => {
    const {
      quantitiesArray,
      pendingOrder,
    } = this.state

    
    var orderData = pendingOrder[0].orderData
    console.log("orderData ",orderData)
   
    var stockData = []
    var total = 0
    for (var key in quantitiesArray) {
      var sub_total = Number(Number(orderData[key].price) * Number(quantitiesArray[key])).toFixed(2)
      total = Number(total) + Number(sub_total)
      var obj = {
        "id": orderData[key].id,
        "stock_list_id": orderData[key].stock_list_id,
        "name": orderData[key].name,
        "qty": quantitiesArray[key],
        "qty_type": orderData[key].qty_type,
        "price": orderData[key].price,
        "total": sub_total
      }
      stockData.push(obj)
    }

    var orderData = {
      "kitchaco": pendingOrder[0].kitchaco,
      "order_date": moment(new Date()).format("DD MMM YYYY"),
      "order_time": moment(new Date()).format("hh:mm A"),
      "total": Number(total).toFixed(2),
      "stockData": stockData,
      "orderId":pendingOrder[0].id
    }

    

    if (stockData.length > 0 && Number(total) > 0) {
      var data = { "orderData": orderData,"query_type":"admin_approve_order" }
     
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(data);
      console.log(raw)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            alert("Approved Succesfully")
            window.location.href="/stock-admin"
          }
        })
        .catch(error => console.log('error', error));
    }
    else{
      alert("Please select altleast 1 item")
    }
  }

  fetchOrderFromServer = () => {
    const {email,password} = this.state
    var orderId = window.location.href.substring(window.location.href.lastIndexOf(":")+1)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"email":email,"password":password,"query_type":"get_admin_specific_pending","orderId":orderId});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.success==true){
          console.log(result.data)
          this.setState({pendingOrder:result.data},()=>{
            
            var orderData = (this.state.pendingOrder)[0].orderData
            var tempQtyArray=[]
            for (var i=0;i<orderData.length;i++){
              tempQtyArray[i] = orderData[i].qty

            }
            this.setState({quantitiesArray:tempQtyArray},()=>{
              console.log(this.state.quantitiesArray)
            })
          })

        }
        else{
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
      qtyTouched:true
    });
  }


  componentDidMount() {
    const {
      loggedIn
    } = this.state

    this.fetchOrderFromServer()
    
   
  }



  render() {
    const {
      loggedIn
    } = this.state
    return (
      <div>
        {this.renderNavBar()}
        {  this.renderOrderPage()}
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      loggedIn,
      stockAdminUserData
    } = this.state
    return (
      <Navbar bg="light" expand="lg" fixed>
        <Navbar.Brand href="#home">
          <Image src={require('../../assets/img/logo.png')} style={{ width: 45 }} roundedCircle fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/stock/">Home</Nav.Link>
            {loggedIn == "true" ?
              <Nav.Link href="/stock-orders/">Order History</Nav.Link>
              :
              null
            }

          </Nav>
          
          {loggedIn == "true" ?
            <>
              <Nav.Link href="#link">Welcome, {stockAdminUserData["first_name"] + ' ' + stockAdminUserData["last_name"]}</Nav.Link>
              <Nav.Link onClick={() => { this.logout() }}>Logout</Nav.Link>
            </>
            :
            null
          }


        </Navbar.Collapse>
      </Navbar>
    )
  }

 

  renderOrderPage() {
    const {
      pendingOrder,
      qtyTouched
    } = this.state

    
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Kitchaco Location: Reservoir</h3>
              <h4>Order Number {pendingOrder[0] && pendingOrder[0].id}</h4>
              <h4>Order Date {pendingOrder[0] && pendingOrder[0].order_date}</h4>
              <h4>Order Time {pendingOrder[0] && pendingOrder[0].order_time}</h4>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Id
            </Col>
            <Col xs={12} md={3} lg={3} className="table-header-col" >
              Product Name
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Par
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Qty
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Price
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Sub Total
            </Col>
          </Row>
          {pendingOrder[0] && ((pendingOrder[0]).orderData).map((item, i) => {
            var subtotal = 0
            if(qtyTouched==false){
              subtotal = item.total
            }
            else{
              if(document.getElementById("qty_" + i)){
                subtotal = Number(Number(document.getElementById("qty_" + i).value) * Number(item.price)).toFixed(2) 
              }
              else{
                subtotal = 0.00
              }
            }
            return (
              <Row key={i} className="table-row">
                <Col xs={12} md={1} lg={1} className="table-col">
                  <h4 className="desktop-only">{item.id}</h4>
                </Col>
                <Col xs={12} md={3} lg={3} className="table-col">
                  <h4>{item.name +' ('+item.qty_type__qty_type+')'}</h4>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <span><b className="mobile-only">Par:</b> {item.par}</span>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <Form.Group>
                    <Form.Control type="number" placeholder="Quantity Needed" id={"qty_" + i} value={this.state.quantitiesArray[i]}
                      name={this.state.quantitiesArray[i]}
                      onChange={this.handleChange.bind(this, i)} />
                    <Form.Text className="text-muted">
                      {item.qty_type__qty_type}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <span><b className="mobile-only">Price:</b> ${item.price}</span>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <span><h4><b className="mobile-only">Sub Total:</b> {subtotal}</h4></span>
                </Col>

              </Row>

            )
          })}
          <Row className="button-row">
            <Col xs={12} >
              <Button onClick={() => { this.approveOrder() }}>Approve Order</Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockAdminOrderEditScreen);



