import React, { Component } from 'react';
import { connect } from 'react-redux'
import NewNavBar from '../../components/UI/NewNavBar/index';
import Image from 'react-bootstrap/Image'
import './style.css';

import { SQUARE_SCRIPT_URL } from '../../assets/Constants';
import { store, getFromLocalStorage, deleteFromLocalStorage } from '../../store/index'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import CustomButton from '../../components/UI/Button/index';
import { theme } from '../../components/UI/theme';
import PlacesAutocomplete from 'react-places-autocomplete';
import 'date-fns';
import moment from 'moment';
import { applyToken } from '../../services/api'
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';


import TextField from '@material-ui/core/TextField';

import {


  cartLocalization
} from "react-shopping-cart";
import {
  getAllOrders
} from '../../actions';
import Maps from './components/Maps';


class OrdersResponsiveScreen extends Component {
  orderStatusId = 0
  constructor(props) {
    super(props)
    this.state = {
      orders: new Array(),
      showMap: false,
      selectedOrder: {},
      selectedOrderId: -1,
      selectedOrderKitchacoLatLng: {},
      isLoading:false,
    }
  }

  componentDidMount() {

    const { dispatch } = this.props
    applyToken(getFromLocalStorage('token'))
    this.setState({isLoading:true})
    dispatch(getAllOrders()).then((response) => {
      this.setState({isLoading:false})
      if (response.payload) {
        var data = response.payload
        this.setState({ orders: data }, () => {
          console.log("orders", this.state.orders)
        })
      }


    })

    this.checkOrderStatus.bind(this)
    this.orderStatusId = setInterval(this.checkOrderStatus.bind(this), 30000);
  }


  checkOrderStatus() {
    const { dispatch } = this.props
    applyToken(getFromLocalStorage('token'))
    dispatch(getAllOrders()).then((response) => {
      console.log("response", response)
      if (response.payload) {
        var data = response.payload
        this.setState({ orders: data }, () => {
          console.log("orders", this.state.orders)
        })
      }


    })
  }


  render() {

    const { appReducer } = store.getState()
    const { selectedLocation, selectedLocationId,userId } = appReducer
    const { orders,isLoading } = this.state
    const {dispatch} = this.props

    return (
      <div>
       {selectedLocationId && selectedLocation && selectedLocationId != '' && selectedLocation != '' ?
          <NewNavBar kitchacoLocation={selectedLocation} kitchacoLocationId={selectedLocationId}  userId={userId} dispatch={dispatch}></NewNavBar>
          :
          null}
        <Container fluid>
          {!isLoading?
          <>
          {orders && orders.map((order) => {
            
            var sub_total = 0.00
            var current_time = moment(new Date()).format('DD MMM YYYY hh:mm A')
            var order_time = moment(moment(order.order_date+' '+order.order_time,"DD MMM YYYY hh:mm A")).format('DD MMM YYYY hh:mm A')
            var diff = moment(current_time,"DD MMM YYYY hh:mm A").diff(order_time,"DD MMM YYYY hh:mm A")
            diff = Number(diff/1000/60)
           
            var isShowCooking = (order.status == "Cooking" && order.order_later==false) || (order.status == "Cooking" && order.order_later==true && diff>=-30) 
           
            return (
              <div key={order.id} style={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#d2d8dd', borderRadius: 10 }}>
                <Row style={{ padding: 20 }}>
                  <Col lg={4} md={4} xs={12}>
                    <Row className={"mobile-only"}>
                      <Col xs={6}><h4 className={"kitchaco-title"}>Order</h4></Col>
                      <Col xs={6}><h4 className={"kitchaco-title"}>#{order.id}</h4></Col>
                    </Row>
                    <Row className="mobile-only">
                      <Col xs={6}><h5 className={"kitchaco-title"}>Order Status</h5></Col>
                      <Col xs={6}>
                        <Row>
                          {order.status == "On its way" ?
                            <>
                              <Col xs={6}>
                                <h5 className={"kitchaco-title"}>{order.status}</h5>
                              </Col>
                              <Col xs={6} >
                                <CustomButton
                                  backgroundColor={theme.colors.primaryColor}
                                  textColor={theme.colors.textColorLight}
                                  borderRadius={20}
                                  isProfilePic={false}
                                  buttonText={'Track'}
                                  handleButtonClick={() => {
                                    this.setState({ selectedOrder: order, selectedOrderId: order.id, showMap: true, selectedOrderKitchacoLatLng: { lat: parseFloat(order.kitchaco.lat), lng: parseFloat(order.kitchaco.lng) } })

                                  }}
                                ></CustomButton>

                              </Col>
                            </>
                            :
                            <>
                              <Col xs={6}>
                               
                                <h5 className={"kitchaco-title"}>{
                                  order.status=="Cooking" && isShowCooking==false?'Received':order.status
                                }</h5>
                              </Col>
                              <Col xs={6}>
                                <Image style={{ height: 60, marginTop: -20 }} src={order.status == "On its way" ? require('../../assets/img/KDriver_OnItsWay.gif') : isShowCooking ? require('../../assets/img/KDriver_Cooking.gif') : null} fluid />
                              </Col>
                            </>
                          }

                        </Row>
                      </Col>
                    </Row>
                    {order.status=="Cooking" && isShowCooking==false?
                        <Row className={"mobile-only"}>
                          <Col xs={12} md={12} lg={12}>
                            Your order has been received. We will keep your order ready on {order.order_date+' '+order.order_time}
                          </Col>
                        </Row>
                    :null}
                    {order.status!="Finished"?
                    <Row className="mobile-only" >
                      <Col xs={6}>
                          <h5>Order Issues?</h5>
                      </Col>
                      <Col xs={6}>
                          <h5>{order.kitchaco.support_phone}</h5>
                      </Col>
                    </Row>
                    :null}

                    <Row>
                      <Col xs={12}><Image src={order.vendor.image != null ? order.vendor.image : require('../../assets/img/default_cover.jpg')} style={{ maxWidth: '100%' }} /></Col>
                    </Row>
                    {order.meals && (order.meals).map((meal, index) => {
                      var item = new Array()
                      sub_total += Number(meal.quantity) * Number(meal.meal.price)
                      return (
                        <Row style={{ marginTop: 5 }} key={index}>
                          <Col xs={6}>
                            <Row>
                              <Col xs={12}> <h6>{meal.meal.name}</h6></Col>
                            </Row>
                            {meal.combo != "" &&
                              (meal.combo.split(",")).map((combo_item) => {
                                item = meal.meal.combo.filter((c) => { return c.name == combo_item })

                                return (
                                  <Row key={item[0].id}>
                                    <Col xs={12}><h6 style={{ color: '#7c7d80', fontSize: 14 }}>{item[0].name}</h6></Col>
                                  </Row>
                                )
                              })
                            }
                            {meal.subitem != "" &&
                              (
                                <Row>
                                  <Col xs={12}> <h6 style={{ color: '#7c7d80', fontSize: 14 }}>{meal.subitem}</h6></Col>
                                </Row>
                              )}
                            {meal.special_request != "" &&
                              (
                                <Row>
                                  <Col xs={12}> <h6 style={{ color: '#7c7d80', fontSize: 14 }}>{meal.special_request}</h6></Col>
                                </Row>
                              )}


                          </Col>
                          <Col xs={3}>
                            <Row>
                              <Col xs={12}><h6 style={{ textAlign: 'right' }}>x{meal.quantity}</h6>
                              </Col>
                            </Row>
                            {item && item.map((c) => {
                              sub_total += Number(meal.quantity) * Number(c.price)
                              return (
                                <Row key={c.id}>
                                  <Col xs={12}><h6 style={{ textAlign: 'right' }}>x{meal.quantity}</h6>
                                  </Col>
                                </Row>
                              )
                            })}
                          </Col>
                          <Col xs={3}>
                            <Row>
                              <Col xs={12}><h6 style={{ textAlign: 'right' }}>x{meal.meal.price}</h6>
                              </Col>
                            </Row>
                            {item && item.map((c) => {
                              return (
                                <Row key={c.id}>
                                  <Col xs={12}><h6 style={{ textAlign: 'right' }}>${c.price}</h6>
                                  </Col>
                                </Row>
                              )
                            })}
                          </Col>

                        </Row>
                      )

                    })}

                    <Row style={{ marginTop: 5 }}>
                      <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Sub Total</h6></Col>
                      <Col xs={3}><h6 style={{ textAlign: 'right' }}>${Number(sub_total).toFixed(2)}</h6></Col>
                    </Row>
                    {order.order_type == "delivery" &&
                      (<Row style={{ marginTop: 5 }}>
                        <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Delivery Charge</h6></Col>
                        <Col xs={3}><h6 style={{ textAlign: 'right' }}>${order.delivery_charge}</h6></Col>
                      </Row>
                      )}
                    {order.discount != "0.00" &&
                      (<Row style={{ marginTop: 5 }}>
                        <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Discount</h6></Col>
                        <Col xs={3}><h6 style={{ textAlign: 'right' }}>${order.discount}</h6></Col>
                      </Row>
                      )}
                    <Row style={{ marginTop: 5 }}>
                      <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Total</h6></Col>
                      <Col xs={3}><h6 style={{ textAlign: 'right' }}>${order.payment_total_amount}</h6></Col>
                    </Row>

                  </Col>
                  <Col lg={8} md={8} xs={12} style={{ backgroundColor: '#edf0f3' }}>
                    <Row className="desktop-only">
                      <Col xs={6}><h4 className={"kitchaco-title"}>Order</h4></Col>
                      <Col xs={6}><h4 className={"kitchaco-title"}>#{order.id}</h4></Col>
                    </Row>
                    <Row className="desktop-only">
                      <Col xs={6}><h5 className={"kitchaco-title"}>Order Status</h5></Col>
                      <Col xs={6}>
                        <Row>
                          {order.status == "On its way" ?
                            <>
                              <Col xs={6}>
                                <h5 className={"kitchaco-title"}>{order.status}</h5>
                              </Col>
                              <Col xs={6} >
                                <CustomButton
                                  backgroundColor={theme.colors.primaryColor}
                                  textColor={theme.colors.textColorLight}
                                  borderRadius={20}
                                  isProfilePic={false}
                                  buttonText={'Track'}
                                  handleButtonClick={() => {
                                    this.setState({ selectedOrder: order, selectedOrderId: order.id, showMap: true, selectedOrderKitchacoLatLng: { lat: parseFloat(order.kitchaco.lat), lng: parseFloat(order.kitchaco.lng) } })

                                  }}
                                ></CustomButton>

                              </Col>
                            </>
                            :
                            <>
                              <Col xs={6}>
                                <h5 className={"kitchaco-title"}>{order.status=="Cooking" && isShowCooking==false?'Received':order.status}</h5>
                                {order.status=="Cooking" && isShowCooking==false?<p>Your order has been received. We will keep your order ready on {order.order_date+' '+order.order_time}</p>:null}
                              </Col>
                              <Col xs={6}>
                                <Image style={{ height: 60, marginTop: -20 }} src={order.status == "On its way" ? require('../../assets/img/KDriver_OnItsWay.gif') : isShowCooking ? require('../../assets/img/KDriver_Cooking.gif') : null} fluid />
                              </Col>
                            </>
                          }

                        </Row>

                      </Col>
                    </Row>
                    {order.status!="Finished"?
                      <Row className="desktop-only">
                        <Col xs={6}>
                            <h5>Order Issues?</h5>
                        </Col>
                        <Col xs={6}>
                            <h5>{order.kitchaco.support_phone}</h5>
                        </Col>
                      </Row>
                    :null}
                    <Row>
                      <Col xs={6}><h5 className={"kitchaco-title"}>Receipt Number</h5></Col>
                      <Col xs={6}><h5 className={"kitchaco-title"}>{order.payment_receipt_number}</h5></Col>
                    </Row>
                    <Row>
                      <Col xs={6}><h6 className={"kitchaco-title"}>Location:</h6></Col>
                      <Col xs={6}><h6 className={"kitchaco-title"}>Kitchaco - {order.kitchaco.location}</h6></Col>
                    </Row>
                    <Row>
                      <Col xs={6}><h6 className={"kitchaco-title"}>Order Date and Time</h6></Col>
                      <Col xs={6}><h6 className={"kitchaco-title"}>{order.order_date} at {order.order_time}</h6></Col>
                    </Row>
                    <Row>
                      <Col xs={6}><h6 className={"kitchaco-title"}>Order Type</h6></Col>
                      <Col xs={6}><h6 className={"kitchaco-title"}>{order.order_type}</h6></Col>
                    </Row>
                    <Row>
                      <Col xs={6}><h6 className={"kitchaco-title"}>Payment Type</h6></Col>
                      <Col xs={6}><h6 className={"kitchaco-title"}>{order.payment_source} </h6></Col>
                    </Row>
                    <Row>
                      <Col xs={6}><h5 className={"kitchaco-title"}>Amount Paid</h5></Col>
                      <Col xs={6}><h5 className={"kitchaco-title"}>${order.payment_total_amount}</h5></Col>
                    </Row>

                  </Col>
                </Row>
                <div style={{ borderBottom: '1px solid #e2e2e2' }}></div>
              </div>
            )
          })}
          {this.renderMapsModal()}
          </>
          :
          <div style={{textAlign:'center'}}>
          <Image src={require('../../assets/img/loading.gif')} fluid></Image>
          </div>
          }
        </Container>

      </div>
    )
  }

  renderMapsModal() {
    const { showMap, selectedOrderId, selectedOrderKitchacoLatLng, selectedOrder } = this.state
    const { dispatch } = this.props
    const order = selectedOrder
    var sub_total = 0.00
    console.log("map", order)
    return (
      <Modal show={showMap} onHide={() => { this.setState({ showMap: false }) }} centered scrollable={true} >
        <Modal.Header closeButton>
          <Modal.Title>Tracking Order #{selectedOrderId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Maps orderId={selectedOrderId} dispatch={dispatch} kitchacoLatLng={selectedOrderKitchacoLatLng}></Maps>
          {order.meals && (order.meals).map((meal, index) => {
            var item = new Array()
            sub_total += Number(meal.quantity) * Number(meal.meal.price)
            return (
              <Row style={{ marginTop: 5 }} key={index}>
                <Col xs={6}>
                  <Row>
                    <Col xs={12}> <h6>{meal.meal.name}</h6></Col>
                  </Row>
                  {meal.combo != "" &&
                    (meal.combo.split(",")).map((combo_item) => {
                      item = meal.meal.combo.filter((c) => { return c.name == combo_item })

                      return (
                        <Row key={item[0].id}>
                          <Col xs={12}><h6 style={{ color: '#7c7d80', fontSize: 14 }}>{item[0].name}</h6></Col>
                        </Row>
                      )
                    })
                  }
                  {meal.subitem != "" &&
                    (
                      <Row>
                        <Col xs={12}> <h6 style={{ color: '#7c7d80', fontSize: 14 }}>{meal.subitem}</h6></Col>
                      </Row>
                    )}
                  {meal.special_request != "" &&
                    (
                      <Row>
                        <Col xs={12}> <h6 style={{ color: '#7c7d80', fontSize: 14 }}>{meal.special_request}</h6></Col>
                      </Row>
                    )}


                </Col>
                <Col xs={3}>
                  <Row>
                    <Col xs={12}><h6 style={{ textAlign: 'right' }}>x{meal.quantity}</h6>
                    </Col>
                  </Row>
                  {item && item.map((c) => {
                    sub_total += Number(meal.quantity) * Number(c.price)
                    return (
                      <Row key={c.id}>
                        <Col xs={12}><h6 style={{ textAlign: 'right' }}>x{meal.quantity}</h6>
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
                <Col xs={3}>
                  <Row>
                    <Col xs={12}><h6 style={{ textAlign: 'right' }}>x{meal.meal.price}</h6>
                    </Col>
                  </Row>
                  {item && item.map((c) => {
                    return (
                      <Row key={c.id}>
                        <Col xs={12}><h6 style={{ textAlign: 'right' }}>${c.price}</h6>
                        </Col>
                      </Row>
                    )
                  })}
                </Col>

              </Row>
            )

          })}
          <Row style={{ marginTop: 5 }}>
            <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Sub Total</h6></Col>
            <Col xs={3}><h6 style={{ textAlign: 'right' }}>${Number(sub_total).toFixed(2)}</h6></Col>
          </Row>
          {order.order_type == "delivery" &&
            (<Row style={{ marginTop: 5 }}>
              <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Delivery Charge</h6></Col>
              <Col xs={3}><h6 style={{ textAlign: 'right' }}>${order.delivery_charge}</h6></Col>
            </Row>
            )}
          {order.discount != "0.00" &&
            (<Row style={{ marginTop: 5 }}>
              <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Discount</h6></Col>
              <Col xs={3}><h6 style={{ textAlign: 'right' }}>${order.discount}</h6></Col>
            </Row>
            )}
          <Row style={{ marginTop: 5 }}>
            <Col xs={{ number: 5, offset: 4 }}><h6 style={{ textAlign: 'right' }}>Total</h6></Col>
            <Col xs={3}><h6 style={{ textAlign: 'right' }}>${order.payment_total_amount}</h6></Col>
          </Row>


        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6}><h5 className={"kitchaco-title"}>Order Status</h5></Col>
            <Col xs={6}>
              <h5 className={"kitchaco-title"}>{order.status}</h5>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(OrdersResponsiveScreen);



