

export const isRestaurantOpen = (moment,kitchacoLocations, selectedLocationObj, type, selectedDate, selectedTime) => {
    
  var tempDat = new Date()
  if (type == "schedule_order") {
    tempDat = moment(selectedDate).format("DD MMM YYYY") + " " + moment(selectedTime).format("HH:mm")
  }

  


  var currentDateTime = moment(tempDat).format("YYYY-MM-DD HH:mm")
  var currentDate = moment(tempDat).format("YYYY-MM-DD")
  var day = moment(currentDateTime).day();
  console.log("isRestaurantOpen Today's day", day)
  var timings = "0";
  switch (day) {
    case 0:
      timings = selectedLocationObj[0].sunday_time;
      break;
    case 1:
      timings = selectedLocationObj[0].monday_time;
      break;
    case 2:
      timings = selectedLocationObj[0].tuesday_time;
      break;
    case 3:
      timings = selectedLocationObj[0].wednesday_time;
      break;
    case 4:
      timings = selectedLocationObj[0].thursday_time;
      break;
    case 5:
      timings = selectedLocationObj[0].friday_time;
      break;
    case 6:
      timings = selectedLocationObj[0].saturday_time;
      break;


  }

  console.log("isRestaurantOpen", kitchacoLocations)
  if (timings == "0") {
    console.log("isRestaurantOpen CLOSED 1")
    return false
  }

  var startTime = new Array()
  var endTime = new Array()
  var windows = timings.split(",")
  windows.map((item) => {
    var temp = item.split("-")
    startTime.push(temp[0])
    endTime.push(temp[1])
  })
  console.log("isRestaurantOpen TIMINGS FOR DAY", windows)
  console.log("isRestaurantOpen CURRENT TIME", currentDateTime)

  for (var i = 0; i < startTime.length; i++) {
    var diffWithEnd = moment(currentDateTime).diff(
      moment(currentDate + " " + endTime[i]),
      "minutes"
    );


    var diffWithStart = moment(currentDateTime).diff(
      moment(currentDate + " " + startTime[i]),
      "minutes"
    );

    console.log("isRestaurantOpen DIFFERENCE", diffWithStart, diffWithEnd)
    if (diffWithStart > 0 && diffWithEnd < 0) {
      console.log("isRestaurantOpen OPEN")
      return true;
    }
  }
  console.log("isRestaurantOpen CLOSED 2")
  return false;

}