import React, { Component } from 'react';

import FloatingFilterButton from '../../components/UI/FloatingFilterButton';
import { store, getFromLocalStorage } from '../../store/index'
import { getSelectedVendor, clearSelectedVendor, changeTab ,changeSelectedLocation,updateUserLocation} from '../../actions/index'
import { applyToken } from '../../services/api';
import { connect } from 'react-redux'
import '../../App.css'
import '../../index.css'
import MapComponent from '../../components/MapComponent';
import FloatingPanel from '../../components/UI/FloatingPanel'
import ExploreComponent from '../../components/ExploreComponent';
import CartComp from '../../components/CartComponent';
import NewNavBar from '../../components/UI/NewNavBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import VendorSideBar from '../../components/UI/VendorSideBar';
import Modal from 'react-bootstrap/Modal';
import TrackVisibility from 'react-on-screen';
import { apiPaths } from '../../services/apiPath';
import api from '../../services/api'
import config from '../../services/apiConfig'
import { AWS_URL } from '../../assets/Constants'


import {
    CartComponent,
    ProductComponent,
    CheckoutButtonComponent,
    cartLocalization
} from "react-shopping-cart";
import './style.css'
import { theme } from '../../components/UI/theme';
const { getDefaultLocalization } = cartLocalization;
const iPadCaseLocalization = {
    color: "Color",
    iPadCase: "iPad case",
    red: "Red",
    green: "Green",
    yellow: "Yellow",
    AUD: "$"
};
const iPadPropertiesWithAdditionalCostLocalization = {
    yellow: "Yellow"
};
const localization = {
    AUD: '$',
};

class AllVendorsScreen extends Component {
    state = {
        vendors: [],
    }
    componentDidMount() {
        const {dispatch} = this.props
        var kitchacoId = window.location.href.substring(window.location.href.lastIndexOf(":") + 1)
        if(kitchacoId.indexOf('?')!=-1){
            kitchacoId = kitchacoId.split("?")[0]
        }
        this.getAllVendors(kitchacoId)
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Current Location ",position)
            dispatch(updateUserLocation(position.coords.latitude,position.coords.longitude))
        },
        function error(err) {
            console.log("Current Location Error! "+err.code+" "+err.message)
        },
        {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 0
          }
        
        );
        
        
    }
    getAllVendors = (kitchacoId) => {
        var requestOptions = {
            method: 'get',
            redirect: 'follow'
        };

        fetch(config.BASE_URI + apiPaths.explore.kitchacoSpecificVendor + "?location=" + kitchacoId, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({ vendors: result.data })
                this.props.dispatch(changeSelectedLocation({ location: result.data[0].kitchaco__location, id: result.data[0].kitchaco }))
            })
            .catch(error => console.log('error', error));
    }


    render() {
        const {
            vendors
        } = this.state

        const {
            userId,
            dispatch
        } = this.props

        const { appReducer } = store.getState()

        const { products, productsTotal } = appReducer

        
        return (
            <div>
                <NewNavBar kitchacoLocation={vendors && vendors[0] && vendors[0].kitchaco__location} kitchacoLocationId={vendors && vendors[0] && vendors[0].kitchaco} productsState={products} productsTotalState={productsTotal} userId={userId} selectedVendorId={''} dispatch={dispatch}></NewNavBar>
                <Container>
                    <Row style={{ marginTop: 30 }}>
                        <Col xs={12}><h3>Restaurants</h3></Col>
                    </Row>
                    {vendors.map((item, index) => {

                        return (
                            <>
                                {index % 4 == 0 ?
                                    <Row>
                                        <Col xs={12} md={6} lg={3} style={{ cursor: 'pointer', marginTop: 30 }} onClick={() => { window.location.href = "/v2/vendor:" + vendors[index].id }}>
                                            <Card className="card-container">
                                                <div className="cover-image"><Card.Img variant="top" src={vendors[index] && vendors[index].kiosk_image && vendors[index].kiosk_image != "" ? AWS_URL + '/' + vendors[index].kiosk_image : require('../../assets/img/default_cover.jpg')} /></div>
                                                <Card.Body>
                                                    <Card.Title>{vendors[index].title}</Card.Title>
                                                    <Card.Text>
                                                        {(vendors[index].bio).split(' ').slice(0, 30).join(' ')}...
                                                    </Card.Text>
                                                    {(vendors[index].kitchens__title).map((kitchen, i) => {
                                                        return (
                                                            <>
                                                                {i % 2 == 0 ?
                                                                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                        <Col lg={6} md={6} xs={6} style={{ backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content' }}>
                                                                            {(vendors[index].kitchens__title)[i] && (vendors[index].kitchens__title)[i]}
                                                                        </Col>

                                                                        <Col lg={6} md={6} xs={6} style={(vendors[index].kitchens__title)[i + 1] ? { backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content' } : null}>
                                                                            {(vendors[index].kitchens__title)[i + 1] && (vendors[index].kitchens__title)[i + 1]}
                                                                        </Col>
                                                                    </Row>
                                                                    : null}
                                                            </>

                                                        )
                                                    })}

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={6} lg={3} style={{ cursor: 'pointer', marginTop: 30 }} onClick={() => { window.location.href = "/v2/vendor:" + vendors[index + 1].id }}>
                                            {vendors[index + 1] ?
                                                <Card className="card-container">
                                                    <div className="cover-image"><Card.Img variant="top" src={vendors[index + 1] && vendors[index + 1].kiosk_image != "" ? AWS_URL + '/' + vendors[index + 1].kiosk_image : require('../../assets/img/default_cover.jpg')} /></div>
                                                    <Card.Body>
                                                        <Card.Title>{vendors[index + 1] && vendors[index + 1].title}</Card.Title>
                                                        <Card.Text>
                                                            {(vendors[index + 1].bio).split(' ').slice(0, 30).join(' ')}...
                                                        </Card.Text>
                                                        {(vendors[index + 1].kitchens__title).map((kitchen, i) => {
                                                            return (
                                                                <>
                                                                    {i % 2 == 0 ?
                                                                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                            <Col lg={6} md={6} xs={6} style={{ backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content', }}>
                                                                                {(vendors[index + 1].kitchens__title)[i] && (vendors[index + 1].kitchens__title)[i]}
                                                                            </Col>

                                                                            <Col lg={6} md={6} xs={6} style={(vendors[index + 1].kitchens__title)[i + 1] ? { backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content', } : null}>
                                                                                {(vendors[index + 1].kitchens__title)[i + 1] && (vendors[index + 1].kitchens__title)[i + 1]}
                                                                            </Col>
                                                                        </Row>
                                                                        : null}
                                                                </>

                                                            )
                                                        })}
                                                    </Card.Body>
                                                </Card>
                                                : null}
                                        </Col>
                                        <Col xs={12} md={6} lg={3} style={{ cursor: 'pointer', marginTop: 30 }} onClick={() => { window.location.href = "/v2/vendor:" + vendors[index + 2].id }}>
                                            {vendors[index + 2] ?
                                                <Card className="card-container">
                                                    <div className="cover-image"><Card.Img variant="top" src={vendors[index + 2] && vendors[index + 2].kiosk_image != "" ? AWS_URL + '/' + vendors[index + 2].kiosk_image : require('../../assets/img/default_cover.jpg')} /></div>
                                                    <Card.Body>
                                                        <Card.Title>{vendors[index + 2] && vendors[index + 2].title}</Card.Title>
                                                        <Card.Text>
                                                            {(vendors[index + 2].bio).split(' ').slice(0, 30).join(' ')}...
                                                        </Card.Text>
                                                        {(vendors[index + 2].kitchens__title).map((kitchen, i) => {
                                                            return (
                                                                <>
                                                                    {i % 2 == 0 ?
                                                                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                            <Col lg={6} md={6} xs={6} style={{ backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content' }}>
                                                                                {(vendors[index + 2].kitchens__title)[i] && (vendors[index + 2].kitchens__title)[i]}
                                                                            </Col>

                                                                            <Col lg={6} md={6} xs={6} style={(vendors[index + 2].kitchens__title)[i + 1] ? { backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content' } : null}>
                                                                                {(vendors[index + 2].kitchens__title)[i + 1] && (vendors[index + 2].kitchens__title)[i + 1]}
                                                                            </Col>
                                                                        </Row>
                                                                        : null}
                                                                </>

                                                            )
                                                        })}
                                                    </Card.Body>
                                                </Card>
                                                : null}
                                        </Col>
                                        <Col xs={12} md={6} lg={3} style={{ cursor: 'pointer', marginTop: 30 }} onClick={() => { window.location.href = "/v2/vendor:" + vendors[index + 3].id }}>
                                            {vendors[index + 3] ?
                                                <Card className="card-container">
                                                    <div className="cover-image"><Card.Img variant="top" src={vendors[index + 3] && vendors[index + 3].kiosk_image && vendors[index + 3].kiosk_image != "" ? AWS_URL + '/' + vendors[index + 3].kiosk_image : require('../../assets/img/default_cover.jpg')} /></div>
                                                    <Card.Body>
                                                        <Card.Title>{vendors[index + 3] && vendors[index + 3].title}</Card.Title>
                                                        <Card.Text>
                                                            {(vendors[index + 3].bio).split(' ').slice(0, 30).join(' ')}...
                                                        </Card.Text>
                                                        {(vendors[index + 3].kitchens__title).map((kitchen, i) => {
                                                            return (
                                                                <>
                                                                    {i % 2 == 0 ?
                                                                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                            <Col lg={6} md={6} xs={6} style={{ backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content' }}>
                                                                                {(vendors[index + 3].kitchens__title)[i] && (vendors[index + 3].kitchens__title)[i]}
                                                                            </Col>

                                                                            <Col lg={6} md={6} xs={6} style={(vendors[index + 3].kitchens__title)[i + 1] ? { backgroundColor: '#ffefed', borderWidth: 1, borderStyle: 'solid', borderColor: 'black', borderRadius: 40, textAlign: 'center', marginTop: 5,minWidth:'fit-content' } : null}>
                                                                                {(vendors[index + 3].kitchens__title)[i + 1] && (vendors[index + 3].kitchens__title)[i + 1]}
                                                                            </Col>
                                                                        </Row>
                                                                        : null}
                                                                </>

                                                            )
                                                        })}
                                                    </Card.Body>
                                                </Card>
                                                : null}
                                        </Col>

                                    </Row>
                                    : null}
                            </>
                        )
                    })}
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        selectedTab: state.appReducer.selectedTab,
        vendorData: state.appReducer.vendorData,
        filters: state.appReducer.filters,
        selectedCategory: state.appReducer.selectedCategory,
        loggedIn: state.appReducer.loggedIn,
        smsSent: state.appReducer.smsSent,
        loginModalToggle: state.appReducer.loginModalToggle,
        phone: state.appReducer.phone,
        userId: state.appReducer.userId,
        token: state.appReducer.token,
        username: state.appReducer.username,
        email: state.appReducer.email,
        userAddress: state.appReducer.userAddress,
        userImg: state.appReducer.userImg,
        userLocation: state.appReducer.userLocation,
        currentLatitude: state.appReducer.currentLatitude,
        currentLongitude: state.appReducer.currentLongitude,
        selectedVendorData: state.appReducer.selectedVendorData

    }
}

export default connect(mapStateToProps)(AllVendorsScreen);
