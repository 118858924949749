import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'

import InputGroup from 'react-bootstrap/InputGroup'
import Nav from 'react-bootstrap/Nav'



class AdminNavBar extends Component {
    constructor(props) {
        super(props)
    }


    render() {
        const {
            logout,
            isSuperAdminLoggedIn,
        } = this.props
        
        var page = (window.location.href.substring(window.location.href.lastIndexOf("/")+1))
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home">Kitchaco Admin Panel</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {isSuperAdminLoggedIn=="true"?
                        <>
                        <Nav className="mr-auto">
                            <Nav.Link active={page=="admin"?true:false} href="/admin">Dashboard</Nav.Link>
                            <Nav.Link active={page=="admin-orders"?true:false} href="/admin-orders">Orders</Nav.Link>
                            <Nav.Link  active={page=="admin-operators"?true:false} href="/admin-operators">Operators</Nav.Link>
                            <Nav.Link  active={page=="admin-txn-report"?true:false} href="/admin-txn-report">Transactional Report</Nav.Link>
                            {/* <Nav.Link  active={page=="admin-drivers"?true:false} href="/admin-drivers">Drivers</Nav.Link> */}
                        </Nav>
                    
                    <Nav.Link onClick={()=>{logout()}}>Logout</Nav.Link>
                    </>
                    :null}
                </Navbar.Collapse>
            </Navbar>
        );

    }
}

export default AdminNavBar;









