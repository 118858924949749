import React, { Component } from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import Media from 'react-bootstrap/Media'
import InputGroup from 'react-bootstrap/InputGroup'
import EllipsisText from "react-ellipsis-text";
import { theme } from '../theme'
import LazyLoad from 'react-lazy-load';
import 'react-slidedown/lib/slidedown.css'
import './style.css'
import { store } from '../../../store'
import CustomButton from '../Button/index'
import TrackVisibility from 'react-on-screen';


class VendorSideBar extends Component {
    state = {
        descriptionIsVisible: true,
    }
    render() {
        ////console.log(this.props)

        const { appReducer } = store.getState()
        const { selectedVendorData } = appReducer
        const { selectedMealCategory, mealCategories, toggleFoodTruckStreetModal } = this.props
        const listGroupItemTitleStyle = {
            border: 0,
            borderRadius: 'inherit',
            paddingBottom: 0,

        }
        const listGroupItemDescriptionStyle = {
            border: 0,
            borderRadius: 'inherit',
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,

        }
        const listGroupItemDownDownStyle = {
            border: 0,
            borderRadius: 'inherit',
            backgroundColor: theme.colors.primaryColor + '1A',
            borderRadius: 40,
            border: 0,
            padding: 10,
            cursor: 'pointer',
        }
        const headingStyle = {
            fontWeight: 900,
            fontSize: '1.5em'

        }
        const subHeadingStyle = {
            fontWeight: 900,
        }

        const listGroupStyle = {
            backgroundColor: theme.colors.textColorLight,
            height: '100%',
        }
        const ellipsisTextStyle =
        {
            fontSize: 12, lineHeight: 0
        }

        const selectedCategoryStyle = {
            backgroundColor: theme.colors.primaryColor + '1A',
            borderRadius: 40,
            border: 0,
            cursor: 'pointer',
        }
        const picStyle = {
            border: '1px solid',
            borderRadius: 20,
            borderColor: theme.colors.textColorDark,
            backgroundColor: theme.colors.backgroundColor,

        };
        const listGroupItemStyle = {
            border: 0,
            borderRadius: 'inherit',
            paddingLeft: 0,
            paddingRight: 0,
        }
        const dishesStyle = {
            padding: 5,
            width: 'fit-content',
            background: theme.colors.primaryColor + '1a',
            borderRadius: 40,
            textAlign: 'center',
            height: 33,
            marginTop: 10,
            display: 'inline-block',
            marginLeft: 5,
            marginRight: 5,
        }




        const displayCategories = mealCategories.map((category, i) => {
            var k = i;
            if (selectedMealCategory == category) {
                k = i - 1
                return (
                    <ListGroup.Item as="li" style={selectedCategoryStyle} onClick={() => this.props.setDivFocus("category" + k, category)} key={i}>
                        <Media>

                            <Media.Body>
                                <h5 style={{ marginBottom: 0 }}>{category}</h5>
                            </Media.Body>
                        </Media>
                    </ListGroup.Item>
                )
            }
            else {
                k = i - 1
                return (
                    <ListGroup.Item as="li" className="hover" onClick={() => this.props.setDivFocus("category" + k, category)} key={i} style={{ border: 0 }}>
                        <Media>
                            <Media.Body>
                                <h5 style={{ marginBottom: 0 }}>{category}</h5>
                            </Media.Body>
                        </Media>
                    </ListGroup.Item>
                )
            }

        }

        )
        return (
            <div style={listGroupStyle} >
                <TrackVisibility partialVisibility>
                    {
                        ({ isVisible }) => {

                            //console.log("Sahaj", isVisible)
                            if (this.state.descriptionIsVisible != isVisible) {
                                this.setState({ descriptionIsVisible: isVisible })
                            }
                            return (
                                <ListGroup>
                                    <ListGroup.Item style={listGroupItemStyle} >
                                        <Media>
                                            <LazyLoad

                                                width={'100%'}
                                                height={'100%'}
                                                debounce={false}
                                                offsetVertical={500}
                                            >
                                                <img
                                                    width={'95%'}
                                                    height={'95%'}
                                                    className="mr-3"
                                                    src={selectedVendorData.logo ? selectedVendorData.logo : require('../../../assets/img/empty_logo.png')}
                                                    alt="Generic placeholder"
                                                />
                                            </LazyLoad>
                                            <Media.Body>
                                                <h5 style={{ marginBottom: 0 }}>{selectedVendorData.title}</h5>
                                                <div style={{ lineHeight: 1 }}>
                                                    <EllipsisText
                                                        text={selectedVendorData.address}
                                                        length={"85"}
                                                        style={ellipsisTextStyle}
                                                    />
                                                </div>
                                               

                                            </Media.Body>
                                        </Media>
                                        {selectedVendorData.kitchens.map((kitchen, i) => {
                                            return (<div key={i} style={dishesStyle}>
                                                <p>{kitchen.title}</p>
                                            </div>)
                                        })}
                                    </ListGroup.Item>
                                    <ListGroup.Item style={listGroupItemDescriptionStyle}><h6>{selectedVendorData.bio}</h6></ListGroup.Item>
                                </ListGroup>
                            )
                        }
                    }
                </TrackVisibility>
                {/* <ListGroup>
                    <CustomButton
                        backgroundColor={theme.colors.primaryColor}
                        textColor={theme.colors.textColorLight}
                        borderRadius={20}
                        isProfilePic={false}
                        buttonText={'Food Truck in My Street'}
                        handleButtonClick={() => { toggleFoodTruckStreetModal() }}
                        margin={'auto'}
                        buttonWidth={'90%'}
                    ></CustomButton>
                </ListGroup> */}
                {this.state.descriptionIsVisible ?
                    <ListGroup as="ul">
                        <ListGroup.Item style={listGroupItemTitleStyle}>
                            <h4 style={subHeadingStyle}>Categories</h4>
                        </ListGroup.Item>
                        {displayCategories}
                    </ListGroup>
                    :
                    <ListGroup as="ul" style={{position:'fixed',top:5,width:'18%'}}>
                        <ListGroup.Item style={listGroupItemTitleStyle}>
                            <h4 style={subHeadingStyle}>Categories</h4>
                        </ListGroup.Item>
                        {displayCategories}
                    </ListGroup>
                }
                
            </div>
        );
    }
}

export default VendorSideBar;