import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'

import InputGroup from 'react-bootstrap/InputGroup'




class NavBar extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <>
                <Navbar bg="light" className=" justify-content-between">
                    <Form inline>
                        <InputGroup>
                            <img
                                alt=""
                                src={require('../../../../assets/img/logo-round.png')}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                style={{ marginRight: 10 }}
                            />{' '}
                        </InputGroup>
                        <InputGroup style={{ marginRight: 10 }}>
                            Kitchaco Referral Portal
                    </InputGroup>
                    </Form>
                </Navbar>
              

            </>
        );

    }
}

export default NavBar;









