import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import PinComponent from './PinComponent'

import {store, getFromLocalStorage} from '../../store'
import { updateUserLocation, getAllOrders,getAllLocations,changeSelectedLocation,changeTab } from '../../actions';

import { applyToken } from '../../services/api';

class MapComponent extends Component {
    
    state={kitchacoLocationsState:null}
    componentDidMount(){
        
        this.props.dispatch(getAllLocations()).then((data) => {
            if(data.payload){
                // console.log("Hello Map",data.payload)
                this.setState({kitchacoLocationsState:data.payload})
            }
           
        })
    }
    onChildClick = (a)=>{
        const {dispatch} = this.props
        //// console.log("Hello M",this.state.kitchacoLocationsState[a])
        dispatch(changeSelectedLocation({location:this.state.kitchacoLocationsState[a].location,id:this.state.kitchacoLocationsState[a].id}))
        dispatch(changeTab('explore'))
        window.location.href="/";

    }
    render() {
        const {vendorData,dispatch} = this.props
        navigator.geolocation.getCurrentPosition(function(position) {
            dispatch(updateUserLocation(position.coords.latitude,position.coords.longitude))
        });
        const {appReducer} = store.getState()
        const{currentLatitude,currentLongitude} = appReducer 
        
        const {kitchacoLocationsState} = this.state
        var displayTrucksOnMap = <div></div>
        if(kitchacoLocationsState!=null && kitchacoLocationsState.length>0){
            displayTrucksOnMap = kitchacoLocationsState.map((truck, i) => {
                return (
                    
                    <PinComponent
                        key={i}
                        lat={truck.lat}
                        lng={truck.lng}
                        logo={require("../../assets/img/logo-round.png")}
                        text="My Marker"
                        
                    />
                    
                )
            })
        }
        else{
            return displayTrucksOnMap
        }

        const defaultMapOptions = {
            fullscreenControl: false,
            zoomControl:true,
          };
       
        return (
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDwgDtMj_rgnX888YvCHEr4WfQZd12f8wU' }}
                    options={defaultMapOptions}
                    center={{ lat: Number(currentLatitude), lng: Number(currentLongitude)}}
                    defaultZoom={10.5}
                    fullscreenControlOptions= {false}
                    onChildClick={this.onChildClick}
                >
                <PinComponent
                    lat={currentLatitude}
                    lng={currentLongitude}
                    logo={require('../../assets/img/user_location.png')}
                    text="My Location"
                />
                {displayTrucksOnMap}
                
                </GoogleMapReact>
            </div>
        );
    }
}

export default MapComponent;