import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import Form from 'react-bootstrap/Form';
import LazyLoad from 'react-lazy-load';
import Img from 'react-image'
import { store } from '../../store'
import VendorSideBar from '../UI/VendorSideBar';
import { theme } from '../UI/theme';
import CustomButton from '../UI/Button';
import { EMAIL_BOOK_CATERING } from '../../assets/Constants'
import { updateCartProducts, updateCartTotal, changeTab } from '../../actions';



import {
    CartComponent,
    ProductComponent,
    CheckoutButtonComponent,
    cartLocalization
} from "react-shopping-cart";
import CheckBox from '../UI/CheckBox';
import { isRestaurantOpen } from '../../assets/Utils';

const { getDefaultLocalization } = cartLocalization;
// You may take localization object from wherever you want, that's just an example
// For more information, see localization section
const iPadCaseLocalization = {
    color: "Color",
    iPadCase: "iPad case",
    red: "Red",
    green: "Green",
    yellow: "Yellow",
    AUD: "$"
};

const iPadPropertiesWithAdditionalCostLocalization = {
    yellow: "Yellow"
};


const localization = {
    AUD: '$',
};




class VendorComponent extends Component {
    constructor(props) {
        super(props)

    }
    state = {
        mealList: [],
        selectedMealCategory: '',
        mealCategories: [],
        addToCartModal: false,
        isFindOutMoreClicked: false,
        bookingUserName: '',
        bookingUserEmail: '',
        bookingUserPhone: '',
        bookingUserDate: '',
        bookingUserTime: '',
        bookingUserLocation: '',
        bookingUserGuest: '',
        selectedMeal: {},
        combos: {},
        productsState: {},
        productsTotalState: 0,
        mealTotalState: 0,
        quantityPicker: 1,
        showAddToCartToast: false,
        selectedSubItem: {},
        selectedCombo: new Array(),
        additionalCostState: new Array(),
        specialRequest: '',
        divFocus: 'category0',
        product: {
            name: "iPadCase",
            id: "ipad-case",
            path: "/shop/ipad-case/",
            properties: {
                color: [
                    "red",
                    "green",
                    {
                        additionalCost: {
                            GBP: 1,
                            EUR: 2,
                            USD: 3.5
                        },
                        value: "yellow"
                    }
                ]
            },
            propertiesToShowInCart: ["color"],
            prices: { AUD: 70 },
            currency: "AUD",
            imageSrc: "1-483x321.jpeg"
        },
        getProductLocalization: getDefaultLocalization("product", "en", {
            ...iPadCaseLocalization,
            ...iPadPropertiesWithAdditionalCostLocalization
        }),
        getCheckoutButtonLocalization: getDefaultLocalization(
            "checkoutButton",
            "en",
            iPadCaseLocalization
        ),

        getCartLocalization: getDefaultLocalization(
            "cart",
            "en",

        )
    }

    setDivFocus = (categoryid, category) => {
        if (categoryid == "category-1") {
            categoryid = "category0"
        }

        this.setState({ divFocus: categoryid, selectedMealCategory: category })
        console.log("OLAOLA", categoryid, typeof (categoryid))
        document.getElementById(categoryid).scrollIntoView()


    }

    componentDidMount() {

        //console.log("ScreenProps VendorComponent ",this.props)
        const { appReducer } = store.getState()
        //console.log("ScreenProps VendorComponent Reducer",appReducer)
        const { products, productsTotal, selectedVendorData, selectedVendor, location, selectedLocationId } = appReducer
        const { productsState, productsTotalState } = this.state
        if (this.state.selectedMealCategory == '' && Object.keys(selectedVendorData).length != 0) {

            var meal_categories = [];
            this.setSelectedMealCategory(meal_categories, selectedVendorData, '')
        }
        if (this.isEmpty(productsState)) {
            this.setState({ productsState: products })
        }
        if (productsTotalState == 0) {
            this.setState({ productsTotalState: productsTotal })
        }







    }

    componentDidUpdate(prevProps, prevState) {
        const { appReducer } = store.getState()
        const { products } = appReducer
        const { dispatch, setCartProducts } = this.props

        if (this.state.productsState !== products) {

            dispatch(updateCartProducts(this.state.productsState))
            dispatch(updateCartTotal(this.calculateCartTotal(this.state.productsState)))
            setCartProducts(this.state.productsState)

        }
    }


    getMealsByCategory(meals, mealCategories = this.state.mealCategories) {
        // //console.log("Get Meals -  Meals",meals)
        // //console.log("Get Meals -  Meal Categories",mealCategories)
        var exploreMealListing = []
        meals.map((meal, i) => {
            try {
                if (mealCategories.some(item => item === meal.category.title)) {
                    var obj = {}
                    if (exploreMealListing.some(item => meal.category.title in item)) {
                        exploreMealListing.map((t, i) => {
                            if (meal.category.title in t) {
                                var arr = t[meal.category.title]
                                arr.push(meal)
                                var new_obj = {}
                                new_obj[meal.category.title] = arr
                                exploreMealListing.remove(i)
                                exploreMealListing.push(obj)
                            }
                        })
                    }
                    else {
                        var arr = []
                        arr.push(meal)
                        obj[meal.category.title] = arr
                        exploreMealListing.push(obj)
                    }
                }
            }
            catch (err) {

            }
        })

        return exploreMealListing


    }


    getComboArray = (combos) => {

        let comboArray = []
        combos.forEach(combo => {
            comboArray.push([
                { id: combo.id, value: combo.name, isChecked: false, price: combo.price },
            ])
        })

        return comboArray;
    }
    handleCheckComboElement = (event) => {

        let combos = this.state.combos
        let selectedCombo = []

        combos.forEach(combo => {
            combo = combo[0]
            if (combo.value == event.target.value) {
                //console.log("handleCheckComboElement ",combo)
                combo.isChecked = event.target.checked
                if (combo.isChecked) {
                    var cost = Number(this.state.mealTotalState) + (Number(combo.price) * Number(this.state.quantityPicker))
                    var k = combo.value;
                    var v = Number(combo.price) * Number(this.state.quantityPicker)

                    var obj = {}
                    obj[k] = v;
                    this.setState({ additionalCostState: [...this.state.additionalCostState, obj], mealTotalState: cost }, () => {

                    })

                    //this.setState({ additionalCostState: Number(this.state.additionalCostState) + (Number(combo.price) * Number(this.state.quantityPicker)), mealTotalState: cost })
                }
                else {
                    var cost = Number(this.state.mealTotalState) - (Number(combo.price) * Number(this.state.quantityPicker))
                    var k = combo.value;
                    var v = Number(combo.price) * Number(this.state.quantityPicker)
                    var temp = new Array()
                    this.state.additionalCostState.map((item) => {

                        if (Object.keys(item) == k && Object.values(item) == v) {
                            // dont push it into new array
                        }
                        else {
                            var key = Object.keys(item)
                            var val = Object.values(item)

                            var obj = {}
                            obj[key] = val[0]
                            temp.push(obj)
                        }
                    })
                    this.setState({ additionalCostState: temp, mealTotalState: cost }, () => {

                    })
                }
            }

            if (combo.isChecked) {
                selectedCombo.push(combo.value)
            }
        })
        //console.log("handleCheckComboElement", combos,selectedCombo)
        this.setState({ combos: combos, selectedCombo: selectedCombo })
    }







    calculateCartTotal = (products) => {
        console.log("Nyra", products)
        var price = 0
        for (var key in products) {

            price += Number(products[key].prices.AUD) * Number(products[key].quantity);

            products[key].additionalCost && products[key].additionalCost.map((item) => {
                var additionalPrice = Object.values(item)[0] * Number(products[key].quantity)
                if (!isNaN(Number(additionalPrice))) {
                    price += Number(additionalPrice)
                }

            })
        }
        //price = Number(price) + Number(this.state.additionalCostState)
        return price;
    }



    setSelectedMealCategory = (meal_categories, truckData, selectedMealCategory) => {

        if (Object.keys(truckData).length != 0) {
            this.setState({
                mealList: []
            }, () => {


                truckData.meals.sort(function (a, b) {
                    var nameA = a.category.priority, nameB = b.category.priority
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                });

                var temp_category = truckData.meals.map(q => q.category.title)

                meal_categories = temp_category.filter((q, idx) => temp_category.indexOf(q) === idx)
                meal_categories.unshift('All')
                if (selectedMealCategory == '') {
                    selectedMealCategory = 'All'
                }

                this.setState({
                    selectedMealCategory: selectedMealCategory, mealCategories: meal_categories
                }, () => {
                    var tempMealList = []

                    for (var i = 0; i < truckData.meals.length; i++) {
                        if (truckData.meals[i].category.title == this.state.selectedMealCategory) {
                            tempMealList.push(truckData.meals[i])
                        }

                    }

                    this.setState({
                        mealList: [
                            ...this.state.mealList,
                            ...tempMealList
                        ]
                    }, () => {

                    }

                    )

                });
            }

            )


        }
    }

    toggleAddToCartModal() {
        this.state.addToCartModal
            ?
            this.setState({ addToCartModal: false })
            :
            this.setState({ addToCartModal: true })
    }





    addProduct = (key, product, currency) => {

        console.log("Divya Add Product product to be added", product)
        console.log("Divya Add Product Product State", this.state.productsState)


        var values = Object.values(this.state.productsState)
        if (values.length == 0) {
            void this.setState(
                ({
                    productsState: { [key]: cartProduct = { quantity: 0 }, ...restOfProducts }
                }) => ({
                    productsState: {
                        ...restOfProducts,
                        [key]: {
                            ...product,
                            quantity: product.quantity + cartProduct.quantity
                        }
                    }
                }), () => {
                    console.log("Meena Product added", this.state.productsState)


                }
            );
        }
        else {
            for (var i = 0; i < values.length; i++) {
                var value = values[i]
                // console.log("Divya Add Product Values",value)
                // console.log("Divya Add Product Check ",value.id, "==", product.id)
                // console.log("Divya Add Product Check ",value.properties.SpecialRequest.toString(), "==", product.properties.SpecialRequest.toString())
                // console.log("Divya Add Product Check ",value.properties.Combo.toString(), "==", product.properties.Combo.toString())
                // console.log("Divya Add Product Check ",value.properties.Subitem.toString(), "==", product.properties.Subitem.toString())


                console.log("Divya Add Product Check ", value.id == product.id)
                console.log("Divya Add Product Check ", value.properties.SpecialRequest.toString() == product.properties.SpecialRequest.toString())
                console.log("Divya Add Product Check ", value.properties.Combo.toString() == product.properties.Combo.toString())
                console.log("Divya Add Product Check ", value.properties.Subitem.toString() == product.properties.Subitem.toString())

                var match = false;
                var matchKey = -1;
                if (value.id == product.id &&
                    value.properties.SpecialRequest.toString() == product.properties.SpecialRequest.toString() &&
                    value.properties.Combo.toString() == product.properties.Combo.toString() &&
                    value.properties.Subitem.toString() == product.properties.Subitem.toString()
                ) {
                    match = true;
                    matchKey=Object.keys(this.state.productsState)[i]
                    break;
                }
            }
            if (match) {
                console.log("Meena Add Product same product Increase quanity")
                // same product. Increase quanity
                void this.setState(
                    ({
                        productsState: { [matchKey]: cartProduct = { quantity: 0 }, ...restOfProducts }
                    }) => ({
                        productsState: {
                            ...restOfProducts,
                            [matchKey]: {
                                ...product,
                                quantity: product.quantity + cartProduct.quantity
                            }
                        }
                    }), () => {
                        console.log("Meena Product added", this.state.productsState)


                    }
                );
            }
            else{
                // same product with different extras. Add as new product
                console.log("Meena Add Product same product with different extras. Add as new product")
                var key = product.id + "_" + Date.now()
                this.setState({ productsState: { ...this.state.productsState, [key]: product } }, () => {
                    console.log("Meena Product added", this.state.productsState)
                })
            }
        }







        //dispatch(updateCartProducts(products))

    }

    generateProductKey = (id, properties) =>
        `${id}/${Object.entries(properties).join("_")}`;

    updateProduct = (key, updatedProduct) => void console.log(":)");

    removeProduct = key => void console.log(":C");

    isEmpty = obj => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };

    addDefaultSrc = e => {
        e.target.src = require('../../assets/img/empty_logo.png')
    }

    render() {
        const { appReducer } = store.getState()
        const { selectedVendorData, products, productsTotal } = appReducer
        const { mealCategories, selectedMealCategory, addToCartModal, isFindOutMoreClicked, selectedMeal, combos, selectedSubItem, selectedCombo,
            getProductLocalization,
            getCheckoutButtonLocalization,
            getCartLocalization,
            productsState,
            product,
            productsTotalState,
            showAddToCartToast,
            specialRequest,
        } = this.state


        var mealsByCategory = [];
        if (mealCategories.length != 0) {
            mealsByCategory = this.getMealsByCategory(selectedVendorData.meals)
        }
        const {
            addProduct,
            generateProductKey,
            updateProduct,
            removeProduct,
            isEmpty,
            state
        } = this;





        const checkoutButtonElement = (
            <CheckoutButtonComponent
                grandTotal={productsTotalState}
                hidden={isEmpty(productsState) ? true : false}
                checkoutURL="/checkout"
                currency="AUD"
                getLocalization={getCheckoutButtonLocalization}
            />
        );

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col xs={3} style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s" }}>
                            {mealCategories.length != 0 ? <VendorSideBar mealCategories={mealCategories} selectedMealCategory={selectedMealCategory} toggleFoodTruckStreetModal={this.toggleAddToCartModal.bind(this)} setDivFocus={this.setDivFocus.bind(this)} divFocus={this.state.divFocus}></VendorSideBar> : null}
                        </Col>
                        <Col xs={9} style={{ backgroundColor: '#c4c4c473' }}>
                            <div
                                aria-live="polite"
                                aria-atomic="true"
                                style={{
                                    position: 'absolute',
                                    minHeight: '100px',
                                }}
                            >
                                <Toast
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        right: 0,
                                    }}
                                    onClose={() => this.setState({ showAddToCartToast: false })} show={showAddToCartToast} delay={3000} autohide
                                >
                                    <Toast.Header>
                                        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                                        <strong className="mr-auto">Added to Cart</strong>
                                        <small>just now</small>
                                    </Toast.Header>
                                    <Toast.Body>{selectedMeal.name} is successfully added to your cart. </Toast.Body>
                                </Toast>
                            </div>
                            <Container style={{ overflow: 'hidden', height: 300 }}>

                                <LazyLoad
                                    width={'73vw'}
                                    height={'300px'}
                                    debounce={false}
                                    offsetVertical={500}
                                    overflow={false}

                                >

                                    <Img
                                        style={{ width: '73vw' }}
                                        src={[
                                            selectedVendorData.image,
                                            require('../../assets/img/default_cover.jpg')
                                        ]}

                                    />
                                </LazyLoad>
                            </Container>
                            <Container>
                                {mealsByCategory.map((meal, i) =>
                                    <div key={i} id={"category" + i} style={{ paddingBottom: 40, paddingTop: 40, borderBottom: '1px solid #C7CACE' }}>
                                        <Row>
                                            <Col xs={10}><h4 style={{ textAlign: 'left' }}>{Object.keys(meal)}</h4></Col>

                                        </Row>
                                        <Row>
                                            {meal[Object.keys(meal)].map((meal, i) => {


                                                return (
                                                    <Col xs={3} key={i}>
                                                        <Card style={{ height: 370, marginTop: 20 }}>
                                                            <div style={{ minHeight: 160, maxHeight: 160, overflow: 'hidden' }}><Card.Img onError={this.addDefaultSrc} className="align-middle" src={meal.image ? meal.image : require('../../assets/img/empty_logo.png')} /></div>
                                                            <Card.Body variant="bottom">
                                                                <Card.Title>{meal.name}</Card.Title>
                                                                <Card.Text>
                                                                    ${meal.price}
                                                                </Card.Text>
                                                                <Button onClick={() => {
                                                                    const product = {
                                                                        name: meal.name,
                                                                        id: "" + meal.id,
                                                                        path: meal.image ? meal.image : require('../../assets/img/empty_logo.png'),
                                                                        prices: { AUD: Number(meal.price) },
                                                                        currency: "AUD",
                                                                        imageSrc: meal.image ? meal.image : require('../../assets/img/empty_logo.png'),
                                                                        properties: {},
                                                                        propertiesToShowInCart: [],
                                                                        quantity: 1,
                                                                        additionalCost: this.state.additionalCostState,
                                                                    }
                                                                    this.setState({ combos: this.getComboArray(meal.combo), mealTotalState: Number(this.state.quantityPicker) * Number(meal.price) }, () => {
                                                                        this.setState({ selectedMeal: meal, addToCartModal: true })
                                                                    })

                                                                    //addProduct(meal.id,product,"AUD")
                                                                }} style={{backgroundColor:theme.colors.primaryColor,borderColor:theme.colors.primaryColor,color:'white'}}>Add To Cart</Button>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                )

                                            }
                                            )}
                                        </Row>

                                    </div>

                                )}
                            </Container>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col xs={12}>
                            <Toast onClose={() => this.setState({ showAddToCartToast: false })} show={showAddToCartToast} >
                                <Toast.Header>
                                    <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded mr-2"
                                        alt=""
                                    />
                                    <strong className="mr-auto">Added to Cart</strong>
                                </Toast.Header>
                                <Toast.Body>{selectedMeal.name} is successfully added to your cart. </Toast.Body>
                            </Toast>
                        </Col>
                    </Row> */}
                    <Modal show={addToCartModal} onHide={this.toggleAddToCartModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
                            <Card style={{ height: 500, marginTop: 20, overflowY: 'scroll' }}>
                                <div style={{ minHeight: 250, maxHeight: 250, overflow: 'hidden' }}><Card.Img onError={this.addDefaultSrc} className="align-middle" src={selectedMeal.image ? selectedMeal.image : require('../../assets/img/empty_logo.png')} /></div>
                                <Card.Body variant="bottom">
                                    <Card.Title>{selectedMeal.name}</Card.Title>
                                    <Card.Text>
                                        {selectedMeal.description}
                                    </Card.Text>
                                    <Row>
                                        <Col className="text-center" xs={3} onClick={() => {
                                            this.setState((prevState, props) => ({
                                                quantityPicker: prevState.quantityPicker - 1
                                            }));
                                        }}>
                                            <div style={{ borderRadius: "50%", backgroundColor: theme.colors.borderColor, cursor: 'pointer', width: 50, height: 50, display: 'inline-block', lineHeight: "50px" }}>
                                                -
                                               </div>
                                        </Col>
                                        <Col xs={6}> <div style={{ width: 50, height: 50, display: 'inline-block', lineHeight: "50px" }}>
                                            {this.state.quantityPicker}
                                        </div> </Col>
                                        <Col className="text-center" xs={3} onClick={() => {
                                            this.setState((prevState, props) => ({
                                                quantityPicker: prevState.quantityPicker + 1
                                            }));
                                        }}>
                                            <div style={{ borderRadius: "50%", backgroundColor: theme.colors.borderColor, cursor: 'pointer', width: 50, height: 50, display: 'inline-block', lineHeight: "50px" }}>
                                                +
                                               </div>
                                        </Col>
                                    </Row>
                                    {selectedMeal.subitem && selectedMeal.subitem.length > 0 ?
                                        <Row style={{ marginTop: 20 }}>
                                            <Card.Title style={{ textAlign: 'left', backgroundColor: '#c7cace', padding: 10, width: '100%' }}>Pick One</Card.Title>
                                            <Col xs={12}>

                                                {selectedMeal.subitem.map((subitem) => {
                                                    //console.log(subitem)
                                                    return (
                                                        <li style={{ listStyle: 'none', textAlign: 'left' }}>
                                                            <input key={subitem.id} onClick={() => { this.setState({ selectedSubItem: subitem }) }} type="radio" checked={this.state.selectedSubItem === subitem ? true : false} value={subitem.name} /> {subitem.name}
                                                        </li>
                                                    );

                                                })}
                                            </Col>

                                        </Row>
                                        : null}
                                    {selectedMeal.combo && selectedMeal.combo.length > 0 ?
                                        <Row style={{ marginTop: 20 }}>
                                            <Card.Title style={{ textAlign: 'left', backgroundColor: '#c7cace', padding: 10, width: '100%' }}>Add Extras</Card.Title>
                                            <Col xs={12}>

                                                {combos && combos.length > 0 && combos.map((combo) => {

                                                    return (
                                                        <CheckBox
                                                            handleCheckComboElement={this.handleCheckComboElement}
                                                            {...combo} />
                                                    );

                                                })}
                                            </Col>

                                        </Row>
                                        : null}
                                    <Row style={{ marginTop: 20 }}>
                                        <Card.Title style={{ textAlign: 'left', backgroundColor: '#c7cace', padding: 10, width: '100%' }}>Special Request</Card.Title>
                                        <Col xs={12}>
                                            <Form.Group controlId="formBasicSpecialRequest">
                                                <Form.Control type="text" placeholder="Eg: No Onions" value={specialRequest} onChange={(e) => { this.setState({ specialRequest: e.target.value }) }} />
                                            </Form.Group>

                                        </Col>
                                    </Row>



                                </Card.Body>
                            </Card>

                        </Modal.Body>

                        <Modal.Footer>
                            <CustomButton
                                backgroundColor={theme.colors.primaryColor}
                                textColor={theme.colors.textColorLight}
                                borderRadius={20}
                                isProfilePic={false}
                                buttonText={'Add to Cart $' + Number(this.state.mealTotalState).toFixed(2)}
                                handleButtonClick={
                                    () => {
                                        const product = {
                                            name: selectedMeal.name,
                                            id: "" + selectedMeal.id,
                                            path: selectedMeal.image ? selectedMeal.image : require('../../assets/img/empty_logo.png'),
                                            prices: { AUD: Number(selectedMeal.price) },
                                            currency: "AUD",
                                            imageSrc: selectedMeal.image ? selectedMeal.image : require('../../assets/img/empty_logo.png'),
                                            properties: { Combo: [selectedCombo], Subitem: [selectedSubItem.name], SpecialRequest: [specialRequest], },
                                            propertiesToShowInCart: ["Combo", "Subitem", "SpecialRequest"],
                                            quantity: this.state.quantityPicker,
                                            additionalCost: this.state.additionalCostState,

                                        }
                                        this.setState({ addToCartModal: false, }, () => {
                                            this.setState({ showAddToCartToast: true, additionalCostState: new Array(), selectedSubItem: {}, selectedCombo: new Array(), specialRequest: '', }, () => {
                                                addProduct(selectedMeal.id, product, "AUD")

                                            })
                                        })


                                    }
                                }

                                buttonWidth={'90%'}
                                margin={'auto'}
                            >
                            </CustomButton>

                        </Modal.Footer>
                    </Modal>

                </Container>
            </div>
        );
    }
}

export default VendorComponent;
