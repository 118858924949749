import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import CustomButton from '../Button/index';
import { theme } from '../theme';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import {
    loginPhone,
    sendSms,
    logout,
    updateUser,
    getUserProfile,
    changeSelectedLocation,
} from '../../../actions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import OtpInput from 'react-otp-input';
import './style.css'
import { store, getFromLocalStorage, deleteFromLocalStorage } from '../../../store'
import { GOOGLE_API_KEY } from '../../../assets/Constants';
import { applyToken } from '../../../services/api';
import { apiPaths } from '../../../services/apiPath';
import api from '../../../services/api'
import config from '../../../services/apiConfig'
import { FormatListNumbered, TrendingUpRounded } from '@material-ui/icons';
import { isRestaurantOpen } from '../../../assets/Utils';
import moment from 'moment';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

class NewNavBar extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        kitchacoLocations: [],
        showBack: false,
        backUrl: '',
        isOpenLoginModal: false,
        isOpenProfileModal: false,
        buttonPressed: false,
        code: '',
        showChangeLocationConfirmation: false,
        showAddMoreItemsSuggestions: false,
        tempSelectedLocationId: -1,
        tempSelectedLocation: '',
        isLocationClosed: false,
    }

    componentDidMount() {
        const {
            kitchacoLocationId,
            selectedVendorId,
            isOpenLoginModal,
            userId,
            productsState,
        } = this.props
        const {
            kitchacoLocations
        } = this.state
        var showBack = window.location.href.includes("vendor") || window.location.href.includes("checkout") || window.location.href.includes("order-history")
        this.setState({ showBack: showBack })
        if (showBack) {
            if (window.location.href.includes("vendor") || window.location.href.includes("order-history")) {
                this.setState({ backUrl: '/v2/kitchaco:' + kitchacoLocationId })
            }
            else if (window.location.href.includes("checkout")) {
                this.setState({ backUrl: '/v2/vendor:' + selectedVendorId })
            }

        }

        if (window.location.href.includes("kitchaco:")) {
            var kitchacoId = window.location.href.substring(window.location.href.lastIndexOf(":") + 1)
            if (kitchacoId.indexOf('?') != -1) {
                kitchacoId = kitchacoId.split("?")[0]
            }
            this.getAllLocations(kitchacoId)
        }
        else {
            this.getAllLocations(-1)
        }


    }

    getAllLocations = (kitchacoLocationId) => {
        var requestOptions = {
            method: 'get',
            redirect: 'follow'
        };

        fetch(config.BASE_URI + apiPaths.explore.location, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({ kitchacoLocations: result }, () => {
                    if (this.state.kitchacoLocations.length > 0 && kitchacoLocationId != -1) {
                        var selectedLocationObj = this.state.kitchacoLocations.filter(obj => {
                            return obj.id == kitchacoLocationId
                        })
                        var currentTime = moment(new Date()).format('YYYY-MM-DDTHH:mm')
                        if (!isRestaurantOpen(moment, this.state.kitchacoLocations, selectedLocationObj, 'schedule_order', currentTime, currentTime)) {
                            this.setState({ isLocationClosed: true })
                        }
                    }
                })

            })
            .catch(error => console.log('error', error));
    }

    isEmpty = obj => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };

    sendSms = () => {
        this.setState({ buttonPressed: true }, () => {
            this.props.dispatch(sendSms(this.state.phone)).then(() => {
                this.setState({ buttonPressed: false })
            })
        })

    }
    loginPhone = () => {
        this.props.dispatch(loginPhone(this.state.phone, this.state.code)).then((data) => {

            if (data) {
                this.getUserProfile(data["payload"].userId)
            }
            else {
                alert("Error! Please try again!")
            }

        })
    }
    getUserProfile = (userId) => {
        this.props.dispatch(getUserProfile(userId)).then((data) => {

            if (data.payload.username == "" || data.payload.email == null) {
                // console.log("Hello 112 ", data.payload)
                this.toggleProfileModal(true)
            }
            else {
                window.location.reload();
            }

        })
    }

    toggleProfileModal(isOpenProfileModal) {
        this.setState({ isOpenProfileModal: isOpenProfileModal })
    }

    updateUserProfile = (userId) => {
        const { usernameState, emailState } = this.state
        //console.log("Actions updateUserProfile",userId,usernameState,emailState)
        applyToken(getFromLocalStorage('token'))
        this.props.dispatch(updateUser(userId, usernameState, emailState)).then((data) => {
            // console.log("Hello Profile",data)
            this.getUserProfile(data["payload"].id)
        })
    }

    logout = () => {
        this.props.dispatch(logout())
        window.location.href = "/v2/kitchaco:1"
    }

    emptyStorage = () => {
        deleteFromLocalStorage("orderStatus", "")
        deleteFromLocalStorage("orderDate", "")
        deleteFromLocalStorage('paymentStatus', "")
        deleteFromLocalStorage('paymentSource', "")
        deleteFromLocalStorage('paymentReceiptNumber', "")
        deleteFromLocalStorage('paymentWttCharges', 0)
        deleteFromLocalStorage('paymentTxnCharges', 0)
        deleteFromLocalStorage('paymentTotalCharges', 0)
        deleteFromLocalStorage('paymentError', "")
        deleteFromLocalStorage('products', "{}")
        deleteFromLocalStorage('productsTotal', 0)
        deleteFromLocalStorage('orderType', '')
        deleteFromLocalStorage('orderLocation', '')
        deleteFromLocalStorage('orderLater', false)
        deleteFromLocalStorage('deliveryCharges', 0)
        deleteFromLocalStorage('applyPromoCode', "")
        deleteFromLocalStorage('discount', 0)

    }



    render() {
        const {
            kitchacoLocation,
            productsState,
            kitchacoLocationId,
            userId,
            isOpenLoginModalProps,
            productsTotalState,
            togglePaymentModal,
            discountState,
            isLogout,
            orderTypeState,
            deliveryChargesState,
            deliveryAllowedError
        } = this.props
        const {
            kitchacoLocations,
            showBack,
            backUrl,
            isOpenLoginModal,
            showChangeLocationConfirmation,
            isLocationClosed
        } = this.state
        if (isOpenLoginModalProps) {
            if (!isOpenLoginModal) {
                this.setState({ isOpenLoginModal: isOpenLoginModalProps })
            }
        }
        if (isLogout) {
            this.logout()
        }


        var showCheckoutDesktop = false
        var showCheckoutMobile = false
        var showPayNowDesktop = false
        var showPayNowMobile = false
        var showSignInDesktop = false
        var showSignInCartMobile = false

        var showOrderAgainButton = false

        if (window.location.href.includes("order-history")) {
            showOrderAgainButton = true
        }


        console.log("UserId => ", userId)
        if (userId == "" || userId == null) {
            // Not Logged in
            showPayNowDesktop = false
            showPayNowMobile = false
            showSignInDesktop = true
            if (!window.location.href.includes("cart")) {
                // not on cart screen
                if (productsState && !this.isEmpty(productsState)) {
                    showCheckoutDesktop = true
                    showCheckoutMobile = true
                }
            }
            else {
                // on cart screen
                if (productsState && !this.isEmpty(productsState)) {
                    showSignInCartMobile = true
                }
            }


        }
        else if (userId != "" && userId != null) {
            //Logged In
            showSignInDesktop = false
            if (!window.location.href.includes("cart")) {
                // not on cart screen
                if (productsState && !this.isEmpty(productsState)) {
                    showCheckoutMobile = true
                    showCheckoutDesktop = true
                }
            }
            else {
                // on cart screen
                if (productsState && !this.isEmpty(productsState)) {
                    showPayNowMobile = true
                    showPayNowDesktop = true
                }
            }

        }
        else {
            alert("Error!")
        }




        if (getFromLocalStorage("selectedLocationId") != null && kitchacoLocationId && getFromLocalStorage("selectedLocationId") != kitchacoLocationId) {
            if (productsState && !this.isEmpty(productsState)) {
                if (!showChangeLocationConfirmation) {
                    this.setState({ showChangeLocationConfirmation: true, tempSelectedLocationId: kitchacoLocationId, tempSelectedLocation: kitchacoLocation })
                }
            }
            else {
                this.props.dispatch(changeSelectedLocation({ location: kitchacoLocation, id: kitchacoLocationId }))
                window.location.href = "/v2/kitchaco:" + kitchacoLocationId
            }

        }





        return (
            <>
                <Navbar bg="light" expand="lg" >
                    {showBack == false ?
                        <Navbar.Brand href={"/v2/kitchaco:" + kitchacoLocationId}><Image style={{ height: 40 }} src={require("../../../assets/img/logo-round.png")} fluid />&nbsp;Kitchaco</Navbar.Brand>
                        :
                        <Navbar.Brand href={backUrl}><Image style={{ height: 40 }} src={require("../../../assets/img/mobile-website-back.png")} fluid />&nbsp;</Navbar.Brand>
                    }
                    <div className="desktop-only-nav">
                        <Nav style={{ margin: 'auto', display: 'block', width: 'fit-content' }}>
                            <Dropdown style={{ borderRadius: 20, backgroundColor: theme.colors.backgroundColor }}>
                                <Dropdown.Toggle style={{
                                    borderRadius: 20,
                                    backgroundColor: theme.colors.backgroundColor,
                                    borderLeft: 0,
                                    color: '#6f7780',
                                    borderColor: '#d2d8dd'
                                }} id="dropdown-basic">
                                    {kitchacoLocation}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {kitchacoLocations && kitchacoLocations.map(kitchaco => {
                                        return (
                                            <Dropdown.Item key={kitchaco.id} onClick={() => {
                                                if (productsState && !this.isEmpty(productsState)) {
                                                    this.setState({ showChangeLocationConfirmation: true, tempSelectedLocationId: kitchaco.id, tempSelectedLocation: kitchaco.location })
                                                }
                                                else {
                                                    this.props.dispatch(changeSelectedLocation({ location: kitchaco.location, id: kitchaco.id }))
                                                    window.location.href = "/v2/kitchaco:" + kitchaco.id
                                                }

                                            }}>{kitchaco.location}</Dropdown.Item>
                                        )
                                    })}

                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </div>
                    {/* Checkout Button for desktop only on all pages except cart */}


                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">

                        </Nav>
                        {showCheckoutDesktop ?
                            <IconButton aria-label="cart" className="desktop-only" onClick={() => {
                                this.setState({ showAddMoreItemsSuggestions: true })

                            }}>
                                <StyledBadge badgeContent={Object.keys(productsState).length} color="secondary">
                                    <ShoppingCartIcon style={{
                                        backgroundColor: theme.colors.backgroundColor,
                                        fontSize: 40,
                                        padding: 8,
                                        borderRadius: 20,
                                        color: theme.colors.iconColor,
                                        marginLeft: 10,
                                        marginRight: 10,
                                    }}></ShoppingCartIcon>
                                </StyledBadge>
                            </IconButton>

                            : null}
                        {showSignInDesktop ?
                            <>
                                <Nav.Link
                                    onClick={() => {
                                        this.setState({ isOpenLoginModal: true })
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}>
                                    Login

                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => {
                                        window.location.href="/v2/contact-us"
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}>
                                    Trading Hours

                                </Nav.Link>

                            </>
                            :
                            <>

                                {showOrderAgainButton ?
                                    <Nav.Link onClick={() => { window.location.href = "/v2/kitchaco:" + kitchacoLocationId }} style={{ textDecoration: 'none', color: 'black' }}>Order Again</Nav.Link>
                                    :
                                    <Nav.Link onClick={() => { window.location.href = "/v2/order-history" }} style={{ textDecoration: 'none', color: 'black' }}>Order History</Nav.Link>}

                                <Nav.Link onClick={() => { window.location.href="/v2/contact-us" }} style={{ textDecoration: 'none', color: 'black' }}>Trading Hours</Nav.Link>
                                <Nav.Link onClick={() => { this.logout() }} style={{ textDecoration: 'none', color: 'black' }}>Logout</Nav.Link>
                            </>
                        }
                    </Navbar.Collapse>


                </Navbar>
                <div className="mobile-only-nav" >
                    <Nav style={{ margin: 'auto', display: 'block', }}>
                        <Dropdown style={{ backgroundColor: theme.colors.backgroundColor, textAlign: 'center', width: 'fit-content', margin: 'auto' }}>
                            <div style={{ backgroundColor: theme.colors.backgroundColor, paddingLeft: 10 }}>
                                <img src={require('../../../assets/img/map-clicked.png')}></img>

                                <Dropdown.Toggle style={{
                                    backgroundColor: theme.colors.backgroundColor,
                                    border: 0,
                                    color: '#6f7780',
                                    borderColor: '#d2d8dd',

                                }} id="dropdown-basic">
                                    {kitchacoLocation}
                                </Dropdown.Toggle>
                            </div>

                            <Dropdown.Menu style={{ width: '100%' }}>
                                {kitchacoLocations && kitchacoLocations.map(kitchaco => {
                                    return (
                                        <Dropdown.Item key={kitchaco.id} onClick={() => {
                                            if (productsState && !this.isEmpty(productsState)) {
                                                this.setState({ showChangeLocationConfirmation: true, tempSelectedLocationId: kitchaco.id, tempSelectedLocation: kitchaco.location })
                                            }
                                            else {
                                                this.props.dispatch(changeSelectedLocation({ location: kitchaco.location, id: kitchaco.id }))
                                                window.location.href = "/v2/kitchaco:" + kitchaco.id
                                            }

                                        }}>{kitchaco.location}</Dropdown.Item>
                                    )
                                })}

                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </div>
                {isLocationClosed ?
                    <Navbar className="site-closed">
                        <Row style={{ width: '100%', margin: '0 auto' }}>
                            <p style={{ width: '100%' }}>This location is currently closed</p>
                            <a style={{ width: '100%', textDecoration: 'none', color: 'white' }} href="/v2/contact-us">Click here to see trading hours</a>
                        </Row>
                    </Navbar>
                    : null}
                {/* Checkout Button for mobile only on all pages except cart */}
                {showCheckoutMobile ?
                    <Navbar fixed="bottom" className="mobile-only">
                        <Row style={{ width: '100%', padding: 10, margin: '0 auto', backgroundColor: 'white', paddingBottom: 30 }}>
                            <Button
                                style={{
                                    borderStyle: 'solid',
                                    borderRadius: 40,
                                    borderWidth: 1,
                                    borderColor: "#f05b4b",
                                    backgroundColor: '#f05b4b',
                                    marginTop: 20
                                }}
                                onClick={() => {

                                    this.setState({ showAddMoreItemsSuggestions: true })


                                }
                                } block className="button-enabled-pink-style">
                                Checkout Now {Object.keys(productsState).length} items
                            </Button>
                        </Row>
                    </Navbar>
                    : null}

                {/*Pay Now Button for mobile only on cart page only */}

                {showPayNowMobile ?
                    <Navbar fixed="bottom" className="mobile-only">
                        <Row style={{ width: '100%', padding: 10, margin: '0 auto', backgroundColor: 'white', paddingBottom: 30 }}>
                            <Button
                                style={((orderTypeState == 'delivery' && deliveryChargesState != 0.0) || orderTypeState == 'pickup') ? { borderStyle: 'solid', borderRadius: 40, borderWidth: 1, borderColor: "#f05b4b", backgroundColor: '#f05b4b', marginTop: 20 } : { borderStyle: 'solid', borderRadius: 40, borderWidth: 1, marginTop: 20, borderColor: '#999999', backgroundColor: '#cccccc', color: '#666666' }}
                                onClick={() => {
                                    if ((orderTypeState == 'delivery' && deliveryChargesState != 0.0) || orderTypeState == 'pickup') {
                                        togglePaymentModal()
                                    }
                                    else {
                                        if (deliveryAllowedError) {
                                            alert("Error! " + deliveryAllowedError)
                                        }
                                        else {
                                            alert("Error! Please check all the fields and try again!")
                                        }
                                    }

                                }
                                } block className="button-enabled-pink-style">
                                Pay Now ${Number(Number(productsTotalState) - Number(discountState)).toFixed(2)}
                            </Button>
                        </Row>
                    </Navbar>
                    : null}

                {/*Sign In Button for mobile only on cart page only */}
                {showSignInCartMobile ?
                    <Navbar fixed="bottom" className="mobile-only">
                        <Row style={{ width: '100%', padding: 10, margin: '0 auto', backgroundColor: 'white', paddingBottom: 30 }}>
                            <Button
                                style={{
                                    borderStyle: 'solid',
                                    borderRadius: 40,
                                    borderWidth: 1,
                                    borderColor: "#f05b4b",
                                    backgroundColor: '#f05b4b',
                                    marginTop: 20
                                }}
                                onClick={() => {
                                    this.setState({ isOpenLoginModal: true })


                                }
                                } block className="button-enabled-pink-style">
                                Sign In to complete your order
                            </Button>
                        </Row>
                    </Navbar>
                    : null}
                {/*Order Again for mobile only on Order History page only */}
                {showOrderAgainButton ?
                    <Navbar fixed="bottom" className="mobile-only">
                        <Row style={{ width: '100%', padding: 10, margin: '0 auto', backgroundColor: 'white', paddingBottom: 30 }}>
                            <Button
                                style={{
                                    borderStyle: 'solid',
                                    borderRadius: 40,
                                    borderWidth: 1,
                                    borderColor: "#f05b4b",
                                    backgroundColor: '#f05b4b',
                                    marginTop: 20
                                }}
                                onClick={() => {
                                    window.location.href = "/v2/kitchaco:" + kitchacoLocationId


                                }
                                } block className="button-enabled-pink-style">
                                Order Again
                            </Button>
                        </Row>
                    </Navbar>
                    : null}

                {this.renderLoginModal()}
                {this.renderProfileModal()}
                {this.renderChangeLocationConfirmationModal()}
                {this.renderAddMoreItemsSuggestionModal()}

            </>
        )
    }

    renderLoginModal = () => {
        const {
            isOpenLoginModal,
            buttonPressed,
            code
        } = this.state;
        const { appReducer } = store.getState()
        const {
            selectedTab,
            smsSent,
        } = appReducer
        return (
            <Modal show={isOpenLoginModal} onHide={() => { this.setState({ isOpenLoginModal: false }) }}>
                {smsSent === false ?
                    <>
                        <Modal.Header>
                            <Modal.Title>Sign In</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
                            Whether you're creating an account or signing back in, lets's start with your number
                            <PhoneInput
                                containerClass={'phoneInputStyle'}
                                country={'au'}
                                onlyCountries={['au', 'gb']}
                                value={this.state.phone}
                                onChange={phone => this.setState({ phone })}
                                autoFormat={true}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                isValid={(value, country) => {
                                    if (value.match(/12345/)) {
                                        return 'Invalid value: ' + value + ', ' + country.name;
                                    } else if (value.match(/1234/)) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <CustomButton
                                backgroundColor={'#646771'}
                                textColor={theme.colors.textColorLight}
                                borderRadius={20}
                                isProfilePic={false}
                                buttonText={buttonPressed ? 'Loading...' : 'Continue'}
                                handleButtonClick={buttonPressed ? null : this.sendSms}
                                buttonWidth={'90%'}
                                margin={'auto'}
                            >

                            </CustomButton>
                        </Modal.Footer>
                    </>
                    :
                    <>
                        <Modal.Header >
                            <Modal.Title>Verify Your Number</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: '#adb3bf' }}>
                            Enter the confirmation code we have sent to your number
                            <OtpInput
                                onChange={code => this.setState({ code: code }, () => { if (code.length === 4) { this.loginPhone() } })}
                                value={code}
                                numInputs={4}
                                separator={<span>&nbsp;</span>}
                                containerStyle={'otpInputStyle'}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <p>Resend Confirmation Code</p>
                        </Modal.Footer>
                    </>
                }
            </Modal>
        )
    }
    renderProfileModal = () => {
        const {
            isOpenProfileModal,
            usernameState,
            emailState,

        } = this.state

        const { appReducer } = store.getState()
        const {
            userId
        } = appReducer


        return (
            <Modal show={isOpenProfileModal} onHide={() => { this.toggleProfileModal(false) }}>
                <Modal.Header >
                    <Modal.Title>Profile Info</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: '#adb3bf' }}>
                    Enter your profile details below

                    <Form.Group style={{ textAlign: 'left' }}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name"
                            defaultValue={usernameState}
                            onChange={(event) => {
                                this.setState({ usernameState: event.target.value })
                            }} />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" style={{ textAlign: 'left' }}>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email"
                            defaultValue={emailState}
                            onChange={(event) => {
                                this.setState({ emailState: event.target.value })
                            }} />
                    </Form.Group>
                    <CustomButton
                        backgroundColor={usernameState != "" && emailState != "" ? theme.colors.primaryColor : theme.colors.backgroundColor}
                        textColor={usernameState != "" && emailState != "" ? theme.colors.textColorLight : theme.colors.textColorDark}
                        borderRadius={20}
                        isProfilePic={false}
                        buttonText={'Continue'}
                        margin={'auto'}
                        buttonWidth={'90%'}
                        handleButtonClick={usernameState != "" && emailState != "" && emailState && usernameState ? () => { this.updateUserProfile(userId) } : null}
                    ></CustomButton>


                </Modal.Body>

            </Modal>
        )
    }

    renderChangeLocationConfirmationModal() {
        const { showChangeLocationConfirmation, tempSelectedLocationId, tempSelectedLocation } = this.state
        const { dispatch } = this.props
        return (
            <Modal show={showChangeLocationConfirmation} onHide={() => { this.setState({ showChangeLocationConfirmation: false }) }}>
                <Modal.Header >
                    <Modal.Title>Change Location?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: 'black' }}>
                    If you change your location now, you'll lose the products you've selected. Are you sure?

                    <Row style={{ marginTop: 10 }}>
                        <Col xs={6}>
                            <CustomButton
                                backgroundColor={'#f05b4b1a'}
                                textColor={theme.colors.primaryColor}
                                borderRadius={20}
                                isProfilePic={false}
                                buttonText={'Cancel'}
                                margin={'auto'}
                                buttonWidth={'90%'}
                                handleButtonClick={() => {
                                    this.setState({ showChangeLocationConfirmation: false });

                                }}
                            ></CustomButton>
                        </Col>
                        <Col>
                            <CustomButton
                                backgroundColor={theme.colors.primaryColor}
                                textColor={theme.colors.textColorLight}
                                borderRadius={20}
                                isProfilePic={false}
                                buttonText={'Change Location'}
                                margin={'auto'}
                                buttonWidth={'90%'}
                                handleButtonClick={() => {
                                    this.emptyStorage()
                                    this.setState({ showChangeLocationConfirmation: false }, () => {
                                        dispatch(changeSelectedLocation({ location: tempSelectedLocation, id: tempSelectedLocationId }))
                                        window.location.href = "/v2/kitchaco:" + tempSelectedLocationId
                                    });

                                }}
                            ></CustomButton>
                        </Col>
                    </Row>




                </Modal.Body>

            </Modal>
        )
    }

    renderAddMoreItemsSuggestionModal() {
        const { showAddMoreItemsSuggestions } = this.state
        const { dispatch, kitchacoLocationId } = this.props
        return (
            <Modal show={showAddMoreItemsSuggestions} onHide={() => { this.setState({ showAddMoreItemsSuggestions: false }) }}>
                <Modal.Header >
                    <Modal.Title>Craving something else?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: 'black' }}>
                    You can add more dishes to your cart from different restaurants without any additional charges.

                    <Row style={{ marginTop: 10 }}>
                        <Col xs={6}>
                            <CustomButton
                                backgroundColor={'#f05b4b1a'}
                                textColor={theme.colors.primaryColor}
                                borderRadius={20}
                                isProfilePic={false}
                                buttonText={'Show my cart'}
                                margin={'auto'}
                                buttonWidth={'90%'}
                                handleButtonClick={() => {
                                    window.location.href = "/v2/cart"

                                }}
                            ></CustomButton>
                        </Col>
                        <Col>
                            <CustomButton
                                backgroundColor={theme.colors.primaryColor}
                                textColor={theme.colors.textColorLight}
                                borderRadius={20}
                                isProfilePic={false}
                                buttonText={'Add more items'}
                                margin={'auto'}
                                buttonWidth={'90%'}
                                handleButtonClick={() => {
                                    window.location.href = "/v2/kitchaco:" + kitchacoLocationId

                                }}
                            ></CustomButton>
                        </Col>
                    </Row>




                </Modal.Body>

            </Modal>
        )
    }
}

export default NewNavBar;
