import React, { Component } from 'react';



import { Map, Polyline, GoogleApiWrapper, Marker,InfoWindow } from 'google-maps-react';
import LazyLoad from 'react-lazy-load';
import Img from 'react-image'

import { store, getFromLocalStorage } from '../../../store'
import { updateUserLocation, getAllOrders, getAllLocations, changeSelectedLocation, changeTab, trackDriver, calculateDelivery } from '../../../actions';


import { GOOGLE_API_KEY } from '../../../assets/Constants';
import { applyToken } from '../../../services/api';



//https://maps.googleapis.com/maps/api/directions/json?origin=Toronto&destination=Montreal&key=AIzaSyB_1vRZ2BJfcRoETruoolVaBW0qbUqvrH4



const LATITUDE = -37.971237
const LONGITUDE = 144.4926947
class Maps extends Component {

    deliveryStatusId = 0
    state = {
        kitchacoLocationsState: null,
        orderDetails: new Array(),
        pathCoordinates: new Array(),

        deliveryLocation: "",
        currentLocation: "",
        driverName: "",
        bounds: null,
        eta: "",
    }
    componentDidMount() {

        this.checkDeliveryStatus.bind(this)
        this.deliveryStatusId = setInterval(this.checkDeliveryStatus.bind(this), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.deliveryStatusId);
    }

    getCoordinatesFromString(item) {
        var tempCoordinates = item.split("(")[1]
        tempCoordinates = tempCoordinates.split(")")[0]
        tempCoordinates = tempCoordinates.split(" ")


        return { "lat": parseFloat(tempCoordinates[0]), "lng": parseFloat(tempCoordinates[1]) }
    }
    checkDeliveryStatus() {
        const { dispatch, orderId } = this.props
        console.log(getFromLocalStorage("token"))
        if (orderId != -1) {
            applyToken(getFromLocalStorage("token"))
            dispatch(trackDriver(orderId)).then((data) => {
                if (data.payload.success && data.payload.orderDetails) {
                    console.log("hello", data.payload.orderDetails)
                    if (this.state.orderDetails != data.payload.orderDetails && data.payload.orderDetails.length > 0) {
                        var currentLocation = this.getCoordinatesFromString(data.payload.orderDetails[0].current_location)
                        console.log("Current Location", currentLocation)
                        var deliveryLocation = this.getCoordinatesFromString(data.payload.orderDetails[0].delivery_location)
                        this.setState({ orderDetails: data.payload.orderDetails, driverName: data.payload.orderDetails[0].driver, currentLocation: currentLocation, deliveryLocation: deliveryLocation }, () => {
                            this.getDirectionsToDestination()
                        })


                    }
                    else {
                        this.setState({ orderDetails: [], pathCoordinates: [this.props.kitchacoLatLng], driverName: "", currentLocation: this.props.kitchacoLatLng, deliveryLocation: this.props.kitchacoLatLng })
                    }
                }
                else {
                    console.log("hello error", data)
                }


            })

        }
    }

    getDirectionsToDestination() {
        const { currentLocation, deliveryLocation } = this.state

        var origin = currentLocation.lat + "," + currentLocation.lng
        var destination = deliveryLocation.lat + "," + deliveryLocation.lng
        let data = {
            body: {
                url: "https://maps.googleapis.com/maps/api/directions/json?origin=" +
                    origin + "&destination=" + destination + "&key=" + GOOGLE_API_KEY
            }
        }
        this.props.dispatch(calculateDelivery(data)).then(response => {
            console.log("getDirectionsToDestination", response.payload)
            var steps = response.payload.routes[0].legs[0].steps
            var routeToDelivery = new Array()
            var duration = response.payload.routes[0].legs[0].duration.text
            steps.map((step) => {
                var obj = {}
                obj = { "lat": step.start_location.lat, "lng": step.start_location.lng }
                routeToDelivery.push(obj)
            })
            console.log("getDirectionsToDestination directions", routeToDelivery)
            this.setState({ pathCoordinates: routeToDelivery, eta: duration })
        })

    }







    onChildClick = (a) => {
        const { dispatch } = this.props
        //// console.log("Hello M",this.state.kitchacoLocationsState[a])
        dispatch(changeSelectedLocation({ location: this.state.kitchacoLocationsState[a].location, id: this.state.kitchacoLocationsState[a].id }))
        dispatch(changeTab('explore'))
        window.location.href = "/";

    }
    render() {

        const { appReducer } = store.getState()

        const { pathCoordinates, bounds, currentLocation } = this.state
        const { kitchacoLatLng } = this.props


        const defaultMapOptions = {
            fullscreenControl: false,
            zoomControl: true,
        };


        console.log("Kitchaco Lat Lng", kitchacoLatLng, currentLocation)
        return (
            <>
                <div style={{ height: '45vh', width: '100%' }}>

                    {pathCoordinates && pathCoordinates[0] && pathCoordinates[0].lat ?
                        <Map
                            google={this.props.google}
                            zoom={18}
                            center={{
                                lat: currentLocation.lat,
                                lng: currentLocation.lng,
                            }}
                            bounds={bounds}
                            style={{ height: '40vh', width: '95%', position: 'relative' }}
                        >

                            <Polyline
                                path={pathCoordinates}
                                strokeColor="#0000FF"
                                strokeOpacity={0.8}
                                strokeWeight={2} />

                            <Marker
                                name={'Kitchaco'}
                                title={'Kitchaco'}
                                position={kitchacoLatLng}

                                icon={{
                                    url: require('../../../assets/img/logo-round.png'),
                                    anchor: new this.props.google.maps.Point(32, 32),
                                    scaledSize: new this.props.google.maps.Size(32, 32)
                                }} >

                                <InfoWindow
                                    visible={true}>
                                    <div>
                                        <h1>Kitchaco</h1>
                                    </div>
                                </InfoWindow>

                            </Marker>

                            <Marker
                                name={'Driver'}
                                position={currentLocation}
                                icon={{
                                    url: require('../../../assets/img/K_Car_Red_N.png'),
                                    anchor: new this.props.google.maps.Point(32, 32),
                                    scaledSize: new this.props.google.maps.Size(32, 32)
                                }} >
                                    <InfoWindow
                                    position={currentLocation}
                                    visible={true}>
                                    <div>
                                        <h1>Kitchaco</h1>
                                    </div>
                                </InfoWindow>
                                </Marker>

                        </Map>
                        :
                        <div style={{ textAlign: 'center' }}><Img src={require('../../../assets/img/K_CarAnimationRED.gif')} /><p>Tracking Driver ...</p></div>

                    }


                </div>
                {this.state.eta != "" ? <h5>Estimated Arrival In {this.state.eta}</h5> : null}
            </>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (GOOGLE_API_KEY)
})(Maps)
