import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import PaymentForm from './components/PaymentForm.js';
import { SQUARE_SCRIPT_URL } from '../../assets/Constants';
import NavBar from '../../components/UI/NavBar/index';
import { store, getFromLocalStorage, deleteFromLocalStorage } from '../../store/index'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import LazyLoad from 'react-lazy-load';
import Img from 'react-image'
import CustomButton from '../../components/UI/Button/index';
import { theme } from '../../components/UI/theme';
import PlacesAutocomplete from 'react-places-autocomplete';
import 'date-fns';
import moment from 'moment';
import { applyToken } from '../../services/api'
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import {
  CartComponent,
  ProductComponent,
  CheckoutButtonComponent,
  cartLocalization
} from "react-shopping-cart";
import {
  updateOrderType, updateOrderLater, updateOrderLocation,
  changeTab, calculateDelivery, getDelivery, updateDeliveryCharges,
  orderStatus, getPromoCode, updateCartTotal, applyPromoCode, updateOrderDate, validatePromoCode,
} from '../../actions';
import ExploreComponent from '../../components/ExploreComponent';
import MapComponent from '../../components/MapComponent';
import VendorComponent from '../../components/VendorComponent';
import FloatingPanel from '../../components/UI/FloatingPanel';
import { isRestaurantOpen } from '../../assets/Utils';
import config from '../../services/apiConfig';
const { getDefaultLocalization } = cartLocalization;

const iPadCaseLocalization = {
  color: "Color",
  iPadCase: "iPad case",
  red: "Red",
  green: "Green",
  yellow: "Yellow",
  AUD: "$"
};

const iPadPropertiesWithAdditionalCostLocalization = {
  yellow: "Yellow"
};


const localization = {
  AUD: '$',
};







class CheckoutScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      orderStatusState: '',
      orderTypeState: '',
      orderLocationState: '',
      orderLaterState: false,
      orderDateState: '',
      productsState: {},
      productsTotalState: 0,
      deliveryChargesState: 0,
      paymentStatusState: '',
      paymentSourceState: '',
      paymentReceiptNumberState: '',
      paymentErrorState: '',
      paymentErrorDetailsState: '',
      paymentWttChargesState: 0.0,
      paymentTxnChargesState: 0.0,
      paymentTotalChargesState: 0.0,
      paymentProcessStarted: false,
      serverDeliveryChargesArray: new Array(),
      orderPromoCodeValue: "",
      orderPromoCodeState: new Array(),
      promoCodeError: '',
      applyPromoCodeState: '',
      discountState: 0.0,
      getCheckoutButtonLocalization: getDefaultLocalization(
        "checkoutButton",
        "en",
        iPadCaseLocalization
      ),

      getCartLocalization: getDefaultLocalization(
        "cart",
        "en",

      ),
    }
  }

  componentWillMount() {
    const that = this;
    let sqPaymentScript = document.createElement('script');
    // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // production: https://js.squareup.com/v2/paymentform
    sqPaymentScript.src = SQUARE_SCRIPT_URL;
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      that.setState({
        loaded: true
      })
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }

  componentDidMount() {
    //console.log("ScreenProps - Checkout", this.props)
    this.props.dispatch(getDelivery()).then((data) => {
      console.log("version_29102020 getDelivery()",data)
      this.setState({ serverDeliveryChargesArray: data["payload"] })

    })
    const { selectedVendorData } = this.props
    const { appReducer } = store.getState()
    const {
      orderType,
      orderLocation,
      orderLater,
      orderDate,
      deliveryCharges,
      products,
      productsTotal,
      orderPromoCode,
      applyPromoCode,
      discount,
      location,
      selectedLocationId

    } = appReducer

    //console.log("ScreenProps - Checkout Reducers", appReducer)
    //console.log("ScreenProps Checkout Get from storage")



    const {
      orderTypeState,
      orderLocationState,
      orderLaterState,
      orderDateState,
      deliveryChargesState,
      productsState,
      productsTotalState,
      orderPromoCodeState,
      applyPromoCodeState,
      discountState,
    } = this.state
    const { dispatch } = this.props
    if (this.state.selectedMealCategory == '' && Object.keys(selectedVendorData).length != 0) {

      var meal_categories = [];
      this.setSelectedMealCategory(meal_categories, selectedVendorData, '')
    }
    if (this.isEmpty(productsState)) {
      //// console.log("Hello ", productsState, products)
      this.setState({ productsState: products }, () => {

      })
    }
    if (productsTotalState == 0) {
      this.setState({ productsTotalState: productsTotal })
    }
    if (orderTypeState == '') {
      this.setState({ orderTypeState: orderType })
    }
    if (orderLaterState == '') {
      this.setState({ orderLaterState: orderLater })
    }
    if (orderDate == "" || orderDate == null) {
      this.setState({ orderDateState: moment(new Date()).format('YYYY-MM-DDTHH:mm') }, () => {
        dispatch(updateOrderDate(this.state.orderDateState))
      })
    }
    else {
      this.setState({ orderDateState: orderDate })
    }
    if (orderLocationState == '') {
      this.setState({ orderLocationState: orderLocation })
    }
    if (deliveryChargesState == '') {
      this.setState({ deliveryChargesState: deliveryCharges })
    }
    if (orderPromoCodeState == "") {
      this.setState({ orderPromoCodeState: orderPromoCode })
    }
    if (getFromLocalStorage('orderStatus') === "Received" || getFromLocalStorage('orderStatus') === "Cooking" || getFromLocalStorage('orderStatus') === "Ready for pickup" || getFromLocalStorage('orderStatus') === "On its way" ) {
      this.checkOrderStatus.bind(this)
      setInterval(this.checkOrderStatus.bind(this), 30000);
    }
    applyToken(getFromLocalStorage('token'))
    this.props.dispatch(getPromoCode()).then((data) => {
      this.setState({ orderPromoCodeState: data['payload'] })
    })

    if (applyPromoCodeState == '') {
      this.setState({ applyPromoCodeState: applyPromoCode })
    }
    if (discountState == '') {
      this.setState({ discountState: discount })
    }





  }

  emptyStorage = () => {
    deleteFromLocalStorage("orderStatus", "")
    deleteFromLocalStorage("orderDate", "")
    deleteFromLocalStorage('paymentStatus', "")
    deleteFromLocalStorage('paymentSource', "")
    deleteFromLocalStorage('paymentReceiptNumber', "")
    deleteFromLocalStorage('paymentWttCharges', 0)
    deleteFromLocalStorage('paymentTxnCharges', 0)
    deleteFromLocalStorage('paymentTotalCharges', 0)
    deleteFromLocalStorage('paymentError', "")
    deleteFromLocalStorage('products', "{}")
    deleteFromLocalStorage('productsTotal', 0)
    deleteFromLocalStorage('orderType', '')
    deleteFromLocalStorage('orderLocation', '')
    deleteFromLocalStorage('orderLater', false)
    deleteFromLocalStorage('deliveryCharges', 0)
    deleteFromLocalStorage('applyPromoCode', "")
    deleteFromLocalStorage('discount', 0)
  }




  checkOrderStatus() {
    var orderId = getFromLocalStorage("orderId")
    applyToken(getFromLocalStorage('token'))
    if (orderId == "") {
      this.emptyStorage()
    }
    else {
      this.props.dispatch(orderStatus(orderId)).then((data) => {
        if (data) {
          if (data["payload"].status == "Finished") {
            this.emptyStorage()
          }
          this.setState({ orderStatusState: data["payload"].status }, () => {

            if (data["payload"].status == "Finished") {
              window.location.href = "/";
            }
            else {
              window.location.reload();
            }

          })
        }
        else {
          this.emptyStorage();
          window.location.reload();
        }
      })
    }
  }

  componentDidUpdate() {
    const { dispatch } = this.props
    const { appReducer } = store.getState()
    const { selectedVendorData } = appReducer
    // if (selectedVendorData.length != 0) {
    //   dispatch(changeTab("cart"))
    // }
  }





  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  handleChange = orderLocationState => {
    const { dispatch } = this.props;
    this.setState({ orderLocationState: orderLocationState }, () => {
      dispatch(updateOrderLocation(orderLocationState))
    });
  };

  handleSelect = orderLocationState => {
    const { dispatch } = this.props;
    geocodeByAddress(orderLocationState)
      .then(results => {
        //console.log(results[0].formatted_address)
        this.setState({ orderLocationState: results[0].formatted_address }, () => {
          dispatch(updateOrderLocation(results[0].formatted_address))
          this.calculateDeliveryPrices()
        });
        getLatLng(results[0])
      })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
  };

  calculateDeliveryPrices() {
    const { appReducer } = store.getState()
    let delivery = this.state.serverDeliveryChargesArray
    const { selectedLocation, selectedLocationId, orderLocation } = appReducer

    var url = config.BASE_URI + "/api/v1/get-delivery-charge/";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "payload":
        {
          "body":
          {
            "kitchaco": selectedLocationId,
            "deliveryLocation": orderLocation
          }
        }
      });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(distanceResponse => {
        console.log("version_1.0.12 calculateDelivery() => ", distanceResponse)
        if (distanceResponse.success == true) {
          this.setState(
            {
              deliveryChargesState: distanceResponse.delivery_charge,
              deliveryLocationDistance: distanceResponse.distance,
              deliveryAllowed: true,
              deliveryAllowedError: '',
            },
            () => {
              //console.log(this.state.deliveryChargesState)
              this.props.dispatch(updateDeliveryCharges(this.state.deliveryChargesState))
            }
          );
        }
        else {
          this.setState({
            deliveryLocationDistance: null,
            deliveryAllowed: false,
            deliveryAllowedError: distanceResponse.error,
            deliveryChargesState: 0.0,
          }, () => {
            this.props.dispatch(updateDeliveryCharges(this.state.deliveryChargesState))
          });
        }
      })
      .catch(error => console.log('error', error));
  }


  calculateDiscount() {
    const { dispatch } = this.props;

    const { orderPromoCodeState, orderPromoCodeValue, productsTotalState } = this.state;
    const { appReducer } = store.getState()
    const { selectedLocation, selectedLocationId } = appReducer

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + "/api/v1/validate-promo/";
    var raw = JSON.stringify({ "payload": { "body": { "orderLocation": selectedLocationId, "orderPromoCode": orderPromoCodeValue, "totalAmount": productsTotalState } } });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(promoCodeResponse => {
        console.log("version_1.0.12 calculateDiscount() => ", promoCodeResponse)
        if (promoCodeResponse.success == true) {
          var promoCode = promoCodeResponse.promocode
          if (promoCode.promo_type == "Discount Rate") {
            var discount = (promoCode.discount_rate / 100) * productsTotalState
            var newProductsTotalState = Number(productsTotalState) - Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
          }
          else if (promoCode.promo_type == "Discount Dollars") {
            var discount = promoCode.discount_dollars
            var newProductsTotalState = Number(productsTotalState) - Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
          }
          else if (promoCode.promo_type == "Free Item") {
            var discount = 0
            var newProductsTotalState = Number(productsTotalState) - Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue + " " + promoCode.free_item })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue + " " + promoCode.free_item, discount: discount }))
          }
          else if (promoCode.promo_type == "Free Delivery") {
            var discount = this.state.deliveryChargesState
            var newProductsTotalState = Number(productsTotalState) - Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
          }
        }
        else {
          
          this.setState({ promoCodeError: promoCodeResponse.error })
        }
      })
      .catch(error => console.log('error', error));

  }

  changePromo() {

    const { orderPromoCodeState, applyPromoCodeState, productsTotalState, discountState } = this.state;
    const { appReducer } = store.getState()
    const { selectedLocation } = appReducer

    console.log("changePromo 1")
    var today = moment(new Date()).format('YYYY-MM-DDTHH:mm')

    for (var i = 0; i < orderPromoCodeState.length; i++) {
      var promoCode = orderPromoCodeState[i];
      if (promoCode.kitchaco && promoCode.kitchaco.location == selectedLocation) {

        var promoCodeExpiry = moment(promoCode.valid_till).format('YYYY-MM-DD')
        var diffInDays = moment(promoCodeExpiry).diff(moment(today), "days");
        if (applyPromoCodeState.toLowerCase().includes(promoCode.code.toLowerCase()) && diffInDays >= 0) {

          if (promoCode.promo_type == "Discount Rate") {
            var discount = discountState
            var newProductsTotalState = Number(productsTotalState) + Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: 0, applyPromoCodeState: "", orderPromoCodeValue: "" })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: "", discount: 0 }))

            break
          }
          else if (promoCode.promo_type == "Discount Dollars") {
            var discount = promoCode.discount_dollars
            var newProductsTotalState = Number(productsTotalState) + Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: 0, applyPromoCodeState: "", orderPromoCodeValue: "" })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: "", discount: 0 }))

            break
          }
          else if (promoCode.promo_type == "Free Item") {
            var discount = 0
            var newProductsTotalState = Number(productsTotalState) + Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: 0, applyPromoCodeState: "", orderPromoCodeValue: "" })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: "", discount: 0 }))

            break
          }
          else if (promoCode.promo_type == "Free Delivery") {
            var discount = this.state.deliveryChargesState
            var newProductsTotalState = Number(productsTotalState) + Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: 0, applyPromoCodeState: "", orderPromoCodeValue: "" })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: "", discount: 0 }))

            break
          }
        }

      }

    }
    //console.log("Calculate Discount", this.state.orderPromoCodeState)
  }



  render() {
    const {
      selectedTab,
      dispatch,
      filters,
      selectedCategory,
      loggedIn,
      phone,
      smsSent,
      loginModalToggle,

    }
      = this.props


    const { appReducer } = store.getState()
    const { selectedVendorData, products, vendorData, userId, location, applyPromoCode, selectedLocationId } = appReducer
    const { orderTypeState,
      orderLaterState,
      orderDateState,
      selectedTime,
      productsState,
      productsTotalState,
      deliveryChargesState,
      orderLocationState,
      orderPromoCodeValue, applyPromoCodeState, discountState } = this.state;
    // console.log("Hello123", applyPromoCodeState)
    var locIndex = location.findIndex(x => x.id == selectedLocationId);
    let displayComponent;
    if (selectedTab === 'map') {
      dispatch(changeTab('map'))
      window.location.href = '/'
      // displayComponent = <div><FloatingPanel></FloatingPanel><MapComponent vendorData={vendorData} dispatch={dispatch}></MapComponent></div>
    }
    else if (selectedTab === 'explore') {
      dispatch(changeTab('explore'))
      window.location.href = '/'

    }
    else if (selectedTab === 'cart') {
      let date = ''
      let time = ''
      if (getFromLocalStorage('orderDate')) {
        let temp = getFromLocalStorage('orderDate').split("T")
        date = temp[0]
        time = moment(getFromLocalStorage('orderDate')).format("hh:mm A")
      }


      displayComponent = <div><Container fluid>

        <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
          {getFromLocalStorage('orderStatus') === "Received" || getFromLocalStorage('orderStatus') === "Cooking" || getFromLocalStorage('orderStatus') === "Ready for pickup" || getFromLocalStorage('orderStatus') === "On its way" ?

            <Col xs={6} style={{ boxShadow: "0 4px 8px 0px rgba(0,0,0,0.2)", transition: "0.3s", paddingTop: 10 }}>
              <b><p>Order Status is {getFromLocalStorage('orderStatus')}</p></b>
              {/* <div style={{width:'100%',margin:'auto'}}><img style={{width:100}} src={require('../../assets/img/order.gif')}></img></div> */}
              <table style={{ width: '100%' }}>
                <tr>
                  <td>KitchaCo Location:</td>
                  <td></td>
                  <td>{appReducer.selectedLocation}</td>
                </tr>
                <tr>
                  <td>Order Type:</td>
                  <td></td>
                  <td style={{ textTransform: 'capitalize' }}>{getFromLocalStorage('orderType')}</td>
                </tr>
                <tr>
                  <td>Order Date:</td>
                  <td></td>
                  <td style={{ textTransform: 'capitalize' }}>{date}</td>
                </tr>
                <tr>
                  <td>Order Time:</td>
                  <td></td>
                  <td style={{ textTransform: 'capitalize' }}>{time}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

                <tr>
                  <td style={{ color: "black" }}><b>Payment Status:</b></td>
                  <td></td>
                  <td style={{ color: "black" }}><b>{getFromLocalStorage('paymentStatus')}</b></td>
                </tr>
                <tr>
                  <td style={{ color: "black" }}><b>Payment Source:</b></td>
                  <td></td>
                  <td style={{ color: "black" }}><b>{getFromLocalStorage('paymentSource')}</b></td>
                </tr>
                <tr>
                  <td style={{ color: "black" }}><b>Receipt Number:</b></td>
                  <td></td>
                  <td style={{ color: "black" }}><b>{getFromLocalStorage('paymentReceiptNumber')}</b></td>
                </tr>
              </table>

            </Col>
            :
            <Col xs={6} style={{ boxShadow: "0 4px 8px 0px rgba(0,0,0,0.2)", transition: "0.3s", paddingTop: 10 }}>
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={12}>
                      <h4>Please select your order type</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <CustomButton
                        backgroundColor={orderTypeState === 'delivery' ? theme.colors.primaryColor : theme.colors.backgroundColor}
                        textColor={orderTypeState === 'delivery' ? theme.colors.textColorLight : theme.colors.textColorDark}
                        borderRadius={20}
                        isProfilePic={false}
                        buttonText={'Delivery'}
                        buttonWidth={'100%'}
                        handleButtonClick={() => { this.changePromo(); this.setState({ orderTypeState: 'delivery', orderLocationState: '' }, () => { dispatch(updateOrderType(this.state.orderTypeState)); dispatch(updateOrderLocation(this.state.orderLocationState)) }) }}
                      ></CustomButton>
                    </Col>
                    <Col xs={6}>
                      <CustomButton
                        backgroundColor={orderTypeState === 'pickup' ? theme.colors.primaryColor : theme.colors.backgroundColor}
                        textColor={orderTypeState === 'pickup' ? theme.colors.textColorLight : theme.colors.textColorDark}
                        borderRadius={20}
                        isProfilePic={false}
                        buttonText={'Pickup'}
                        buttonWidth={'100%'}
                        handleButtonClick={() => { this.changePromo(); this.setState({ orderTypeState: 'pickup', deliveryChargesState: 0.0, orderLocationState: getFromLocalStorage("selectedLocation") }, () => { dispatch(updateOrderType(this.state.orderTypeState)); dispatch(updateDeliveryCharges(this.state.deliveryChargesState)); dispatch(updateOrderLocation(getFromLocalStorage("selectedLocation"))) }) }}
                      ></CustomButton>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {orderTypeState === 'delivery' ?
                <Row style={{ marginTop: 50 }}>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12}>
                        <h4>Enter your delivery location:</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <PlacesAutocomplete
                          value={this.state.orderLocationState}
                          onChange={this.handleChange}
                          onSelect={this.handleSelect}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Delivery Location (Required)',
                                  className: 'location-search-input',
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {this.state.deliveryChargesState != 0.0 ?
                          <p>A delivery charge of ${this.state.deliveryChargesState} will apply.</p>
                          :

                          <p>{this.state.deliveryAllowedError}</p>
                        }

                      </Col>
                    </Row>
                  </Col>
                </Row>
                : null}



              <Row style={{ marginTop: 50 }}>
                <Col xs={8}>
                  <Row>
                    <Col xs={12}>
                      <h4>Please select your order time</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <CustomButton
                        backgroundColor={orderLaterState === false ? theme.colors.primaryColor : theme.colors.backgroundColor}
                        textColor={orderLaterState === false ? theme.colors.textColorLight : theme.colors.textColorDark}
                        borderRadius={20}
                        isProfilePic={false}
                        buttonText={'Order Now'}
                        buttonWidth={'100%'}
                        handleButtonClick={() => { this.setState({ orderLaterState: false, orderDateState: moment(new Date()).format('YYYY-MM-DDTHH:mm') }, () => { dispatch(updateOrderLater(this.state.orderLaterState.toString())); dispatch(updateOrderDate(this.state.orderDateState)) }) }}
                      ></CustomButton>
                    </Col>
                    <Col xs={6}>
                      <CustomButton
                        backgroundColor={orderLaterState === true ? theme.colors.primaryColor : theme.colors.backgroundColor}
                        textColor={orderLaterState === true ? theme.colors.textColorLight : theme.colors.textColorDark}
                        borderRadius={20}
                        isProfilePic={false}
                        buttonText={'Order for later'}
                        buttonWidth={'100%'}
                        handleButtonClick={() => { this.setState({ orderLaterState: true }, () => { dispatch(updateOrderLater(this.state.orderLaterState.toString())) }) }}
                      ></CustomButton>
                    </Col>
                  </Row>
                </Col>
              </Row>


              {orderLaterState === true ?
                <Row style={{ marginTop: 50 }}>
                  <Col xs={8}>
                    <Row>
                      <Col xs={12}>
                        <h4>Enter Order Time</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8}>

                        <form noValidate>
                          <TextField
                            id="datetime-local"
                            type="datetime-local"
                            defaultValue={orderDateState.toString()}
                            onChange={(event) => {
                              this.setState({ orderDateState: event.target.value }, () => {
                                // // console.log("Hello Order Date", this.state.orderDateState)
                                dispatch(updateOrderDate(this.state.orderDateState))
                              })
                              console.log(event.target.value)
                            }}

                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </form>

                      </Col>
                    </Row>
                  </Col>
                </Row>
                : null}
              {orderTypeState != '' && ((orderTypeState == 'delivery' && deliveryChargesState != 0.0) || orderTypeState == 'pickup') ?
                <Row style={{ marginTop: 50 }}>
                  <Col xs={8}>
                    <Row>
                      <Col xs={12}>
                        <h4>Enter Promo Code</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8}>
                        <Row>
                          <Col xs={6}>

                            <InputGroup className="mb-3">
                              {applyPromoCodeState == "" || applyPromoCodeState == null ?
                                <FormControl
                                  placeholder="Promo Code"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={orderPromoCodeValue}
                                  onChange={(event) => { this.setState({ orderPromoCodeValue: event.target.value }) }}
                                />
                                :
                                <FormControl
                                  placeholder="Promo Code"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={applyPromoCodeState}
                                  readOnly
                                  onChange={(event) => { console.log("") }}
                                />


                              }
                            </InputGroup>
                            <p>{this.state.promoCodeError}</p>
                            <p style={{ cursor: 'pointer' }} onClick={() => this.changePromo()}>Click here to edit</p>
                            {/* <TextField
                                        style={{ border: "1px solid " + theme.colors.borderColor, borderRadius: 20 }}
                                        id="promo"
                                        type="text"
                                        defaultValue={orderPromoCodeValue.toString()}
                                        onChange={(event) => { //console.log(event.target.value) }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      /> */}
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              backgroundColor={orderPromoCodeValue != "" ? theme.colors.primaryColor : theme.colors.backgroundColor}
                              textColor={orderPromoCodeValue != "" ? theme.colors.textColorLight : theme.colors.textColorDark}
                              borderRadius={20}
                              isProfilePic={false}
                              buttonText={applyPromoCodeState == "" || applyPromoCodeState == null ? 'Apply' : 'Applied'}
                              buttonWidth={'100%'}
                              handleButtonClick={applyPromoCodeState == "" || applyPromoCodeState == null ? (orderPromoCodeValue != "" ? () => { this.calculateDiscount() } : null) : null}
                            ></CustomButton>


                          </Col>
                        </Row>


                      </Col>
                    </Row>
                  </Col>
                </Row>
                : null}



              {isRestaurantOpen(moment, location, locIndex, 'schedule_order', orderDateState, orderDateState) ?



                orderTypeState != '' ?
                  (orderTypeState == 'delivery' && deliveryChargesState != 0.0) || orderTypeState == 'pickup' ?
                    <Row style={{ marginTop: 50 }}>
                      <Col xs={8}>
                        <Row>
                          <Col xs={12}>
                            <h4>Enter Payment Details</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={8}>
                            <PaymentForm
                              paymentForm={window.SqPaymentForm}
                              user={userId}
                              productsTotal={productsTotalState}
                              deliveryCharges={deliveryChargesState}
                              orderType={orderTypeState}
                              dispatch={dispatch}
                              productsState={productsState}
                              orderDateState={orderDateState}
                              orderLaterState={orderLaterState}
                              discount={discountState}
                              applyPromoCode={applyPromoCodeState}

                            />

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    : null
                  : null
                :
                <Row style={{ marginTop: 50 }}>
                  <Col xs={8}>
                    <Row>
                      <Col xs={12}>
                        <h4>Restaurant closed</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <p>{"Restaurant is closed on " + moment(orderDateState).format("DD MMM YYYY") + " at " + moment(orderDateState).format("hh:mm A") + "."}
                          <br></br>Please select <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ orderLaterState: true }, () => { dispatch(updateOrderLater(this.state.orderLaterState.toString())) }) }}><b>Order for later</b></span> above and choose a different date or time</p>

                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h4>Opening Hours:</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <p>Monday: {location[locIndex].monday_time != 0 ? location[locIndex].monday_time : 'Closed'}</p>
                        <p>
                          Tuesday: {location[locIndex].tuesday_time != 0 ? location[locIndex].tuesday_time : 'Closed'}
                        </p>
                        <p >
                          Wednesday: {location[locIndex].wednesday_time != 0 ? location[locIndex].wednesday_time : 'Closed'}
                        </p>
                        <p>
                          Thursday: {location[locIndex].thursday_time != 0 ? location[locIndex].thursday_time : 'Closed'}
                        </p>
                        <p>
                          Friday: {location[locIndex].friday_time != 0 ? location[locIndex].friday_time : 'Closed'}
                        </p>
                        <p>
                          Saturday: {location[locIndex].saturday_time != 0 ? location[locIndex].saturday_time : 'Closed'}
                        </p>
                        <p>
                          Sunday: {location[locIndex].sunday_time != 0 ? location[locIndex].sunday_time : 'Closed'}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

            </Col>}
          <Col xs={6} className="checkout-screen-cart" >
            <CartComponent
              hideHeader={true}
              products={
                productsState
                // Provide your own product's Object(Look at Products)
              }
              getLocalization={getDefaultLocalization('cart', 'en', localization)}
              currency="AUD"

              isCartEmpty={false}

            />
            <Row>
              <Col xs={6}>
                <h5 style={{ textAlign: 'left' }}>Sub Total:</h5>
              </Col>
              <Col xs={6}>
                <h5 style={{ textAlign: 'left' }}>${Number(Number(productsTotalState) + Number(discountState)).toFixed(2)}</h5>
              </Col>

            </Row>
            {applyPromoCodeState != '' && applyPromoCodeState != null
              ?
              <Row>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>Discount({applyPromoCodeState}):</h5>
                </Col>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>- ${Number(discountState).toFixed(2)}</h5>
                </Col>

              </Row>
              : null}

            {orderTypeState === 'delivery' ?
              <Row>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>Delivery Charges:</h5>
                </Col>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>${deliveryChargesState}</h5>
                </Col>
              </Row>
              : null}
            <Row>
              <Col xs={6}>
                <h5 style={{ textAlign: 'left' }}>Total:</h5>
              </Col>
              <Col xs={6}>
                <h5 style={{ textAlign: 'left' }}>${Number(Number(deliveryChargesState) + Number(productsTotalState)).toFixed(2)}</h5>
              </Col>
            </Row>
            {orderTypeState != '' ?
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <h5 style={{ textAlign: 'center', textTransform: 'capitalize' }}>{orderTypeState} location:</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <h5 style={{ textAlign: 'center', textTransform: 'capitalize', backgroundColor: '#f05b4b1a', padding: 10, borderRadius: 40 }}>{orderLocationState}</h5>
                    </Col>
                  </Row>

                </Col>
              </Row>
              : null}
          </Col>

        </Row>

      </Container>
      </div>
    }
















    return (
      this.state.loaded &&
      <div>

        <NavBar
          selectedTab={selectedTab}
          dispatch={dispatch}
          loggedIn={loggedIn}
          phone={phone}
          smsSent={smsSent}
          loginModalToggle={loginModalToggle}
          kitchacoLocation={location}
        ></NavBar>
        {displayComponent}


      </div>

    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(CheckoutScreen);



