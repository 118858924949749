import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'

import { store } from './store/index'
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom'
import VendorScreen from './screens/VendorScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import DriverScreen from './screens/DriverScreen';
import OrdersScreen from './screens/OrdersScreen';
import DriverApp from './screens/DriverApp';
import StockScreen from './screens/StockScreen';
import StockOrderScreen from './screens/StockOrderScreen';
import StockAdminScreen from './screens/StockAdminScreen';
import StockAdminOrderEditScreen from './screens/StockAdminOrderEditScreen';
import StockOrderEditScreen from './screens/StockOrderEditScreen';
import StockAdminApprovedOrdersScreen from './screens/StockAdminApprovedOrdersScreen';
import AdminScreen from './screens/AdminScreen';
import AdminOrdersScreen from './screens/AdminOrdersScreen';
import AdminOperatorsScreen from './screens/AdminOperatorsScreen';
import OperatorScreen from './screens/OperatorScreen';
import OperatorOrdersScreen from './screens/OperatorOrdersScreen';
import AdminTxnReportScreen from './screens/AdminTxnReportScreen';
import VendorResponsiveScreen from './screens/VendorResponsiveScreen';
import AllVendorsScreen from './screens/AllVendorsScreen';
import CheckoutResponsiveScreen from './screens/CheckoutResponsiveScreen'
import OrdersResponsiveScreen from './screens/OrdersResponsiveScreen';
import LandingPageRedirectScreen from './screens/LandingPageRedirectScreen';
import ContactUsScreen from './screens/ContactUsScreen';



ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/vendor:id" component={() => <VendorScreen></VendorScreen>} />
        <Route path="/v2/kitchaco:id"  exact component={()=><AllVendorsScreen></AllVendorsScreen>} />
        <Route path="/v2/vendor:id"  exact component={()=><VendorResponsiveScreen></VendorResponsiveScreen>} />
        <Route path="/v2/cart"  exact component={()=><CheckoutResponsiveScreen></CheckoutResponsiveScreen>} />
        <Route path="/v2/order-history"  exact component={()=><OrdersResponsiveScreen></OrdersResponsiveScreen>} />
        <Route path="/v2/contact-us" component={() => <ContactUsScreen></ContactUsScreen>} />
        <Route path="/checkout" component={() => <CheckoutScreen></CheckoutScreen>} />
        <Route path="/orders" component={() => <OrdersScreen></OrdersScreen>} />
        <Route path="/admin" component={() => <AdminScreen></AdminScreen>} />
        <Route path="/operator-admin" component={() => <OperatorScreen></OperatorScreen>} />
        <Route path="/operator-admin-orders" component={() => <OperatorOrdersScreen></OperatorOrdersScreen>} />
        <Route path="/admin-orders" component={() => <AdminOrdersScreen></AdminOrdersScreen>} />
        <Route path="/admin-txn-report" component={() => <AdminTxnReportScreen></AdminTxnReportScreen>} />
        <Route path="/admin-operators" component={() => <AdminOperatorsScreen></AdminOperatorsScreen>} />
        <Route path="/delivery-driver" component={() => <DriverScreen></DriverScreen>} />
        <Route path="/driver-app" component={()=><DriverApp></DriverApp>} />
        <Route path="/stock" component={()=><StockScreen></StockScreen>} />
        <Route path="/stock-orders" component={()=><StockOrderScreen></StockOrderScreen>} />
        <Route path="/stock-order-edit:id" component={()=><StockOrderEditScreen></StockOrderEditScreen>} />
        <Route path="/stock-admin" component={()=><StockAdminScreen></StockAdminScreen>} />
        <Route path="/stock-admin-order-edit:id" component={()=><StockAdminOrderEditScreen></StockAdminOrderEditScreen>} />
        <Route path="/stock-admin-approved-orders" component={()=><StockAdminApprovedOrdersScreen></StockAdminApprovedOrdersScreen>} />
        <Route path="/driver-app-tnc" render={() => (window.location = "https://ac245500-873d-44de-a86c-ae090e26b012.usrfiles.com/ugd/ac2455_cd2a56dc11dc4fc0bb50053d3c26cda0.pdf")}   />
        <Route path="/video/online-ordering" render={() => (window.location = "https://youtu.be/ZcGTaeRhTlw")}   />
        <Route path="/video/order-at-venue" render={() => (window.location = "https://youtu.be/-IKvbdDDFzc")}   />
        <Route component={()=><LandingPageRedirectScreen></LandingPageRedirectScreen>} />
        
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
