import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"


class StockAdminApprovedOrdersScreen extends Component {


  state = {
    adminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    pendingOrder: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    email: getFromLocalStorage("stockAdminEmail") && getFromLocalStorage("stockAdminEmail")!=''?getFromLocalStorage("stockAdminEmail"):'',
    password: getFromLocalStorage("stockAdminPassword") && getFromLocalStorage("stockAdminPassword")!=''?getFromLocalStorage("stockAdminPassword"):'',
    stockAdminUserData: getFromLocalStorage("stockAdminUserData") && JSON.parse(getFromLocalStorage("stockAdminUserData")) ? JSON.parse(getFromLocalStorage("stockAdminUserData")) : new Array(),
  }

  login = () => {
    const {email,password} = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "login" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.stockAdmin, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({ adminLoggedIn: "true" }, () => {
            saveToLocalStorage("stockAdminLoggedIn", "true")
            saveToLocalStorage("stockAdminEmail", email)
            saveToLocalStorage("stockAdminPassword", password)
          })
        }
        else {
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }

  logout = () => {
    deleteFromLocalStorage("stockAdminLoggedIn", "false")
    deleteFromLocalStorage("stockAdminEmail", "")
    deleteFromLocalStorage("stockAdminPassword", "")
    this.setState({ adminLoggedIn: "false" })
    window.location.href='/stock-admin'
  }

  

  fetchApprovedOrderFromServer = () => {
    const {email,password} = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "get_admin_approved" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.success){
          this.setState({pendingOrder:result.data})
        }
      })
      .catch(error => console.log('error', error));
  }

  containsObject = (obj,list) =>{
    if(list){
      for (var i=0;i<list.length;i++){
        if(list[i].stock_list_id==obj.stock_list_id){
          return true
        }
      }
    }
    return false
  }

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value }
    });
  }


  componentDidMount() {
    const {
      adminLoggedIn
    } = this.state

    this.fetchApprovedOrderFromServer()
    
  }



  render() {
    const {
      adminLoggedIn
    } = this.state
    return (
      <div>
        {this.renderNavBar()}
        {
          adminLoggedIn == "true" ?
            this.renderHomePage()
            :
            this.renderLoginPage()
        }
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      adminLoggedIn,
      stockAdminUserData
    } = this.state
    return (
      <Navbar bg="light" expand="lg" fixed>
        <Navbar.Brand href="#home">
          <Image src={require('../../assets/img/logo.png')} style={{ width: 45 }} roundedCircle fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/stock-admin/">Home</Nav.Link>
            {adminLoggedIn == "true" ?
              <Nav.Link href="/stock-approved-orders/">Approved Orders</Nav.Link>
              :
              null
            }

          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
          {adminLoggedIn == "true" ?
            <>
              <Nav.Link href="#link">Welcome, {stockAdminUserData["first_name"] + ' ' + stockAdminUserData["last_name"]}</Nav.Link>
              <Nav.Link onClick={() => { this.logout() }}>Logout</Nav.Link>
            </>
            :
            null
          }


        </Navbar.Collapse>
      </Navbar>
    )
  }

  renderLoginPage() {
    const {
      email,
      password
    } = this.state
    return (
      <div>
        <Container>
          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(text)=>{this.setState({email:text})}} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(text)=>{this.setState({password:text})}} />
          </Form.Group>

          <Button onClick={() => { this.login() }} variant="primary" type="submit">
            Login
            </Button>

        </Container>
      </div>
    )
  }

  renderHomePage() {
    const {
      pendingOrder
    } = this.state

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Pending Orders</h3>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Order #
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col" >
              Order Date
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Order Time
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Total
            </Col>
            <Col xs={12} md={3} lg={3} className="table-header-col">
              Status
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">

            </Col>
          </Row>
          {pendingOrder.map((item, i) => {

            return (
              <>
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                        <span><b className="mobile-only">Order Number:</b> {item.id}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.order_date}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.order_time}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span><b className="mobile-only">Total:</b>${item.total}</span>
                        </Col>
                        <Col xs={12} md={3} lg={3} className="table-col">
                        <span><b className="mobile-only">Status:</b>{item.approved ? 'Approved on ' + item.approved_date + ' ' + item.approved_time : 'Pending'}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <div className="details-btn">Details</div>
                      </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.id} style={{padding:20,backgroundColor:'#eff2f5'}}>
                      <>
                      <Row>
                      <Col xs={12} md={3} lg={3}  >
                          <h5>Products Ordered</h5>
                      </Col>
                      </Row>
                      <Row className="table-header-row">
                            <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                              Id
                             </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col" >
                              Product Name
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Qty
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Price
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Sub Total
                            </Col>
                          </Row>
                        {item.orderData.map((product, i) => {
                         
                          return (
                            <>
                            <Row key={i} className="table-row" style={this.containsObject(product,item.adjustedData)?{backgroundColor:'yellow'}:null}>
                              <Col xs={12} md={1} lg={1} className="table-col">
                                <span className="desktop-only">{product.id}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                                {product.name}
                              </Col>

                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Price:</b> {product.price}</span>
                              </Col>
                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Sub Total:</b>${product.total}</span>
                              </Col>

                            </Row>
                            
                            </>


                          )
                        })}
                        {item.adjustedData && item.adjustedData.length>0?
                      <>
                      <Row>
                      <Col xs={12} md={3} lg={3}  style={{marginTop:20}} >
                          <h5>Adjusted Items</h5>
                      </Col>
                      </Row>
                      <Row className="table-header-row">
                            <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                              Id
                             </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col" >
                              Product Name
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Qty
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Price
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Sub Total
                            </Col>
                        </Row>
                        {item.adjustedData && item.adjustedData.map((product, i) => {
                         
                          return (

                            <Row key={i} className="table-row">
                              <Col xs={12} md={1} lg={1} className="table-col">
                                <span className="desktop-only">{product.stock_list_id}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                                {product.name+' ('+product.qty_type__qty_type+')'}
                              </Col>

                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Price:</b> {product.price}</span>
                              </Col>
                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Sub Total:</b>${product.total}</span>
                              </Col>

                            </Row>


                          )
                        })}
                        </>
                        :null}
                       
                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
               
              </>

            )
          })}

        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    adminLoggedIn: state.appReducer.adminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockAdminApprovedOrdersScreen);



