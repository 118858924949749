import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { apiPaths } from '../../services/apiPath';
import config from '../../services/apiConfig'
import './style.css'

export default class ContactUsScreen extends Component {
    state = {
        kitchacoLocations: []
    }

    componentDidMount() {
        this.getAllLocations()
    }




    render() {
        const {
            kitchacoLocations
        } = this.state
        console.log(kitchacoLocations)
        return (
            <>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href={"/v2/kitchaco:1"}><Image style={{ height: 40 }} src={require("../../assets/img/logo-round.png")} fluid />&nbsp;Kitchaco</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">

                        </Nav>
                        <Nav.Link style={{ textDecoration: 'none', color: 'black' }} href="https://kitchaco.com">
                            Home
                        </Nav.Link>
                        <NavDropdown title="Order Now" id="basic-nav-dropdown" style={{ textDecoration: 'none', color: 'black' }}>
                            {kitchacoLocations.length>0 && kitchacoLocations.map((loc)=>{
                                return (
                                    <NavDropdown.Item style={{ textDecoration: 'none', color: 'black' }} href={'/v2/kitchaco:'+loc.id}>{loc.suburb}</NavDropdown.Item>
                                )
                            })}
                            
                          
                        </NavDropdown>
                        <Nav.Link style={{ textDecoration: 'none', color: 'black' }} href="https://www.kitchaco.com/aboutus">
                            About Us
                        </Nav.Link>
                        <Nav.Link style={{ textDecoration: 'none', color: 'black' }} href="https://www.kitchaco.com/catering">
                            Catering
                        </Nav.Link>
                        <Nav.Link style={{ textDecoration: 'none', color: 'black' }} href="https://www.kitchaco.com/collaboratewithus">
                            Colaborate With Us
                        </Nav.Link>
                    </Navbar.Collapse>
                </Navbar>
                <Container>
                    <Row style={{padding:15}}>
                        <h3>Get In Touch</h3>
                        <p>
                            If you are interested in learning more about the Kitchen Collective or getting
                            involved, send us a message and a member of our team will get back to you shortly.
                        </p>
                        <p>We are currently located in:</p>
                        <p><b>{kitchacoLocations.map((location, i) => {
                            return (
                                <>
                                    {i != kitchacoLocations.length - 1 ?
                                        location.suburb + ', '
                                        :
                                        location.suburb + ' '
                                    }

                                </>
                            )
                        })}</b> with new locations opening soon.</p>

                        <p>Contact: info@kitchaco.com</p>

                    </Row>
                    <Row>
                        {kitchacoLocations.map((item, index) => {
                            return (
                                <Col key={item.id} xs={12} md={4} lg={3} style={index % 2 == 0 ? { padding: 20, backgroundColor: '#f15b4b', color: 'white', marginBottom: 10 } : { padding: 20, backgroundColor: '#ffffff', color: 'black', marginBottom: 10, borderColor: 'black', borderWidth: 1, borderStyle: 'solid' }}>
                                    <h4>{item.suburb}</h4>
                                    <p>Contact: {item.support_phone}</p>
                                    {this.isClosedAllDays(item) ? <p><i>Temporarily closed for the holiday period.</i></p> : null}
                                    <p><b>Trading</b></p>
                                    <p>Monday: {item.monday_time != 0 ? item.monday_time : 'Closed'}</p>
                                    <p>Tuesday: {item.tuesday_time != 0 ? item.tuesday_time : 'Closed'}</p>
                                    <p>Wednesday: {item.wednesday_time != 0 ? item.wednesday_time : 'Closed'}</p>
                                    <p>Thursday: {item.thursday_time != 0 ? item.thursday_time : 'Closed'}</p>
                                    <p>Friday: {item.friday_time != 0 ? item.friday_time : 'Closed'}</p>
                                    <p>Saturday: {item.saturday_time != 0 ? item.saturday_time : 'Closed'}</p>
                                    <p>Sunday: {item.sunday_time != 0 ? item.sunday_time : 'Closed'}</p>
                                </Col>
                            )
                        })}


                    </Row>
                </Container>
            </>
        );
    }

    isClosedAllDays = (location) => {
        if (location.monday_time == 0 && location.tuesday_time == 0 && location.wednesday_time == 0 && location.thursday_time == 0 && location.friday_time == 0 && location.saturday_time == 0 && location.sunday_time == 0) {
            return true
        }
        return false
    }

    getAllLocations = () => {
        var requestOptions = {
            method: 'get',
            redirect: 'follow'
        };

        fetch(config.BASE_URI + apiPaths.explore.location, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.sort((a, b) => (a.suburb > b.suburb) ? 1 : ((b.suburb > a.suburb) ? -1 : 0))
                this.setState({ kitchacoLocations: result })

            })
            .catch(error => console.log('error', error));
    }
}
